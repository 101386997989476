import React, {useEffect} from "react";
import Calendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {FC} from "react";
import { Breadcrumb, Spin} from "antd";
import {t, Trans} from "@lingui/macro";
import CalendarInfoModal from "../../components/calendar-info/calendar-info";
import {i18n} from "@lingui/core";
import Model from '../../components/modal';
import useSafeState from "../../utils/safe-state";
import {useAxios} from "../../utils/hooks";
import {AxiosResponse} from "axios";
import moment from "moment";
import {fullCalendarDate} from "../../interfaces/FullCalendarDate";
import DateTimeFormatter from "../../utils/date-time-formater";


const DeliveryCalendarScreen : FC = () => {

    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL  + "");
    const [current,setCurrent] = useSafeState<any>();

    const infoModal = Model({
        title: i18n._(t`Information`),
        component: <CalendarInfoModal />,
    });
    const [tableData, setTableData] = useSafeState<any[]>([]);
    const [loading, setLoading] = useSafeState<boolean>(false);

    const getCalendarData = (fullCalendarDate: fullCalendarDate) =>
    {
        if(fcrAxiosInstance.current != null){
            setLoading(true);

            let start =  DateTimeFormatter().momentToDate(fullCalendarDate.start);
            let end =  DateTimeFormatter().momentToDate(fullCalendarDate.end);

            fcrAxiosInstance.current.get("/resource/delivery-calendars/get-by-range?start="+start+"&end="+end)
                .then( function (response : AxiosResponse<any>)
                {
                    let events: any[] = [];
                    for (let [index,value] of response.data.entries()) {

                        let title: any =
                                localStorage.getItem('language') == 'hu' || localStorage.getItem('language') == null
                                    ? "Munkanap" + " - Műszak (" + value.shift + ")"
                                    : "Workday" + " - Shift (" + value.shift + ")";

                        if(value.extendedShift){
                            if(localStorage.getItem('language') == 'hu' || localStorage.getItem('language') == null){
                                title = title + " + Nyújtott (" + value.extendedShift + ")";
                            }else{
                                title = title + " + Extended (" + value.extendedShift + ")";
                            }
                        }

                        events.push({
                            title: title,
                            start:value.date,
                            end:moment(value.date)
                        })
                    }

                    setTableData(events);
                })
                .catch( function (error: any){
                console.log(error);
            }).finally(function () {
                setLoading(false);
            }) ;
        }
        console.log(current);
    }


    const getData = (fullCalendarDate: fullCalendarDate) => {
        getCalendarData(fullCalendarDate);
    };

    const getDisplayedData = (date: any) => {
        if (fcrAxiosInstance.current ==null)
        {
            setTimeout(() => {
                getData(date);
            }, 5);
        }
        else
        {
            getData(date);
        }

    };


    return <>
        <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Delivery calendar'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <Spin spinning={loading}>
        <Calendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            dayMaxEventRows={4}
            buttonText={
                {
                    today:    i18n._(t`today`),
                    month:    i18n._(t`month`),
                    week:     i18n._(t`week`),
                    day:      i18n._(t`day`),
                    list:     i18n._(t`list`)
                }
            }
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            eventClick={info =>
            {
                console.log(info.event.endStr);
                infoModal.open({
                    title: info.event.title,
                    timeStart: info.event.startStr,
                    color: info.event.backgroundColor,
                });
            }}
            locale={'hu'}
            events={tableData}
            nowIndicator={true}
            datesSet={getDisplayedData}
            height="auto"
            contentHeight="auto"
        />
        </Spin>
        {infoModal.component}
    </>
}
export default DeliveryCalendarScreen;
