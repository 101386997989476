import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const SapFileIncomingModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'supplierCodes', width: 50, filter: true, sorter:true,title:<Trans>Supplier</Trans>, fixed: "left", render: text => {return (<b>{text}</b>);} })
    columns.push({key:'filePath', width: 200, sorter:true,title:<Trans>File name</Trans>});
    columns.push({key:'incomingDate', width: 110, dateTimeFilter:true, sorter:true,title:<Trans>Incoming date</Trans>, direction:"desc"});
    columns.push({key:'expirationDate', width: 110, dateFilter:true, sorter:true,title:<Trans>Expiration date</Trans>});


    return{
        columns:columns,
        url:"/resource/sap-file-incomings-view"
    };

}
export default SapFileIncomingModel();
