import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const SettingsModel = (): ColumnMain => {

    const columns: Array<Column> = new Array();
    columns.push({
        key: 'id',
        width: 60,
        sorter: true,
        title: <Trans>#</Trans>,
        fixed: "left",
        direction: "asc",
        render: text => {
            return (<b>{text}</b>);
        }
    });
    columns.push({
            key: 'category', width: 350, sorter: true, title: <Trans>Category</Trans>,
            tags: [
                {text: 'CLAIM_HANDLING_LOGISTIC', color: 'green'},
                {text: 'CLAIM_HANDLING_QUALITY', color: 'green'},
                {text: 'MLA_STAKEHOLDERS', color: 'green'},
            ],
            uniqueSelectFilterData: [
                {
                    label: <Trans>NONE</Trans>,
                    value: null
                }, {
                    label: <Trans>CLAIM_HANDLING_LOGISTIC</Trans>,
                    value: "CLAIM_HANDLING_LOGISTIC"
                }, {
                    label: <Trans>CLAIM_HANDLING_QUALITY</Trans>,
                    value: "CLAIM_HANDLING_QUALITY"
                }, {
                    label: <Trans>MLA_STAKEHOLDERS</Trans>,
                    value: "MLA_STAKEHOLDERS"
                }
            ]
        }
    );
    columns.push({key: 'key', width: 300, sorter: true, title: <Trans>Key</Trans>,
            tags: [
                {text: 'APPROVER', color: 'blue'},
                {text: 'APPROVER_DEPUTY', color: 'blue'},
                {text: 'RESPONSIBLE', color: 'blue'},
                {text: 'RESPONSIBLE_DEPUTY', color: 'blue'},
                {text: 'CONTROLLER', color: 'blue'},
                {text: 'CONTROLLER_DEPUTY', color: 'blue'},
            ],
            uniqueSelectFilterData: [
                {
                    label: <Trans>NONE</Trans>,
                    value: null
                }, {
                    label: <Trans>APPROVER</Trans>,
                    value: "APPROVER"
                }, {
                    label: <Trans>APPROVER_DEPUTY</Trans>,
                    value: "APPROVER_DEPUTY"
                }, {
                    label: <Trans>RESPONSIBLE</Trans>,
                    value: "RESPONSIBLE"
                }, {
                    label: <Trans>RESPONSIBLE_DEPUTY</Trans>,
                    value: "RESPONSIBLE_DEPUTY"
                }, {
                    label: <Trans>CONTROLLER</Trans>,
                    value: "CONTROLLER"
                }, {
                    label: <Trans>CONTROLLER_DEPUTY</Trans>,
                    value: "CONTROLLER_DEPUTY"
                }
            ]
        }
    );
    columns.push({key: 'value', width: 300, sorter: true, title: <Trans>Value</Trans>});
    columns.push({key:'supplier.code', dataIndex:['supplier','code'], width: 300, sorter: true, title: <Trans>Supplier</Trans>});
    columns.push({key: 'createdBy', width: 170, sorter: true, title: <Trans>Created By</Trans>})
    columns.push({
        key: 'createdDate',
        width: 140,
        dateTimeFilter: true,
        sorter: true,
        title: <Trans>Created Date</Trans>
    })
    columns.push({key: 'updatedBy', width: 170, sorter: true, title: <Trans>Updated By</Trans>})
    columns.push({
        key: 'updatedDate',
        width: 140,
        dateTimeFilter: true,
        sorter: true,
        title: <Trans>Updated Date</Trans>
    })

    return {
        columns: columns,
        url: "/resource/settings"
    };

}
export default SettingsModel();
