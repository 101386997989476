import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import SettingsModel from "../../models/dto/settings-model";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb, Button, message, Popconfirm, Tooltip} from "antd";
import {Trans} from "@lingui/macro";
import {DeleteOutlined, EditTwoTone} from "@ant-design/icons";
import {useAxios} from "../../utils/hooks";
import {antdContext} from "../../utils/antdContext";
import Drawer from "../../components/drawer/drawer";
import ItemForm from "../../forms/item-form/item-drawer";
import {PlusOutlined} from "@ant-design/icons/lib";
import SettingsForm from "../../forms/settings/settings-drawer";

const SettingsScreen : FC = () => {

    const [trigger, setTrigger] = useSafeState(false);

    const mtskAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL  + "");

    const handleDelete = (id: any) => {
        if(mtskAxiosInstance.current != null){
            mtskAxiosInstance.current.delete("/resource/settings/id?id=" + id)
                .then(() => {
                    message.success(antdContext('Az adott sor sikeresen törölve lett.'));
                    setTrigger(false);
                    setTrigger(!trigger);
                }).catch(function (error :any) {
                message.error(antdContext('Hiba a mentés közben'));
                setTrigger(false);
                setTrigger(!trigger);
                console.log(error);
            });
        }
    }

    const action = (text: string, record: any) => {

        return <>
            <Tooltip placement="top" title={<Trans>Delete</Trans>}>
                <Popconfirm title={<Trans>Are you sure to delete?</Trans>}
                            onConfirm={() => handleDelete(record.id)}
                            okText={<Trans>Yes</Trans>}
                            cancelText={<Trans>No</Trans>}>
                    <DeleteOutlined className={"ActionButtons"} style={{color: "#ff4d4f"}}/>
                </Popconfirm>
            </Tooltip>
            <Tooltip placement="top" title={<Trans>Edit</Trans>}>

                <EditTwoTone
                    twoToneColor={"#40a9ff"}
                    className={"ActionButtons"}
                    onClick={
                    () => drawer.open("Edit", {
                        id: record.id,
                        category: record.category,
                        key: record.key,
                        value: record.value,
                        supplier: record.supplier == null ? null : record.supplier.code,
                        version:record.version
                    }
                    )
                    }
                />
            </Tooltip>
        </>
    }

    const actions = {
        width: 100,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    const text = <Trans>Settings</Trans>;
    const drawer = Drawer(
        {
            title: text,
            children:
                <SettingsForm/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    return  <>

        <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Settings'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <DtoTable model={SettingsModel}
                  tableHeader={
                      <Button type={"primary"} onClick={ () => drawer.open("Add new")} style={{float: "left", marginRight:10}}><PlusOutlined /><span><Trans>Add new</Trans></span></Button>
                  }
                  action={actions}
                  trigger={trigger}
                  pageSize={100}
                  scroll={{ x: 1300, y: "65vh" }}
        />
        {drawer.component}
    </>
}
export default SettingsScreen;
