
import {FC} from "react";

import React from 'react';

import MLAUploadForm from "../../forms/ppap/mla-upload-form";

interface Interface{
    requestId: any;
    mode?: "View" | "AddNew";
}
const MLAUploadScreen: FC<Interface> = (props:any) => {

    return (
            
                <MLAUploadForm
                    requestId={props.requestId}
                    mode={props.mode}
                />
            
    );
}
export default MLAUploadScreen;
