import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import SapFileIncomingModel from "../../models/dto/sap-file-incoming-model";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb, message, Tooltip} from "antd";
import {Trans} from "@lingui/macro";
import {
    FileExcelOutlined,
    FilePdfOutlined
} from "@ant-design/icons/lib";
import {useAxios} from "../../utils/hooks";
import {antdContext} from "../../utils/antdContext";
import isAuthorized from "../../utils/is-authorized";
import {useKeycloak} from "@react-keycloak/web";

const SapFileForecastIncomingScreen : FC = () => {

    const {initialized, keycloak} = useKeycloak();
    const [trigger, setTrigger] = useSafeState(false);
    const [pdfDownloading, setPdfDownloading] = useSafeState(false);
    const [csvDownloading, setCsvDownloading] = useSafeState(false);
    const [ediCsvDownloading, setEdiCsvDownloading] = useSafeState(false);
    const [ediDownloading, setEdiDownloading] = useSafeState(false);

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "")

    const openNotification = (status: any, msg: string) => {
        if (status === "error") {
            message.error(antdContext(msg)).then(r => {
                console.log(r)
            });
        } else if (status === "success") {
            message.success(antdContext(msg));
        }
    };

    const handleDownloadPdf = (record: any) => {

        setPdfDownloading(true);
        if(axiosInstance.current != null){
            const FileDownload = require('js-file-download');
            axiosInstance.current({
                url: process.env.REACT_APP_API_BASE_URL + '/resource/sap-file-incomings/pdf/id?id=' + record.id,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                console.log(response);
                FileDownload(response.data, record.filePath.split('.')[0] + '.pdf');
            }).catch(function (error: any) {
                openNotification("error", "File not found");
            }).finally(() => setPdfDownloading(false));
        }
    }

    const handleDownloadCsv = (record: any) => {

        setCsvDownloading(true);
        if(axiosInstance.current != null){
            const FileDownload = require('js-file-download');
            axiosInstance.current({
                url: process.env.REACT_APP_API_BASE_URL + '/resource/sap-file-incomings/csv/id?id=' + record.id,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                console.log(response);
                FileDownload(response.data, record.filePath.split('.')[0] + '.csv');
            }).catch(function (error: any) {
                openNotification("error", "File not found");
            }).finally(() => setCsvDownloading(false));
        }
    }

    const action = (text: string, record: any) => (
        <>
            {
                isAuthorized(keycloak,["MTSK_SUPPL_USER"])
                    ? <>
                        <Tooltip placement="topLeft" title={<Trans>.pdf</Trans>}>
                            <FilePdfOutlined className={"ActionButtons"} style={{color: "#cf1322"}} onClick={ () => handleDownloadPdf(record)} />
                        </Tooltip>
                        <Tooltip placement="topLeft" title={<Trans>.csv</Trans>}>
                            <FileExcelOutlined className={"ActionButtons"} style={{color: "#3f6600"}} onClick={ () => handleDownloadCsv(record)} />
                        </Tooltip>
                    </>
                    :<></>
            }
        </>
    );

    const actions = {
        width: 40,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    return  <>
        <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Forecasts'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <DtoTable
            query={{fileType: "ALL_FORECAST"}}
            model={SapFileIncomingModel}
            action={ isAuthorized(keycloak,["MTSK_SUPPL_USER"]) ? actions : null }
            trigger={trigger}
            pageSize={100}
            scroll={{ x: 1300, y: "65vh" }}
        />
    </>
}
export default SapFileForecastIncomingScreen;
