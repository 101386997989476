import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";
import { Tag } from 'antd';

const ClaimListModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'refNum', width: 90, filter:true, sorter:true,title:<Trans>Ref. No.</Trans>});
    columns.push({
        key: 'state',
        width: 300,
        uniqueSelectFilterData: [
            {
                label: <Trans>All</Trans>,
                value: null,
            },
            {
                label: <Trans>[10] - Kiadás (Kiadó)</Trans>,
                value: 'Start',
            },
            {
                label: <Trans>[20] - Javítás (Fogadó)</Trans>,
                value: 'Response',
            },
            {
                label: <Trans>[30] - Helyesbítés (Fogadó)</Trans>,
                value: 'Responsibility',
            },
            {
                label: <Trans>[30] - Helyesbítés (hiba ok) (Fogadó)</Trans>,
                value: 'Error reason data',
            },
            {
                label: <Trans>[40] - Ellenőrzés (Fogadó)</Trans>,
                value: 'Control (Receiver)',
            },
            {
                label: <Trans>[50] - Jóváhagyás (Fogadó)</Trans>,
                value: 'Approval (Receiver)',
            },
            {
                label: <Trans>[60] - Elfogadás (Kiadó)</Trans>,
                value: 'Acceptance',
            },
            {
                label: <Trans>[70] - Ellenőrzés (Kiadó)</Trans>,
                value: 'Control (Sender)',
            },
            {
                label: <Trans>[80] - Jóváhagyás (Kiadó)</Trans>,
                value: 'Approval (Sender)',
            },
            {
                label: <Trans>[100] - Bev. dátum 1. hét (Kiadó)</Trans>,
                value: 'Any Error? 1.',
            },
            {
                label: <Trans>[110] - Bev. dátum 2. hét (Kiadó)</Trans>,
                value: 'Any Error? 2.',
            },
            {
                label: <Trans>[120] - Bev. dátum 3. hét (Kiadó)</Trans>,
                value: 'Any Error? 3.',
            },
            {
                label: <Trans>[130] - Lezárás (Kiadó)</Trans>,
                value: 'Closing',
            },
            {
                label: <Trans>Lezárva</Trans>,
                value: 'Closed',
            }
        ],
        sorter: true,
        title: <Trans>Status</Trans>,
        render: text => {
            let color = 'gray';
            let transText = <Trans>No state</Trans>;
            if (text === 'Start') {
                color = '#91caff';
                transText = <Trans>[10] - Kiadás (Kiadó)</Trans>;
            } else if (text === 'Response') {
                color = '#ffc53d';
                transText = <Trans>[20] - Javítás (Fogadó)</Trans>;
            } else if (text === 'Responsibility') {
                color = '#faad14';
                transText = <Trans>[30] - Helyesbítés (Fogadó)</Trans>;
            } else if (text === 'Error reason data') {
                color = '#d48806';
                transText = <Trans>[30] - Helyesbítés (hiba ok) (Fogadó)</Trans>;
            } else if (text === 'Control (Receiver)') {
                color = '#ad6800';
                transText = <Trans>[40] - Ellenőrzés (Fogadó)</Trans>;
            } else if (text === 'Approval (Receiver)') {
                color = '#874d00';
                transText = <Trans>[50] - Jóváhagyás (Fogadó)</Trans>;
            } else if (text === 'Acceptance') {
                color = '#69b1ff';
                transText = <Trans>[60] - Elfogadás (Kiadó)</Trans>;
            } else if (text === 'Control (Sender)') {
                color = '#4096ff';
                transText = <Trans>[70] - Ellenőrzés (Kiadó)</Trans>;
            } else if (text === 'Approval (Sender)') {
                color = '#1677ff';
                transText = <Trans>[80] - Jóváhagyás (Kiadó)</Trans>;
            } else if (text === 'Any Error? 1.') {
                color = '#2f54eb';
                transText = <Trans>[100] - Bev. dátum 1. hét (Kiadó)</Trans>;
            } else if (text === 'Any Error? 2.') {
                color = '#1d39c4';
                transText = <Trans>[110] - Bev. dátum 2. hét (Kiadó)</Trans>;
            } else if (text === 'Any Error? 3.') {
                color = '#10239e';
                transText = <Trans>[120] - Bev. dátum 3. hét (Kiadó)</Trans>;
            } else if (text === 'Closing') {
                color = '#061178';
                transText = <Trans>[130] - Lezárás (Kiadó)</Trans>;
            } else if (text === 'Closed') {
                color = '#f5222d';
                transText = <Trans>Lezárva</Trans>;
            }
            return (
                <Tag color={color} key={text}>
                    {transText}
                </Tag>
            );
        },
    });


    columns.push({key:'partNo', width: 100, filter:true, sorter:true,title:<Trans>Part No.</Trans>});
    columns.push({key:'partName', width: 100, filter:true, sorter:true,title:<Trans>Part Name</Trans>});
    columns.push({key:'responsibleDepartment', width: 70, filter:true, sorter:true,title:<Trans>Resp. Dep.</Trans>});
    columns.push({key:'qtyOfWrong', width: 100, filter:true, sorter:true,title:<Trans>Qty Of Wrong</Trans>});
    columns.push({key:'qtyOfFinallyWrong', width: 100, filter:true, sorter:true,title:<Trans>Qty Of Finally Wrong</Trans>});
    columns.push({key:'createdBy', width: 100, sorter:true, title:<Trans>Released By</Trans>})
    columns.push({key:'createdDate', width: 100, dateTimeFilter: true, direction: 'desc', sorter:true,title:<Trans>Released Date</Trans>})
    columns.push({key:'updatedBy', width: 100, sorter:true,title:<Trans>Updated By</Trans>})
    columns.push({key:'updatedDate', width: 100, dateTimeFilter: true, sorter:true,title:<Trans>Updated Date</Trans>})


    return{
        columns:columns,
        url:"/resource/claim-handling-view"
    };

}
export default ClaimListModel();
