import useSafeState from '../../utils/safe-state';
import React, {FC, ReactComponentElement, ReactElement, ReactNode, useEffect} from 'react';
import {Form, Row, Col, Input, Button, message, DatePicker, Select, Upload, Space, Typography, Popconfirm} from 'antd';
import Dragger from "antd/lib/upload/Dragger";
import {
    DeleteOutlined, DeleteTwoTone,
    DownloadOutlined,
    InboxOutlined,
    InfoCircleOutlined, InfoCircleTwoTone, PlusCircleOutlined, PlusCircleTwoTone, QuestionCircleOutlined,
    UploadOutlined,
    WarningOutlined
} from "@ant-design/icons/lib";
import { useAxios } from '../../utils/hooks';
import {AxiosResponse} from "axios";
import {t, Trans} from "@lingui/macro";
import {i18n} from "@lingui/core";
import {SelectType} from "../../interfaces/SelectType";
import {antdContext} from "../../utils/antdContext";
import TextArea from "antd/es/input/TextArea";
import Icon from "@ant-design/icons/es";
import {Message} from "../../interfaces/Message";
import {MessageDocument} from "#/interfaces/MessageDocument";
import {I18nProvider} from "@lingui/react";
import {UploadFile} from "antd/lib/upload/interface";

export interface MessageId {
    language: string;
    messageCode : string;
}

export interface MessageModel {
    messageId: MessageId;
    translated : string;
}

interface Interface{
    data?: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}


const MessageForm = (props : Interface) => {

    const [loading, setLoading] = useSafeState(false);
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "")
    const [autoCompleteOptions, setAutoCompleteOptions] = useSafeState<any>();

    const data : Message = props.data || {};
    const [form] = Form.useForm();
    const [fileList, setFileList] = useSafeState<Array<any>>([]);
    const [uploading, setUploading] = useSafeState(false);
    const [messageDocuments, setMessageDocuments] = useSafeState<Array<MessageDocument>>([]);


    let isEditMode = false;


    useEffect(() => {

        form.resetFields();
        getSuppliersForSelect();

        if (props.mode === "Edit") {

            let fieldsValues = [];
            for (const [key, value] of Object.entries(data)) {
                fieldsValues.push({name: key, value: value});
            }
            form.setFields(fieldsValues);

            isEditMode = true;
        }else if(props.mode === "Add new")
        {
            //let fieldsValues = [{name: "deviceTypeCategory", value: "COOLER"}];
            //form.setFields(fieldsValues);
            isEditMode = false;
        }
    },[])

    useEffect(() => {

        setMessageDocuments(data.messageDocuments);

    },[data])

    const onReset = () => {
        form.resetFields();
    };


    const getSuppliersForSelect = () => {

        if (!axiosInstance.current) {
            return;
        }

        axiosInstance.current.get('/resource/suppliers')
            .then(function (response: AxiosResponse<any>) {
                if (Array.isArray(response.data)) {
                    let supplierArray: Array<SelectType> = [];
                    response.data.forEach(value => {

                        const label = value.code;

                        supplierArray.push(
                            {
                                label: label,
                                value: label,
                            }
                        )
                    });
                    setAutoCompleteOptions(supplierArray);

                }

            })
            .catch(function (error: any) {
                console.log(error);//TODO::EndIt
            });

    };

    const openNotification = (status:any) => {
        if (status === "error") {
            message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
        } else if (status === "success") {
            message.success(antdContext('Sikeres mentés'));
        } else {
            message.info(antdContext('Ismeretlen hiba')).then(r => {console.log(r)})
        }
        if (props.onClose){
            props.onClose();
        }
    };

    const config = { headers: {'Content-Type': 'application/json'} };

    const handleEdit = () => {

        console.log(form.getFieldValue(["pic"]));

        if(axiosInstance.current != null){
            setLoading(true);
            axiosInstance.current.post("/resource/messages/geci", {
                    id: form.getFieldValue(["id"]),
                    message: form.getFieldValue(["message"]),
                    title: form.getFieldValue(["title"]),
                    startDate: form.getFieldValue(["startDate"]).format('YYYY-MM-DD'),
                    endDate: form.getFieldValue(["endDate"]).format('YYYY-MM-DD'),
                    supplierCode: {
                        code: form.getFieldValue(["supplierCode"])
                    },
                    version: form.getFieldValue(["version"]),
                    file: fileList
                },
                config).then(() => {
                openNotification("success");
                setLoading(false);

            }).catch(function (error :any) {
                openNotification("error");
                console.log(error)
                setLoading(false);
            });
        }
    }

    const handleUploadCsv = () => {
        if(axiosInstance.current != null){
            setLoading(true);
            const formData = new FormData();

            fileList.forEach(file => {
                formData.append('files', file);
            });

            axiosInstance.current.post("/resource/messages/upload-csv", formData,
                { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
                openNotification("success");
                setLoading(false);
            }).catch(function (error :any) {
                openNotification("error");
                console.log(error)
                setLoading(false);
            });
        }
    }

    const handleAddNew = () => {

        if(axiosInstance.current != null){
            setLoading(true);
            axiosInstance.current.post("/resource/messages/save1", {
                    message:	form.getFieldValue(["message"]),
                    title:	form.getFieldValue(["title"]),
                    startDate:	form.getFieldValue(["startDate"]).format('YYYY-MM-DD'),
                    endDate: form.getFieldValue(["endDate"]).format('YYYY-MM-DD'),
                    supplierCode:{
                        code:form.getFieldValue(["supplierCode"])
                    },
                    file: fileList
                },
                config).then(function (response : AxiosResponse<any>){
                setUploading(true);

                if(fileList.length > 0 && axiosInstance.current != null){
                    const formData = new FormData();
                    fileList.forEach(file => {
                        formData.append('pic', file);
                        formData.append('deviceTypeId', response.data.name);
                    });

                    axiosInstance.current.post('/resource/device-types/upload-picture', formData)
                        .then(function (response : AxiosResponse<any>){
                            setUploading(false);
                            setFileList([]);
                            openNotification("success");
                            setLoading(false);
                        }).catch(function (error :any) {
                        openNotification("error");
                        setUploading(false);
                        setLoading(false);
                    });
                }else{
                    openNotification("success");
                    setLoading(false);
                }
            }).catch(function (error :any) {
                openNotification("error");
                console.log(error)
                setLoading(false);
            });
        }
    }

    const uploadPropsCopy = {
        name: 'pic',
        multiple: false,
        onChange(info:any)
        {
            const { status } = info.file;

            if (status !== 'uploading') {
                console.log('uploading',info.file, info.fileList);
            }
            if (status === 'done') {
                message.success(`${info.file.name} {<Trans>file uploaded successfully.</Trans>}`);
            } else if (status === 'error') {
                message.error(`${info.file.name} {<Trans>file upload failed.</Trans>}`);
            }
        },
        beforeUpload(file:any)
        {
            let fileList2 : Array<any> = fileList;
            fileList2.push(file);
            setFileList(fileList2);
            return false;
        },
        onRemove(file: UploadFile<any>)
        {
            let currentList : Array<UploadFile> = fileList;
            currentList = currentList.filter(value => value.name!=file.name);
            setFileList(currentList);
        }
    };

    let mainForm : ReactElement =<>
        <Row gutter={24}>
            <Col span={24}>
                <Form.Item
                    name="title"
                    label={<Trans>Title</Trans>}
                    children={<Input disabled={false}/>}
                    rules={[{
                        type:"string",
                        required: true,
                        message: <Trans>Please type a title</Trans>
                    }]}
                />
            </Col>
        </Row>
        <Row gutter={24}>
            <Col span={24}>
                <Form.Item
                    name="message"
                    label={<Trans>Message</Trans>}
                    children={
                        <TextArea rows={4} />
                    }
                    rules={[{
                        type:"string",
                        required: true,
                        message: <Trans>Please type a message</Trans>
                    }]}
                />
            </Col>
        </Row>
        <Row gutter={24}>
            <Col span={8}>
                <Form.Item
                    name="startDate"
                    label={<Trans>Start date</Trans>}
                    children={
                        <DatePicker format={'YYYY-MM-DD'} placeholder={i18n._(t`dateFormat`)}/>
                    }
                    rules={[{
                        type:"date",
                        required: true,
                        message: <Trans>Please select a date</Trans>
                    }]}
                />
            </Col>
            <Col span={8}>
                <Form.Item
                    name="endDate"
                    label={<Trans>End date</Trans>}
                    children={
                        <DatePicker format={'YYYY-MM-DD'} placeholder={i18n._(t`dateFormat`)}/>
                    }
                    rules={[{
                        type:"date",
                        required: true,
                        message: <Trans>Please select a date</Trans>
                    }]}
                />
            </Col>
            <Col span={8}>
                <Form.Item
                    name="supplierCode"
                    label={<Trans>Supplier</Trans>}
                    children={
                        <Select
                            notFoundContent={<Trans id={'No data'}/>}
                            showSearch={true}
                            style={{width: '100%'}}
                            placeholder={<Trans id={'Select a supplier'}></Trans>}
                            options={autoCompleteOptions}
                        />
                    }
                    rules={[{
                        type:"string",
                        required: true,
                        message: <Trans>Select a supplier</Trans>
                    }]}
                />
            </Col>
        </Row>
    </>

    const handleNewSubmit = () => {

        if(axiosInstance.current != null){
            setLoading(true);
            const formData = new FormData();
            fileList.forEach(file => {
                formData.append('files', file);
            });
            formData.append("message",form.getFieldValue(["message"]));
            formData.append("title",form.getFieldValue(["title"]));
            formData.append("startDate",form.getFieldValue(["startDate"]).format('YYYY-MM-DD'));
            formData.append("endDate",form.getFieldValue(["endDate"]).format('YYYY-MM-DD'));
            formData.append("supplierCode",form.getFieldValue(["supplierCode"]));

            axiosInstance.current.post("/resource/messages/save-with-file", formData,
                { headers:
                        {
                            'Content-Type': 'multipart/form-data',
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT',
                            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
                        }
                }
                ).then(() => {
                openNotification("success");
                setLoading(false);
            }).catch(function (error :any) {
                setLoading(false);
                openNotification("error");
                console.log(error)
            });
        }
    }

    const handleEditSubmit = () => {

        if(axiosInstance.current != null){
            setLoading(true);
            const formData = new FormData();
            fileList.forEach(file => {
                formData.append('files', file);
            });
            formData.append("message",form.getFieldValue(["message"]));
            formData.append("id",data.id+"");
            formData.append("title",form.getFieldValue(["title"]));
            formData.append("startDate",form.getFieldValue(["startDate"]).format('YYYY-MM-DD'));
            formData.append("endDate",form.getFieldValue(["endDate"]).format('YYYY-MM-DD'));
            formData.append("supplierCode",form.getFieldValue(["supplierCode"]));

            axiosInstance.current.post("/resource/messages/update-with-file", formData,
                { headers:
                        {
                            'Content-Type': 'multipart/form-data',
                            'Access-Control-Allow-Origin': '*',
                            'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT',
                            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
                        }
                }
                ).then(() => {
                openNotification("success");
                setLoading(false);
            }).catch(function (error :any) {
                setLoading(false);
                openNotification("error");
                console.log(error)
            });
        }
    }

    const IconText = ({ icon , text  } : any) => (
        <div>
            {icon}
            {text}
        </div>
    );

    const antdContext: any = (text: string) => {
        return <I18nProvider i18n={i18n}>
            <Trans>{text}</Trans>
        </I18nProvider>;
    }

    const removeFile = (id : number) =>
    {

        if (!axiosInstance.current) {
            return;
        }

        axiosInstance.current.delete('/public/file/delete/message-document/'+id)
            .then(function (response: AxiosResponse<any>)
            {
                const current  = messageDocuments;

                if(response.status == 200)
                {
                    setMessageDocuments(current.filter(value => value.id != id));
                    message.success(antdContext('Success delete'));
                }
                else
                {
                    message.error(antdContext('Error on delete'));
                }

            })
            .catch(function (error: any) {
                console.log(error);//TODO::EndIt
            });

    }

    const generateFiles =  (msgDoc : MessageDocument[]) => {

        let fileArray: ReactElement[] = [];

        if (msgDoc.length == 0) {

            fileArray.push(  <IconText
                icon={<WarningOutlined style={{marginRight:6}}/>}
                text={<Trans>Files not found to this message</Trans>}/>);

            return fileArray;
        }

        fileArray.push(  <IconText
            icon={<InfoCircleTwoTone style={{marginRight:6,fontSize:18}}/>}
            text={<Trans>Files to this message</Trans>}/>);

        msgDoc.forEach((value, index) => {

            fileArray.push(
                <div>
                            <Popconfirm
                                onConfirm={() => removeFile(value.id)}
                                okText={<Trans>Yes</Trans>}
                                cancelText={<Trans>No</Trans>}
                                title={<Trans>Are u sure?</Trans>} icon={<QuestionCircleOutlined style={{ color: 'red' }} />}>
                                <DeleteTwoTone twoToneColor={"red"} onClick={() => console.log("")} />
                            </Popconfirm>
                            <Button
                                style={{marginLeft:6}}
                                href={process.env.REACT_APP_API_BASE_URL+"/public/file/get?folder="+value.folder+"&filename="+props.data.id+"/"+value.fileName}
                                type="default" icon={<DownloadOutlined />} size={"small"}>
                                {value.fileName}
                            </Button>
                </div>);

        });

        return fileArray;
    }

    return(
        <Form id={props.mode}  form={form} layout="vertical" onFinish={() => props.mode === "Edit" ?  handleEdit() : handleAddNew()}>
            {props.mode === "Add new" ?
                <>
                    {mainForm}
                    <Row gutter={24}>
                        <Col span={24}>

                            <Form.Item
                                name="fenykep"
                                children={<Dragger {...uploadPropsCopy}>
                                    <div></div>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text"><Trans>Click or drag file to this area to upload</Trans></p>

                                </Dragger>}
                            />

                        </Col>
                    </Row>
                </>
                :
                <>
                    {mainForm}

                    <Row gutter={24}>
                        <Col span={24}>
                            <IconText
                                icon={<PlusCircleTwoTone twoToneColor="#52c41a"  style={{marginTop:6,marginRight:6,marginBottom:6,fontSize:18}}/>}
                                text={<Trans>Upload more</Trans>}/>
                        </Col>
                    </Row>

                    <Row gutter={24}>
                        <Col span={24}>

                            <Form.Item
                                name="fenykep"
                                children={<Dragger {...uploadPropsCopy}>
                                    <div></div>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text"><Trans>Click or drag file to this area to upload</Trans></p>
                                </Dragger>}
                            />

                        </Col>
                    </Row>

                    <Row style={{marginBottom:10}} gutter={24}>
                        <Col span={24}>
                            <Space direction="vertical" style={{marginBottom:10}}>
                                {generateFiles(messageDocuments)}
                            </Space>
                        </Col>
                    </Row>


                </>
            }
            {props.mode === "Edit" ?
                <Button style={{display:"flex"}} loading={loading} htmlType="button" type="primary" onClick={() => handleEditSubmit()}>
                    <Trans>Mentés</Trans>
                </Button>
                :
                props.mode === "Add new" ?
                    <>
                        <Button htmlType="button" style={{ marginRight: 8 }} onClick={() =>onReset()}>
                            <Trans>Vissza</Trans>
                        </Button>
                        <Button loading={loading} htmlType="button" type="primary" style={{ marginRight: 8 }} onClick={() => handleNewSubmit()}>
                            <Trans>Save</Trans>
                        </Button>
                    </>
                    :
                    <Button loading={loading} htmlType="button" type="primary" onClick={() => handleUploadCsv()}>
                        <Trans>Feltöltés</Trans>
                    </Button>

            }
        </Form>
    );

}
export default MessageForm;
