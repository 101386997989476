import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import SapFileIncomingModel from "../../models/dto/sap-file-incoming-model";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb, message, Popconfirm, Tooltip} from "antd";
import {Trans} from "@lingui/macro";
import {Link} from "react-router-dom";
import {
    EyeOutlined,
    FileExcelOutlined,
    FilePdfOutlined, FileTextOutlined, PrinterOutlined,
} from "@ant-design/icons/lib";
import {useAxios} from "../../utils/hooks";
import {antdContext} from "../../utils/antdContext";
import isAuthorized from "../../utils/is-authorized";
import {useKeycloak} from "@react-keycloak/web";

const SapFileOrderIncomingScreen : FC = () => {

    const {initialized, keycloak} = useKeycloak();
    const [trigger, setTrigger] = useSafeState(false);
    const [pdfDownloading, setPdfDownloading] = useSafeState(false);
    const [csvDownloading, setCsvDownloading] = useSafeState(false);
    const [ediCsvDownloading, setEdiCsvDownloading] = useSafeState(false);
    const [ediDownloading, setEdiDownloading] = useSafeState(false);

    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "")

    const openNotification = (status: any, msg: string) => {
        if (status === "error") {
            message.error(antdContext(msg)).then(r => {
                console.log(r)
            });
        } else if (status === "success") {
            message.success(antdContext(msg));
        }
    };

    const handleDownloadPdf = (record: any) => {

        setPdfDownloading(true);
        if(axiosInstance.current != null){
            const FileDownload = require('js-file-download');
            axiosInstance.current({
                url: process.env.REACT_APP_API_BASE_URL + '/resource/sap-file-incomings/pdf/id?id=' + record.id,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                console.log(response);
                FileDownload(response.data, record.filePath.split('.')[0] + '.pdf');
            }).catch(function (error: any) {
                openNotification("error", "File not found");
            }).finally(() => setPdfDownloading(false));
        }
    }

    const handleDownloadCsv = (record: any) => {

        setCsvDownloading(true);
        if(axiosInstance.current != null){
            const FileDownload = require('js-file-download');
            axiosInstance.current({
                url: process.env.REACT_APP_API_BASE_URL + '/resource/sap-file-incomings/csv/id?id=' + record.id,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                console.log(response);
                FileDownload(response.data, record.filePath.split('.')[0] + '.csv');
            }).catch(function (error: any) {
                openNotification("error", "File not found");
            }).finally(() => setCsvDownloading(false));
        }
    }

    const handleDownloadEdiCsv = (record: any) => {

        setEdiCsvDownloading(true);
        if(axiosInstance.current != null){
            const FileDownload = require('js-file-download');
            axiosInstance.current({
                url: process.env.REACT_APP_API_BASE_URL + '/resource/sap-file-incomings/edi-csv/id?id=' + record.id,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                FileDownload(response.data, record.filePath.split('.')[0] + '.csv');
            }).catch(function (error: any) {
                openNotification("error", "File not found");
            }).finally(() => setEdiCsvDownloading(false));
        }
    }

    const handleDownloadEdi = (record: any) => {

        setEdiDownloading(true);
        if(axiosInstance.current != null){
            const FileDownload = require('js-file-download');
            axiosInstance.current({
                url: process.env.REACT_APP_API_BASE_URL + '/resource/sap-file-incomings/edi/id?id=' + record.id,
                method: 'POST',
                responseType: 'blob'
            }).then((response) => {
                FileDownload(response.data, record.filePath.split('.')[0] + '.edi');
            }).catch(function (error: any) {
                console.log(error);
            }).finally(
                () => setEdiDownloading(false)
            );
        }
    }

    const handleDownloadLabelList = (record: any,isA5Label : boolean) => {

        if(axiosInstance.current != null){
            const FileDownload = require('js-file-download');
            axiosInstance.current({
                url: process.env.REACT_APP_API_BASE_URL + '/resource/orders/get-qr-by-incoming-id/' + record.id+'/'+isA5Label,
                method: 'GET',
                responseType: 'blob',
                headers : {
                    'Content-Type': 'application/json',
                    'Charset':'utf-8'
                }
            }).then((response) => {
                FileDownload(response.data, new Date().toISOString().split('.')[0] + '.'+'pdf');
            }).catch(function (error: any) {
                console.log(error);
            })
        }
    }

    const action = (text: string, record: any) => (
            <>
                {
                    isAuthorized(keycloak,["MTSK_SUPPL_USER"])
                        ? <>
                            <Tooltip placement="topLeft" title={<Trans>.edi</Trans>}>
                                <FileTextOutlined className={"ActionButtons"} style={{color: "#d48806"}} onClick={ () => handleDownloadEdi(record)} />
                            </Tooltip>
                            <Tooltip placement="topLeft" title={<Trans>.pdf</Trans>}>
                                <FilePdfOutlined className={"ActionButtons"} style={{color: "#cf1322"}} onClick={ () => handleDownloadPdf(record)} />
                            </Tooltip>
                            <Tooltip placement="topLeft" title={<Trans>.csv</Trans>}>
                                <FileExcelOutlined className={"ActionButtons"} style={{color: "#3f6600"}} onClick={ () => handleDownloadCsv(record)} />
                            </Tooltip>
                            <Tooltip placement="topLeft" title={<Trans>.csv (edi)</Trans>}>
                                <FileExcelOutlined className={"ActionButtons"} style={{color: "#3f6600"}} onClick={ () => handleDownloadEdiCsv(record)} />
                            </Tooltip>
                            <Popconfirm
                                    title={<Trans>Which label do you want to print?</Trans>}
                                    okText={<Trans>A5 label</Trans>}
                                    cancelText={<Trans>80x100 label</Trans>}
                                    onCancel={() => handleDownloadLabelList(record,false)}
                                    onConfirm={() => handleDownloadLabelList(record,true)}
                                >
                                    <PrinterOutlined className={"ActionButtons"} style={{color: "#096dd9"}}  />
                            </Popconfirm>
                        </>
                        :<></>
                }
                <Tooltip placement="topLeft" title={<Trans>Show details</Trans>}>
                    <Link to={'/orders/'+record.id}>
                        <EyeOutlined  className={"ActionButtons"} style={{color: "#40a9ff"}} />
                    </Link>
                </Tooltip>
            </>
        );

    const actions = {
        width: isAuthorized(keycloak,["MTSK_SUPPL_USER"]) ? 110 : 25,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    return  <>
        <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Orders'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
            <DtoTable
                query={{fileType: "FIRM_NORMAL_EDI"}}
                model={SapFileIncomingModel}
                action={actions}
                trigger={trigger}
                pageSize={100}
                scroll={{ x: 1300, y: "65vh" }}
                />
    </>
}
export default SapFileOrderIncomingScreen;
