import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const ItemModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();

    columns.push({key:'id.itemNo',dataIndex:['id','itemNo'], width: 150, sorter:true,title:<Trans>ItemNo</Trans>, fixed: "left", direction:"asc", render: text => {return (<b>{text}</b>);} })
    columns.push({key:'id.supplier.code', dataIndex:['id','supplier','code'], width: 125, sorter:true,title:<Trans>Supplier</Trans>, fixed: "left"});
    columns.push({key:'nameHun', width: 230, sorter:true,title:<Trans>Name hun</Trans> });
    columns.push({key:'nameEng', width: 230, sorter:true,title:<Trans>Name eng</Trans> });
    columns.push({key:'piecePerBox', width: 130, sorter:true,title:<Trans>Piece per box</Trans>});
    columns.push({key:'active', width: 100, checkboxFilter:true, sorter:true,title:<Trans>Active</Trans> });
    columns.push({key:'createdBy', width: 170, sorter:true,title:<Trans>Created By</Trans>})
    columns.push({key:'createdDate', width: 140, dateTimeFilter: true, sorter:true,title:<Trans>Created Date</Trans>})
    columns.push({key:'updatedBy', width: 170, sorter:true,title:<Trans>Updated By</Trans>})
    columns.push({key:'updatedDate', width: 140, dateTimeFilter: true, sorter:true,title:<Trans>Updated Date</Trans>})

    return{
        columns:columns,
        url:"/resource/items"
    };

}
export default ItemModel();
