import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const EmailLogModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'id', width: 110, sorter:true,title:<Trans>Id</Trans>, fixed: "left", direction:"desc"});
    columns.push({key:'addresses', width: 110, sorter:true,title:<Trans>Addresses</Trans>, fixed: "left" });
    columns.push({key:'subject', width: 110, sorter:true,title:<Trans>Subject</Trans>, fixed: "left" });
    columns.push({key:'attachments', width: 110, sorter:true,title:<Trans>Attachments</Trans>, fixed: "left"});
    columns.push({key:'expirationDate', dateFilter:true, width: 110, sorter:true,title:<Trans>Expiration date</Trans>, fixed: "left"});
    columns.push({key:'sendDate',dateTimeFilter:true, width: 110, sorter:true,title:<Trans>Send date</Trans>, fixed: "left"});

    return{
        columns:columns,
        url:"/resource/email-logs"
    };

}
export default EmailLogModel();
