import useSafeState from '../../utils/safe-state';
import React, {useEffect, useState, useRef} from "react";
import {Form, Row, Col, Input, Button, message, Select, Radio, Space, DatePickerProps, DatePicker, Card, Spin} from 'antd';
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import {useKeycloak} from "@react-keycloak/web";
import { AxiosInstance, AxiosResponse } from 'axios';
import type { RadioChangeEvent } from 'antd';
import moment from 'moment';

import getEnumArray from "../../utils/enums";

import {InboxOutlined} from "@ant-design/icons";

import {Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import {antdContext} from "../../utils/antdContext";
import { InputNumber } from 'antd';
import type { InputRef } from 'antd';
import ClaimListScreen from '../../screens/claim-handling/claim-list';
import CorrectionScreen from '../../screens/claim-handling/correction';
import {SelectType} from "#/interfaces/SelectType";
import {DateTimeFormat} from "../../components/calendar-info/calendar-info";


interface Interface{
    requestId: any;
    mode?: "View" | "AddNew" | "Edit";
}

const ReleaseForm = (props : Interface) => {

    const { TextArea } = Input;
    const [responsibleOrganisationOptions,setResponsibleOrganisationOptions] = useSafeState<any>();
    const [claimTypeOptions,setClaimTypeOptions] = useSafeState<any>();
    const [importanceRatingOptions,setImportanceRatingOptions] = useSafeState<any>();
    const [spareIdentifierOptions,setSpareIdentifierOptions] = useSafeState<any>();
    const [demandedImmediateActionOptions,setDemandedImmediateActionOptions] = useSafeState<any>();
    const [itemNoSearch, setItemNoSearch] = useSafeState<any>();
    const [items, setItems] = useSafeState<any>([]);
    const [selectedItem, setSelectedItem] = useSafeState<any>();
    const [itemNo, setItemNo] = useSafeState<any>([]);
    const [organizationOptions,setOrganizationOptions] = useSafeState<any>();
    const [supplierOptions,setSupplierOptions] = useSafeState<any>();
    const [userOptions,setUserOptions] = useSafeState<any>();
    const [mainOptions,setMainOptions] = useSafeState<any>();
    const [partSource, setPartSource] = useSafeState<any>();
    const [requestId, setRequestId] = useSafeState<any>();
    const [isOtherEnable, setOtherEnable] = useSafeState<boolean>(false);


    const [form] = Form.useForm();
    
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");

    const [value, setValue] = useState(1);
    const [loading, setLoading] = useSafeState(false);
   

    const partSourceOnChange = (e: RadioChangeEvent) => {
        console.log('part source radio checked', e.target.value);
        setValue(e.target.value);
        form.setFieldsValue({ responsibleorganisation: ''});
        setMainOptions(form.getFieldValue(["partsource"]) == 1 ? organizationOptions : supplierOptions);
    };

    const nextItemMarkOnChange = (e: RadioChangeEvent) => {
        console.log('next item mark radio checked', e.target.value);
        setValue(e.target.value);
    };

    const releaseDatepickerOnChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };

    const immediateActionDatepickerOnChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };

    const finalActionDatepickerOnChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };

    const foundDatepickerOnChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };

    const wrongQuantityOnChange = (value: number) => {
        form.setFieldsValue({'wrongquantity':value});
    };


    const handleDemandActionChange = (value: any) => {

        if(value == 'OTHER'){
            setOtherEnable(true);
        } else {
            setOtherEnable(false);
        }
    }


    const finalWrongQuantityOnChange = (value: number) => {
        form.setFieldsValue({'finalwrongquantity':value});
    };

    const [result, setResult] = useSafeState<boolean>();
    const [isView, setIsView] = useSafeState<boolean>();
    const {initialized, keycloak} = useKeycloak();
    const [userId, setUserId] = useSafeState<number>();
    const [nextClaimSeq, setNextClaimSeq] = useSafeState<number>();
    const [back, setBack] = useSafeState<boolean>(false);


    const getUserId = () => {
        if(axiosInstance.current != null) {
            
            let obj: any = JSON.parse(JSON.stringify(keycloak.tokenParsed));

            axiosInstance.current.get("/resource/user/get-user?userName=" + obj.preferred_username, {})
                .then(function (response : AxiosResponse<any>) {
                    let data: any = JSON.parse(JSON.stringify(response.data));
                    setUserId(data.id);
                })
                .catch(function (error :any) {
                    message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                    console.log(error)
            });
        }
    }

    const getNextClaimSeq = () => {

        if(axiosInstance.current != null) {

            axiosInstance.current.get("/resource/request-data/get-next-claim-seq", {})
            .then(function (response : AxiosResponse<any>) {
                setNextClaimSeq(response.data);
                form.setFieldsValue({'regnumber':response.data});
            }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
            });

        }
    }

    const viewMode = () => {
        if(axiosInstance.current != null) {
            
            axiosInstance.current.get("/resource/request-data/get-request-data?requestId=" + props.requestId, {}
                ).then(function (response : AxiosResponse<any>)
                {
                   
                    let dataArray:any[] = JSON.parse(JSON.stringify(response.data));
                    
                    dataArray.forEach(value => {
                        switch (value.name) {
                            case 'regnumber':
                                form.setFieldsValue({"regnumber":value.value});
                            break;
                            case 'refnumber':
                                form.setFieldsValue({"refnumber":value.value});
                            break;
                            case 'releasedate':
                                const releaseDate = new Date(value.value);
                                const releaseMoment = moment(releaseDate);
                                form.setFieldsValue({"releasedate":releaseMoment});
                            break;
                            case 'partsource':
                                value.value == 1 ? form.setFieldsValue({"partsource":1}) : form.setFieldsValue({"partsource":2});
                            break;
                            case 'responsibleorganisation':
                                form.setFieldsValue({"responsibleorganisation":value.value});
                            break;
                            case 'statussign':
                                form.setFieldsValue({"statussign":value.value});
                            break;
                            case 'immediateaction':
                                const immediateactionDate = new Date(value.value);
                                const immediateactionMoment = moment(immediateactionDate);
                                form.setFieldsValue({"immediateaction":immediateactionMoment});
                            break;
                            case 'finalaction':
                                const finalactionDate = new Date(value.value);
                                const finalactionMoment = moment(finalactionDate);
                                form.setFieldsValue({"finalaction":finalactionMoment});
                            break;
                            case 'releaser':
                                form.setFieldsValue({"releaser":value.value});
                            break;
                            case 'checker':
                                form.setFieldsValue({"checker":value.value});
                            break;
                            case 'approval':
                                form.setFieldsValue({"approval":value.value});
                            break;
                            case 'other':
                                form.setFieldsValue({"other":value.value});
                            break;
                            case 'remark':
                                form.setFieldsValue({"remark":value.value});
                            break;
                            case 'claimtype':
                                form.setFieldsValue({"claimtype":value.value});
                            break;
                            case 'importancerating':
                                form.setFieldsValue({"importancerating":value.value});
                            break;
                            case 'spareidentifier':
                                form.setFieldsValue({"spareidentifier":value.value});
                            break;
                            case 'sparepartname': 
                                form.setFieldsValue({"sparepartname":value.value});
                            break;
                            case 'foundplace': 
                                form.setFieldsValue({"foundplace":value.value});
                            break;
                            case 'founddate':
                                const dt = new Date(value.value);
                                const dtMoment = moment(dt);
                                form.setFieldsValue({"founddate": dtMoment});
                            break;
                            case 'nextitemmark':
                                value.value == 1 ? form.setFieldsValue({"nextitemmark":1}) : form.setFieldsValue({"nextitemmark":2});
                            break;
                            case 'demandedimmediateaction':
                                form.setFieldsValue({"demandedimmediateaction": value.value});
                                break;
                            case 'errorname':
                                form.setFieldsValue({"errorname":value.value});
                            break;
                            case 'wrongquantity':
                                form.setFieldsValue({"wrongquantity":value.value});
                            break;
                            case 'finalwrongquantity':
                                form.setFieldsValue({"finalwrongquantity":value.value});
                            break;
                            case 'errordescription':
                                form.setFieldsValue({"errordescription":value.value});
                            break;
                            default:
                            break;    
                        }
                        
                    });

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }
    }

    let nowDate = moment();

    useEffect(() => {
        getNextClaimSeq();
        form.setFieldsValue({'releasedate':nowDate});
        form.setFieldsValue({'immediateaction':nowDate});
        form.setFieldsValue({'releaser': localStorage.getItem('email') });
        setResponsibleOrganisationOptions(getEnumArray('responsibleOrganisations'));
        setClaimTypeOptions(getEnumArray('claimType'));
        setImportanceRatingOptions(getEnumArray('importanceRating'));
        setSpareIdentifierOptions(getEnumArray('spareIdentifier'));
        setDemandedImmediateActionOptions(getEnumArray('demandedImmediateAction'));
        setIsView(props.mode == 'View' ? true : false);
        if (!isView) {
            getUserId();
        }
        getOrganization();
        getSupplier();
        getClaimHandlingControllerUser();
        getClaimHandlingApproverUser();
        getUsers();
    }, []);


    useEffect(() => {

        getItems(itemNoSearch);

    }, [itemNoSearch]);


    useEffect(() => {
        if (isView || props.mode == 'Edit' ) {
            viewMode();
        }
    },[isView]

    );

    const handleFinish = () => {

        const obj = {
            initData: {
                regNum: form.getFieldValue(["regnumber"]),
                refNum: form.getFieldValue(["refnumber"]),
                claimType: form.getFieldValue(["claimtype"]),
                priority: form.getFieldValue(["importancerating"]),
                remark: form.getFieldValue(["remark"]),
                originOfThePart: form.getFieldValue(["partsource"]) == 1 ? "Internal list" : "Supplier list",
                responsibleDepartment: form.getFieldValue(["responsibleorganisation"]),
                partNumber: form.getFieldValue(["spareidentifier"]),
                partName: form.getFieldValue(["sparepartname"]),
                location: form.getFieldValue(["foundplace"]),
                findingDate: moment(form.getFieldValue(["founddate"])).format('YYYY-MM-DD'),
                qtyOfWrong: form.getFieldValue(["wrongquantity"]),
                nameOfError: form.getFieldValue(["errorname"]),
                descriptionOfError: form.getFieldValue(["errordescription"]),
                dateOfRelease: moment(form.getFieldValue(["releasedate"])).format('YYYY-MM-DD'),
                releaser: form.getFieldValue(["releaser"]),
                controlPerson: form.getFieldValue(["checker"]),
                approvalPerson: form.getFieldValue(["approval"]),
                otherStakeholder: form.getFieldValue(["other"]),
                signOfNextItem: form.getFieldValue(["nextitemmark"]) == 1 ? "Needed" : "Not needed",
                immediateActions: form.getFieldValue(["demandedimmediateaction"]),
                otherAction: form.getFieldValue(["otherAction"]),
                immediateActionDeadline: moment(form.getFieldValue(["immediateaction"])).format('YYYY-MM-DD'),
                finallyActionDeadline: moment(form.getFieldValue(["finalaction"])).format('YYYY-MM-DD')
            }
        };

        if(axiosInstance.current != null) {
            setLoading(true);
            
            axiosInstance.current
                .post(
                    "/resource/requests/claim-handling/start-and-release",
                    obj
                    )
                .then(function (response : AxiosResponse<any>) {

                    if (fileList.length > 0) {
                        handleUpload(response.data.id);
                    }
                    
                    setResult(false);
                    setPartSource(form.getFieldValue(["partsource"]));
                    setRequestId(response.data.id);

                })
                .catch(function (error :any) {
                    message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
                    console.log(error)
                    setLoading(false);
            });
        }
    }

    const getOrganization = () => {

        if(axiosInstance.current != null) {
            axiosInstance.current.get('/resource/settings/organizations')
                .then(function (response: AxiosResponse<any>) {
                    let res: Array<any> = [];

                    Object.entries(response.data).forEach(([key, value]) => {
                        res.push(
                            {
                                label: key,
                                value: value,
                            }
                        )
                    });
                    setOrganizationOptions(res);
                    setMainOptions(res);
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    }

    const getClaimHandlingControllerUser = () => {

        if(axiosInstance.current != null) {
            axiosInstance.current.get('/resource/settings/claim-handling-controller-user')
                .then(function (response: AxiosResponse<any>) {
                    form.setFieldsValue({checker: response.data});
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    }

    const getClaimHandlingApproverUser = () => {

        if(axiosInstance.current != null) {
            axiosInstance.current.get('/resource/settings/claim-handling-approver-user')
                .then(function (response: AxiosResponse<any>) {
                    form.setFieldsValue({approval: response.data});
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    }

    const getSupplier = () => {

        if(axiosInstance.current != null) {

            axiosInstance.current.get('/resource/suppliers')
                .then(function (response: AxiosResponse<any>) {
                    if (Array.isArray(response.data)) {
                        let supplierArray: Array<SelectType> = [];
                        response.data.forEach(value => {

                            const label = value.code;

                            supplierArray.push(
                                {
                                    label: label,
                                    value: label,
                                }
                            )
                        });
                        setSupplierOptions(supplierArray);
                    }
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    }

    const getUsers = () => {

        if(axiosInstance.current != null) {

            axiosInstance.current.get('/resource/user')
                .then(function (response: AxiosResponse<any>) {
                    console.log(response.data);
                    if (Array.isArray(response.data)) {
                        let userArray: Array<SelectType> = [];
                        response.data.forEach(value => {
                            userArray.push(
                                {
                                    label: value.email,
                                    value: value.email,
                                }
                            )
                        });
                        setUserOptions(userArray);
                    }
                })
                .catch(function (error: any) {
                    console.log(error);
                });
        }
    }

    const getItems = (itemNo: string) => {

        if (itemNo) {
            if (axiosInstance.current != null) {

                let queryJson: string = '{';
                queryJson += itemNo ? '"id": { "itemNo": "' + itemNo + '"} , ' : '';
                queryJson += '}';

                if (queryJson.length > 2) {
                    queryJson =
                        queryJson.substr(0, queryJson.length - 3) +
                        queryJson.substr(queryJson.length - 2, queryJson.length);
                }

                axiosInstance.current
                    .post('/resource/items/page', {
                        start: 0,
                        length: 20,
                        direction: 'asc',
                        sorted_field: 'id.itemNo',
                        query: JSON.parse(queryJson),
                    })
                    .then(function (response: AxiosResponse<any>) {
                            setItems(response.data.data);
                            setItemNo(generateOptions(response.data.data, 'itemNo'));
                            console.log(response.data.data);
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });
            }
        }
    };



    const { Dragger } = Upload;

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: true,
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            return false;
        },
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
          },
        fileList,
    };

    const handleUpload = (requestId:any) => {
        
        fileList.forEach((file) => {
          const uploadedFile = new FormData();
          uploadedFile.append('uploadedFile', file as RcFile);
            if (axiosInstance.current != null) {

                axiosInstance.current.post("/resource/request-files/upload-file?requestId=" + requestId + "&actionId=1", uploadedFile,
                    { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
                        //message.success(antdContext('Sikeres feltöltés'));
                        setUploading(false);
                    }).catch(function (error :any) {
                        //message.error(antdContext('Sikertelen feltöltés'));
                        console.log(error)
                        setUploading(false);
                });
            }
        });
        setUploading(true);
        
        
    };


    const inputRef = useRef<InputRef>(null);

    const sharedProps = {
        ref: inputRef,
    };

    type optionalType = {
        key?: number;
        label: any;
        value: string;
    };

    const handleOutDeviceToOnChange = (value: any) => {
        let actItem: any;
        for (var i = 0; i < items.length; i++) {
            if (items[i]['id']['itemNo'] == value) {
                actItem = items[i];
                setSelectedItem(actItem);
                break;
            }
        }

        if (actItem) {
            form.setFieldsValue({ spareidentifier: actItem['id']['itemNo'] });
            form.setFieldsValue({ sparepartname: actItem['nameHun'] });
            if (mainOptions == supplierOptions) {
                form.setFieldsValue({ responsibleorganisation: actItem['id']['supplier']['code'] });
            }
        }
    };

    const generateOptions = (data: any, val: string): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = { key: i, value: data[i]['id'][val], label: data[i]['id'][val] };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    return(
        result == undefined ? (
            back == true ? (
                <ClaimListScreen/>
            ) : (
                <Form id="release"  layout='vertical' form={form}  onFinish={() => handleFinish()}>
                <Spin spinning={loading}></Spin>    
                <Row gutter={24}>
                    <Col span={12}>
                        <h2><Trans>Release</Trans></h2>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Card style={{ width: 400, margin: 10 }}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="statussign"
                                    label={<Trans>Status sign</Trans>}
                                    initialValue={'10'}
                                    children={<Input disabled={true}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter the status sign</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="regnumber"

                                    label={<Trans>Registration number</Trans>}
                                    children={<Input disabled={true}/>}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="refnumber"

                                    label={<Trans>Reference number</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}

                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter a reference number</Trans>,
                                        validator: () => {

                                            let v:string = form.getFieldValue('refnumber');

                                            if (v == undefined || v.length <= 0) {
                                                inputRef.current!.focus({});
                                                return Promise.reject('');
                                            } else {
                                                return Promise.resolve('');
                                            }
                                        }
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="claimtype"
                                    label={<Trans>Claim type</Trans>}
                                    children={
                                        <Select disabled={isView}
                                                notFoundContent={<Trans id={'No data'}/>}
                                                style={{ width: '100%' }}
                                                placeholder={<Trans id={'claim type'}></Trans>}
                                                options={claimTypeOptions}
                                        />
                                    }
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter the claim type</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="importancerating"
                                    label={<Trans>Importance rating</Trans>}
                                    children={
                                        <Select disabled={isView}
                                                notFoundContent={<Trans id={'No data'}/>}
                                                style={{ width: '100%' }}
                                                placeholder={<Trans id={'Importance rating'}></Trans>}
                                                options={importanceRatingOptions}
                                        />
                                    }
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter the importance rating</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="remark"
                                    label={<Trans>Remark</Trans>}
                                    children={<TextArea disabled={isView} rows={4}/>}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="datafiles"
                                    children={<Dragger disabled={isView} {...uploadProps}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text"><Trans>Click or drag file to this area to upload</Trans></p>
                                    </Dragger>
                                    }
                                />
                            </Col>
                        </Row>
                    </Card>
                    <Card style={{ width: 400, margin: 10 }}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="partsource"
                                    initialValue={1}
                                    label={<Trans>Source of spare part</Trans>}
                                    children={<Radio.Group disabled={isView} onChange={partSourceOnChange} value={value}>
                                        <Space direction="vertical">
                                            <Radio value={1} ><Trans>Internal list</Trans></Radio>
                                            <Radio value={2} ><Trans>Supplier list</Trans></Radio>
                                        </Space>
                                    </Radio.Group>
                                    }
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="spareidentifier"
                                    label={<Trans>Spare identifier</Trans>}
                                    children={
                                        <Select
                                            allowClear
                                            disabled={isView}
                                            showSearch={true}
                                            style={{ width: '100%' }}
                                            notFoundContent={<Trans id={'No data'}/>}
                                            placeholder={<Trans id={'Spare identifier'}></Trans>}
                                            onSearch={value => setItemNoSearch(value)}
                                            onChange={value => handleOutDeviceToOnChange(value)}
                                            options={itemNo}
                                        />

                                    }
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter the spare identifier</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="sparepartname"
                                    label={<Trans>Sparepart name</Trans>}
                                    initialValue={'alkatrész neve'}
                                    children={<Input disabled={true}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter the spare name</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="responsibleorganisation"
                                    label={<Trans>Responsible organisation</Trans>}
                                    children={
                                        <Select disabled={isView}
                                                showSearch={true}
                                                notFoundContent={<Trans id={'No data'}/>}
                                                style={{ width: '100%' }}
                                                placeholder={<Trans id={'Responsible organisation'}></Trans>}
                                                options={mainOptions}
                                        />
                                    }
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter the responsible organisation</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="foundplace"
                                    label={<Trans>Found place</Trans>}
                                    children={<Input disabled={isView} {...sharedProps}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter the found place name</Trans>,
                                        validator: () => {

                                            let v:string = form.getFieldValue('foundplace');

                                            if (v == undefined || v.length <= 0) {
                                                inputRef.current!.focus({});
                                                return Promise.reject('');
                                            } else {
                                                return Promise.resolve('');
                                            }
                                        }
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="founddate"
                                    label={<Trans>Found date</Trans>}
                                    children={<DatePicker placeholder='Dátum' disabled={isView} onChange={foundDatepickerOnChange}
                                                          format={'YYYY.MM.DD'}/>}
                                    rules={[{
                                        type:"date",
                                        required: true,
                                        message: <Trans>Please enter a found date</Trans>
                                    }]}
                                />
                            </Col>

                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="wrongquantity"
                                    label={<Trans>Wrong quantity</Trans>}
                                    children={<InputNumber min={0} disabled={isView} onChange={wrongQuantityOnChange}/>}
                                    rules={[{
                                        type:"number",
                                        required: true,
                                        message: <Trans>Please enter the wrong quantity</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="errorname"
                                    label={<Trans>Error name</Trans>}
                                    children={<Input {...sharedProps} disabled={isView} />}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter the error name</Trans>,
                                        validator: () => {

                                            let v:string = form.getFieldValue('refnumber');

                                            if (v == undefined || v.length <= 0) {
                                                inputRef.current!.focus({});
                                                return Promise.reject('');
                                            } else {
                                                return Promise.resolve('');
                                            }
                                        }
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="errordescription"
                                    label={<Trans>Error description</Trans>}
                                    children={<TextArea disabled={isView} rows={4}/>
                                    }
                                />
                            </Col>
                        </Row>
                    </Card>
                    <Card style={{width: 400, margin: 10 }}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="releasedate"
                                    label={<Trans>Release date</Trans>}
                                    children={<DatePicker placeholder='Dátum' disabled={isView} onChange={releaseDatepickerOnChange}
                                                          defaultValue={moment()}
                                                          format={'YYYY.MM.DD'} />}

                                    rules={[{
                                        type:"date",
                                        required: true,
                                        message: <Trans>Please enter a release date</Trans>,

                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="releaser"
                                    label={<Trans>Releaser</Trans>}
                                    initialValue={'kiadó'}
                                    children={<Input disabled={true}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter the releaser name</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="checker"
                                    label={<Trans>Checker person</Trans>}
                                    initialValue={'ellenőrző'}
                                    children={<Input disabled={true}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter the checker name</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="approval"
                                    label={<Trans>Approval person</Trans>}
                                    initialValue={'jóváhagyó személy'}
                                    children={<Input disabled={true}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter the approval name</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="other"
                                    label={<Trans>Other person</Trans>}
                                    children={
                                        <Select disabled={isView}
                                                notFoundContent={<Trans id={'No data'}/>}
                                                style={{ width: '100%' }}
                                                placeholder={<Trans id={'Other users'}></Trans>}
                                                options={userOptions}
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                    </Card>
                    <Card style={{width: 400, margin: 10 }}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="nextitemmark"
                                    initialValue={1}
                                    label={<Trans>Next item mark</Trans>}
                                    children={<Radio.Group disabled={isView} onChange={nextItemMarkOnChange} value={value}>
                                        <Space direction="vertical">
                                            <Radio value={1}><Trans>Needed</Trans></Radio>
                                            <Radio value={2}><Trans>Not needed</Trans></Radio>
                                        </Space>
                                    </Radio.Group>
                                    }
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="demandedimmediateaction"
                                    label={<Trans>Demanded immediate action</Trans>}
                                    children={
                                        <Select disabled={isView}
                                                notFoundContent={<Trans id={'No data'}/>}
                                                style={{ width: '100%' }}
                                                placeholder={<Trans id={'Demanded immediate action'}></Trans>}
                                                options={demandedImmediateActionOptions}
                                                onChange={handleDemandActionChange}
                                        />
                                    }
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter the demanded immediate action</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="otherAction"
                                    label={<Trans>Other Action</Trans>}
                                    children={<TextArea disabled={isView || !isOtherEnable} rows={4}/>}
                                    rules={[{
                                        type:"string",
                                        required: isOtherEnable,
                                        message: <Trans>Please enter an other action</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="immediateaction"
                                    label={<Trans>Immediate action deadline</Trans>}
                                    children={<DatePicker placeholder='Dátum' disabled={isView} onChange={immediateActionDatepickerOnChange}
                                                          defaultValue={moment()}
                                                          format={'YYYY.MM.DD'}/>}
                                    rules={[{
                                        type:"date",
                                        required: true,
                                        message: <Trans>Please enter an immediate action date</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="finalaction"
                                    label={<Trans>Final action deadline</Trans>}
                                    children={<DatePicker placeholder='Dátum' disabled={isView} onChange={finalActionDatepickerOnChange}
                                                          format={'YYYY.MM.DD'}/>}
                                    rules={[{
                                        type:"date",
                                        required: true,
                                        message: <Trans>Please enter a final action date</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Row>
                    <Form.Item
                        children={
                            <>
                                <Button htmlType="button" type="primary" onClick={() => setBack(true)} style={{marginRight: 5}}>
                                    <Trans>Back</Trans>
                                </Button>
                                <Button htmlType="button" type="primary" onClick={() => form.submit()} disabled={isView}>
                                    <Trans>Save</Trans>
                                </Button>
                            </>
                        }
                    />
            </Form>
            )
            ) : (
                    <ClaimListScreen/>
                )
            );

}
export default ReleaseForm;
