import useSafeState from '../../utils/safe-state';
import React, {useEffect, useState, useRef} from "react";
import {Form, Row, Col, Input, Button, message, Select, Radio, Space, DatePickerProps, DatePicker} from 'antd';
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import {useKeycloak} from "@react-keycloak/web";
import { AxiosInstance, AxiosResponse } from 'axios';
import type { RadioChangeEvent } from 'antd';
import moment from 'moment';

import getEnumArray from "../../utils/enums";

import {InboxOutlined} from "@ant-design/icons";

import {Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import {antdContext} from "../../utils/antdContext";
import { InputNumber } from 'antd';
import type { InputRef } from 'antd';
import ClaimListScreen from '../../screens/ppap/claim-list';



interface Interface{
    requestId: any;
    mode?: "View" | "AddNew";
}

const MSCDecisionForm = (props : Interface) => {

    
    const { TextArea } = Input;
    const [responsibleOrganisationOptions,setResponsibleOrganisationOptions] = useSafeState<any>();
    const [claimTypeOptions,setClaimTypeOptions] = useSafeState<any>();
    const [importanceRatingOptions,setImportanceRatingOptions] = useSafeState<any>();
    const [spareIdentifierOptions,setSpareIdentifierOptions] = useSafeState<any>();
    const [demandedImmediateActionOptions,setDemandedImmediateActionOptions] = useSafeState<any>();
    const [itemNoSearch, setItemNoSearch] = useSafeState<any>();
    const [items, setItems] = useSafeState<any>([]);
    const [selectedItem, setSelectedItem] = useSafeState<any>();
    const [itemNo, setItemNo] = useSafeState<any>([]);

    const [form] = Form.useForm();
    
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");

    const [value, setValue] = useState(1);
    const [loading, setLoading] = useSafeState(false);
    

    const [result, setResult] = useSafeState<boolean>();
    const [isView, setIsView] = useSafeState<boolean>();
    const {initialized, keycloak} = useKeycloak();
    const [userId, setUserId] = useSafeState<number>();
    const [back, setBack] = useSafeState<boolean>(false);


    const getUserId = () => {
        if(axiosInstance.current != null) {
            
            let obj: any = JSON.parse(JSON.stringify(keycloak.tokenParsed));

            const resp = axiosInstance.current.get("/resource/user/get-user?userName=" + obj.preferred_username, {
                    
                }
                ).then(function (response : AxiosResponse<any>)
                {
                    let data: any = JSON.parse(JSON.stringify(response.data));

                    setUserId(data.id);
                    

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                
            });

            
        }
    }

    const viewMode = () => {
        if(axiosInstance.current != null) {
            
            const resp = axiosInstance.current.get("/resource/request-data/get-request-data?requestId=" + props.requestId, {
                    
                }
                ).then(function (response : AxiosResponse<any>)
                {
                   
                    let dataArray:any[] = JSON.parse(JSON.stringify(response.data));
                   
                    dataArray.forEach(value => {
                         console.log("value: " + value.value);
                        switch (value.name) {
                            case 'demand':
                                form.setFieldsValue({"demand":value.value});
                            break;                            
                            case 'mlauploaded':
                                value.value == 'true' ? form.setFieldsValue({"mlauploaded":1}) : form.setFieldsValue({"mlauploaded":2});
                            break;
                            default:
                            break;    
                        }
                        
                    });

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }
    }

    useEffect(() => {
        setIsView(props.mode == 'View' ? true : false);
        if (!isView) {
            
            getUserId();
        }
    }, []);

    useEffect(() => {
        if (isView) {
            viewMode();
        }
    },[isView]

    );

    const handleFinish = () => {
        
        const obj = {
            demand: form.getFieldValue(["demand"]),
            mlauploaded: form.getFieldValue(["mlauploaded"]) == 1 ? 'true' : 'false',
        };

        if(axiosInstance.current != null) {
            setLoading(true);
            
            axiosInstance.current.post("/resource/requests-to-actions/newRequestToAction?requestId=" + props.requestId, {

                    reqData: obj
                }
                ).then(function (response : AxiosResponse<any>)
                {
                    if (fileList.length > 0) {
                        
                        handleUpload(response.data.request.id);
                    }
                    
                    setResult(false);

                }).catch(function (error :any) {
                message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }

    }

    const { Dragger } = Upload;

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: true,
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            return false;
        },
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
          },
        fileList,
    };

    const handleUpload = (requestId:any) => {
        
        fileList.forEach((file) => {
          const uploadedFile = new FormData();
          uploadedFile.append('uploadedFile', file as RcFile);
            if (axiosInstance.current != null) {

                axiosInstance.current.post("/resource/request-files/upload-file?requestId=" + requestId + "&actionId=33", uploadedFile,
                    { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
                        setUploading(false);
                    }).catch(function (error :any) {
                        console.log(error)
                        setUploading(false);
                });
            }
        });
        setUploading(true);
        
        
    };

    return(
        result == undefined ? (
            back == true ? (
                <ClaimListScreen/>
            ) : (
                <Form id="mscdecision"  layout='vertical' form={form}  onFinish={() => handleFinish()}>
                <Row gutter={24}>
                    <Col span={12}>
                        <h2><Trans>MSC Decision</Trans></h2>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name="mlauploaded"
                            initialValue={1}
                            label={<Trans>MLA uploaded</Trans>}
                            children={<Radio.Group value={value} disabled={isView}>
                                <Space direction="vertical">
                                    <Radio value={1}><Trans>Yes</Trans></Radio>
                                    <Radio value={2}><Trans>No</Trans></Radio>
                                </Space>
                            </Radio.Group>
                        }
                        />
                    </Col>    
                    <Col span={6}>
                            <Form.Item
                                name="demand"
                                label={<Trans>PPAP Demand detect</Trans>}
                                children={<TextArea disabled={isView} rows={4} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the demand</Trans>
                                }]}
                            />
                    </Col>      
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name="datafiles"
                            label={<Trans>MSC decision result</Trans>}
                            children={<Dragger disabled={isView} {...uploadProps}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text"><Trans>Click or drag file to this area to upload</Trans></p>
                          </Dragger>
                          }
                        />
                    </Col>
                </Row>
                
                {
                    isView ? (
                        <Form.Item
                            children={
                                <Button htmlType="button" type="primary" onClick={() => setBack(true)}>
                                    <Trans>Back</Trans>
                                </Button>
                            }
                    />
                    ):(
                    <Form.Item
                        children={
                            <Button htmlType="button" type="primary" onClick={() => form.submit()}>
                                <Trans>Save</Trans>
                            </Button>
                        }
                    />)
                }

            </Form>
            )
            ) : (
                <ClaimListScreen/>
            )
            );

}
export default MSCDecisionForm;
