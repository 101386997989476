import React from 'react';
import { Form, Row, Col } from 'antd';
import XMLViewer from 'react-xml-viewer';
import { SapConnectionModel } from '../../models/sap-connection/sap-connection';

interface Interface {
    data?: SapConnectionModel[];
    mode?: 'Edit' | 'Add new';
    onClose?: () => void;
}

const SapConnectionForm = (props: Interface) => {
    const data = props.data || {};
    const [form] = Form.useForm();

    let fieldsValues = [];
    for (const [key, value] of Object.entries(data)) {
        fieldsValues.push({ name: key, value: value });
    }
    form.setFields(fieldsValues);

    let url = form.getFieldValue(['requestUrl']);
    const xml = form.getFieldValue(['requestBody']);
    const customTheme = {
        attributeKeyColor: "#0074D9",
        attributeValueColor: "#2ECC40"
      };

    return (
        <Form id={props.mode} layout="vertical">
            <Row gutter={16}>
                <Col span={24}>
                    <div
                        style={{
                            fontFamily: 'monospace',
                            backgroundColor: 'rgb(50,41,49)',
                            position: 'relative',
                            padding: 10,
                            color: 'white',
                        }}
                    >
                        Request URL: <span style={{ color: 'rgb(253, 139, 25)' }}>{url}</span>
                    </div>
                </Col>
            </Row>
            
            <Row gutter={16}>
                <Col span={24}>
                    {xml != null && xml !== '' ? (
                        <XMLViewer xml={xml} theme={customTheme} invalidXml={"Hibás XML"} collapsible={true}/>
                    ) : (
                        <></>
                    )}
                </Col>
            </Row>
            
        </Form>
    );
};
export default SapConnectionForm;
