
import {FC} from "react";

import React from 'react';

import PPAPApprovalForm from "../../forms/ppap/ppap-approval-form";

interface Interface{
    requestId: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

interface Interface{
    requestId: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

const PPAPApprovalScreen: FC<Interface> = (props:any) => {

    

    return (
            
                <PPAPApprovalForm
                    requestId={props.requestId}
                />
            
    );
}
export default PPAPApprovalScreen;
