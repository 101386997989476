import useSafeState from '../../utils/safe-state';
import React, {useEffect, useState, useRef} from "react";
import {Form, Row, Col, Input, Button, message, Select, Radio, Space, DatePickerProps, DatePicker} from 'antd';
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import {useKeycloak} from "@react-keycloak/web";
import { AxiosInstance, AxiosResponse } from 'axios';
import type { RadioChangeEvent } from 'antd';
import moment from 'moment';

import getEnumArray from "../../utils/enums";

import {InboxOutlined} from "@ant-design/icons";

import {Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import {antdContext} from "../../utils/antdContext";
import { InputNumber } from 'antd';
import type { InputRef } from 'antd';
import ClaimListScreen from '../../screens/ppap/claim-list';



interface Interface{
    requestId: any;
    mode?: "View" | "AddNew";
}

const PPAPUploadForm = (props : Interface) => {

    
    const { TextArea } = Input;
    const [responsibleOrganisationOptions,setResponsibleOrganisationOptions] = useSafeState<any>();
    const [claimTypeOptions,setClaimTypeOptions] = useSafeState<any>();
    const [importanceRatingOptions,setImportanceRatingOptions] = useSafeState<any>();
    const [spareIdentifierOptions,setSpareIdentifierOptions] = useSafeState<any>();
    const [demandedImmediateActionOptions,setDemandedImmediateActionOptions] = useSafeState<any>();
    const [itemNoSearch, setItemNoSearch] = useSafeState<any>();
    const [items, setItems] = useSafeState<any>([]);
    const [selectedItem, setSelectedItem] = useSafeState<any>();
    const [itemNo, setItemNo] = useSafeState<any>([]);

    const [form] = Form.useForm();
    
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");

    const [value, setValue] = useState(1);
    const [loading, setLoading] = useSafeState(false);
    
   

    const partSourceOnChange = (e: RadioChangeEvent) => {
        console.log('part source radio checked', e.target.value);
        setValue(e.target.value);
    };

    const nextItemMarkOnChange = (e: RadioChangeEvent) => {
        console.log('next item mark radio checked', e.target.value);
        setValue(e.target.value);
    };

    const releaseDatepickerOnChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };

    const immediateActionDatepickerOnChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };

    const finalActionDatepickerOnChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };

    const foundDatepickerOnChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };

    const wrongQuantityOnChange = (value: number) => {
        form.setFieldsValue({'wrongquantity':value});
    };

    const finalWrongQuantityOnChange = (value: number) => {
        form.setFieldsValue({'finalwrongquantity':value});
    };

    const [result, setResult] = useSafeState<boolean>();
    const [isView, setIsView] = useSafeState<boolean>();
    const {initialized, keycloak} = useKeycloak();
    const [userId, setUserId] = useSafeState<number>();
    const [back, setBack] = useSafeState<boolean>(false);


    let idTokenParsed:any = JSON.parse(JSON.stringify(keycloak.idTokenParsed));
    let supplierCode:string = idTokenParsed.supplierCode;
    let uploader:string = idTokenParsed.name;

    const getSupplierName = () => {
        if(axiosInstance.current != null) {

            const resp = axiosInstance.current.get("/resource/suppliers/" + supplierCode, {
                    
                }
                ).then(function (response : AxiosResponse<any>)
                {
                    let data: any = JSON.parse(JSON.stringify(response.data));
                    
                    form.setFieldsValue({"suppliername":data.name});
                    form.setFieldsValue({"suppliercode":supplierCode});
                    form.setFieldsValue({"uploader":uploader});
                    

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                
            });

            
        }
    }

    const getUserId = () => {
        if(axiosInstance.current != null) {
            
            let obj: any = JSON.parse(JSON.stringify(keycloak.tokenParsed));

            const resp = axiosInstance.current.get("/resource/user/get-user?userName=" + obj.preferred_username, {
                    
                }
                ).then(function (response : AxiosResponse<any>)
                {
                    let data: any = JSON.parse(JSON.stringify(response.data));

                    setUserId(data.id);
                    

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                
            });

            
        }
    }

    const viewMode = () => {
        if(axiosInstance.current != null) {
            
            const resp = axiosInstance.current.get("/resource/request-data/get-request-data?requestId=" + props.requestId, {
                    
                }
                ).then(function (response : AxiosResponse<any>)
                {
                   
                    let dataArray:any[] = JSON.parse(JSON.stringify(response.data));
                    
                    dataArray.forEach(value => {
                        switch (value.name) {
                            case 'regnumber':
                                form.setFieldsValue({"regnumber":value.value});
                            break;
                            case 'refnumber':
                                form.setFieldsValue({"refnumber":value.value});
                            break;
                            case 'releasedate':
                                const releaseDate = new Date(value.value);
                                const releaseMoment = moment(releaseDate);
                                form.setFieldsValue({"releasedate":releaseMoment});
                            break;
                            case 'partsource':
                                value.value == 1 ? form.setFieldsValue({"partsource":1}) : form.setFieldsValue({"partsource":2});
                            break;
                            case 'responsibleorganisation':
                                form.setFieldsValue({"responsibleorganisation":value.value});
                            break;
                            case 'statussign':
                                form.setFieldsValue({"statussign":value.value});
                            break;
                            case 'immediateaction':
                                const immediateactionDate = new Date(value.value);
                                const immediateactionMoment = moment(immediateactionDate);
                                form.setFieldsValue({"immediateaction":immediateactionMoment});
                            break;
                            case 'finalaction':
                                const finalactionDate = new Date(value.value);
                                const finalactionMoment = moment(finalactionDate);
                                form.setFieldsValue({"finalaction":finalactionMoment});
                            break;
                            case 'releaser':
                                form.setFieldsValue({"releaser":value.value});
                            break;
                            case 'checker':
                                form.setFieldsValue({"checker":value.value});
                            break;
                            case 'approval':
                                form.setFieldsValue({"approval":value.value});
                            break;
                            case 'other':
                                form.setFieldsValue({"other":value.value});
                            break;
                            case 'remark':
                                form.setFieldsValue({"remark":value.value});
                            break;
                            case 'claimtype':
                                form.setFieldsValue({"claimtype":value.value});
                            break;
                            case 'importancerating':
                                form.setFieldsValue({"importancerating":value.value});
                            break;
                            case 'spareidentifier':
                                form.setFieldsValue({"spareidentifier":value.value});
                            break;
                            case 'sparepartname': 
                                form.setFieldsValue({"sparepartname":value.value});
                            break;
                            case 'foundplace': 
                                form.setFieldsValue({"foundplace":value.value});
                            break;
                            case 'founddate':
                                const founddateDate = new Date(value.value);
                                const founddateMoment = moment(founddateDate);
                                form.setFieldsValue({"founddate":founddateMoment});
                            break;
                            case 'nextitemmark':
                                value.value == 1 ? form.setFieldsValue({"nextitemmark":1}) : form.setFieldsValue({"nextitemmark":2});
                            break;
                            case 'demanedimmediateaction':
                                form.setFieldsValue({"demanedimmediateaction":value.value});
                            break;
                            case 'errorname':
                                form.setFieldsValue({"errorname":value.value});
                            break;
                            case 'wrongquantity':
                                form.setFieldsValue({"wrongquantity":value.value});
                            break;
                            case 'finalwrongquantity':
                                form.setFieldsValue({"finalwrongquantity":value.value});
                            break;
                            case 'errordescription':
                                form.setFieldsValue({"errordescription":value.value});
                            break;
                            default:
                            break;    
                        }
                        
                    });

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }
    }

    

    


    useEffect(() => {
        
        getSupplierName();
        setIsView(props.mode == 'View' ? true : false);
        if (!isView) {
            
            getUserId();
        }
    }, []);

    useEffect(() => {

        getItems(itemNoSearch);

    }, [itemNoSearch]);


    useEffect(() => {
        if (isView) {
            viewMode();
        }
    },[isView]

    );

    const handleFinish = () => {

        if(axiosInstance.current != null) {
            setLoading(true);
            
            axiosInstance.current.post("/resource/requests-to-actions/newRequestToAction?requestId=" + props.requestId, {
        
                }
                ).then(function (response : AxiosResponse<any>)
                {
                    if (fileList.length > 0) {
                        handleUpload(response.data.request.id);
                    }
                    
                    setResult(false);

                }).catch(function (error :any) {
                message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }

    }

    const getItems = (itemNo: string) => {

        if (itemNo) {
            if (axiosInstance.current != null) {

                let queryJson: string = '{';
                queryJson += itemNo ? '"id": { "itemNo": "' + itemNo + '"} , ' : '';
                queryJson += '}';

                if (queryJson.length > 2) {
                    queryJson =
                        queryJson.substr(0, queryJson.length - 3) +
                        queryJson.substr(queryJson.length - 2, queryJson.length);
                }

                axiosInstance.current
                    .post('/resource/items/page', {
                        start: 0,
                        length: 20,
                        direction: 'asc',
                        sorted_field: 'id.itemNo',
                        query: JSON.parse(queryJson),
                    })
                    .then(function (response: AxiosResponse<any>) {
                            setItems(response.data.data);
                            setItemNo(generateOptions(response.data.data, 'itemNo'));
                            console.log(response.data.data);
                    })
                    .catch(function (error: any) {
                        console.log(error);
                    });
            }
        }
    };


    const { Dragger } = Upload;

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: true,
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            return false;
        },
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
          },
        fileList,
    };

    const handleUpload = (requestId:any) => {
        
        fileList.forEach((file) => {
          const uploadedFile = new FormData();
          uploadedFile.append('uploadedFile', file as RcFile);
            if (axiosInstance.current != null) {

                axiosInstance.current.post("/resource/request-files/upload-file?requestId=" + requestId + "&actionId=33", uploadedFile,
                    { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
                        setUploading(false);
                    }).catch(function (error :any) {
                        console.log(error)
                        setUploading(false);
                });
            }
        });
        setUploading(true);
        
        
    };


   

    

    type optionalType = {
        key?: number;
        label: any;
        value: string;
    };

   

    const generateOptions = (data: any, val: string): optionalType[] => {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {
            let currentItem: optionalType = { key: i, value: data[i]['id'][val], label: data[i]['id'][val] };
            arrayToOptions.push(currentItem);
        }
        return arrayToOptions;
    };

    return(
        result == undefined ? (
            
                <Form id="ppapupload"  layout='vertical' form={form}  onFinish={() => handleFinish()}>
                <Row gutter={24}>
                    <Col span={12}>
                        <h2><Trans>PPAP Upload</Trans></h2>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name="datafiles"
                            children={<Dragger disabled={isView} {...uploadProps}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text"><Trans>Click or drag file to this area to upload</Trans></p>
                          </Dragger>
                          }
                        />
                    </Col>
                </Row>
                
                <Form.Item
                    children={
                        <Button htmlType="button" type="primary" onClick={() => form.submit()}>
                            <Trans>Save</Trans>
                        </Button>
                    }
                />

            </Form>
            )
            : (
                <ClaimListScreen/>
            )
            );

}
export default PPAPUploadForm;
