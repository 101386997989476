import useSafeState from '../../utils/safe-state';
import React, {useState, useEffect} from "react";
import {Form, Row, Col, Button, message, Radio, Space, RadioChangeEvent, Input, Tabs, Card} from 'antd';
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import {antdContext} from "../../utils/antdContext";
import ClaimListScreen from '../../screens/claim-handling/claim-list';
import ErrorReasonScreen from '../../screens/claim-handling/error-reason';
import AcceptToImplementScreen from '../../screens/claim-handling/accept-to-implement';
import { AxiosResponse } from 'axios';
import ReactJson from "react-json-view";


interface Interface{
    partSource: any;
    requestId: any;
    mode?: "View" | "Add new";
    onClose?: () => void;
}

const ApprovalToErrorReasonForm = (props : Interface) => {

    const [disabledJustification, setDisabledJustification] = useSafeState(true);
    const [justificationRequired, setJustificationRequired] = useSafeState(false);

    const { TextArea } = Input;
    const [loading, setLoading] = useSafeState(false);

    const [form] = Form.useForm();
    
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");
    const [value, setValue] = useState(1);
    const [result, setResult] = useSafeState<boolean>();
    const [isView, setIsView] = useSafeState<boolean>();
    const [isBack, setBack] = useSafeState<boolean>();

    const [infoData, setInfoData] = useSafeState<any>();

    const getInfo = (id: number) => {

        if(axiosInstance.current != null){

            axiosInstance.current.get(process.env.REACT_APP_API_BASE_URL + '/resource/request-data/get-request-data-view?requestId=' + id)
                .then(function (response: AxiosResponse<any>) {
                    setInfoData(response.data)
                }).catch(function (error :any) {
                message.error(antdContext('Hiba a mentés közben'));
            }).finally(() => {
            });
        }
    };


    const approvalResultOnChange = (e: RadioChangeEvent) => {
        console.log('approvalresult radio checked', e.target.value);
        if (e.target.value == '2'){
            setDisabledJustification(false);
            setJustificationRequired(true);

        } else {
            setDisabledJustification(true);
            setJustificationRequired(false);
        }
    };

    useEffect(() => {
        
        setIsView(props.mode == 'View' ? true : false);
        tabsData();
        getInfo(props.requestId);

    }, []);

    useEffect(() => {
        if (isView) {
            viewMode();
        }
    },[isView]

    );


    const viewMode = () => {
        if(axiosInstance.current != null) {
            
            const resp = axiosInstance.current.get("/resource/request-data/get-request-data?requestId=" + props.requestId, {
                    
                }
                ).then(function (response : AxiosResponse<any>)
                {
                   
                    let dataArray:any[] = JSON.parse(JSON.stringify(response.data));
                    
                    dataArray.forEach(value => {
                        switch (value.name) {
                            case 'justification501':
                                form.setFieldsValue({"justification":value.value});
                            break;
                            case 'approvalresult501':
                                value.value == 'true' ? form.setFieldsValue({"approvalresult":1}) : form.setFieldsValue({"approvalresult":2});
                            break;
                            default:
                            break;    
                        }
                        
                    });

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }
    }

    const tabsData = () => {
        if(axiosInstance.current != null) {
            
            const resp = axiosInstance.current.get("/resource/request-data/get-request-data?requestId=" + props.requestId, {
                    
                }
                ).then(function (response : AxiosResponse<any>)
                {
                   
                    let dataArray:any[] = JSON.parse(JSON.stringify(response.data));
                    
                    dataArray.forEach(value => {
                        
                        switch (value.name) {
                            case 'justification401':
                                form.setFieldsValue({"justification401":value.value});
                            break;
                            default:
                            break;    
                        }
                        
                    });

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }
    }

    const handleFinish = () => {
    
        let resultFlag = form.getFieldValue(["approvalresult"]) == 1 ? 'true' : 'false';
        
        const obj = {
            resultOfApprovalSender: resultFlag,
            resultOfApprovalSenderRemark: form.getFieldValue(["justification"])
        }

        if(axiosInstance.current != null) {
            setLoading(true);

            axiosInstance.current.post("/resource/requests-to-actions/newRequestToAction?requestId=" + props.requestId + "&resultFlag=" + resultFlag,
                obj
                ).then(() => {
                    setLoading(false);
                    setResult(resultFlag == 'true' ? true : false);

            }).catch(function (error :any) {
                message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });
        }

    }

    return(
        result == undefined ? (
            isBack ? (
                <ClaimListScreen/>
            ) : (
        <Form id="approval"  layout='vertical' form={form}  onFinish={() => handleFinish()}>
                    <Row gutter={24}>
                        <Card style={{ width: 400, margin: 10 }} title={<Trans>Approval result</Trans>}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="approvalresult"
                                        initialValue={1}
                                        children={<Radio.Group disabled={isView}  onChange={approvalResultOnChange} value={value}>
                                            <Space direction="vertical">
                                                <Radio value={1}><Trans>OK</Trans></Radio>
                                                <Radio value={2}><Trans>Not ok</Trans></Radio>
                                            </Space>
                                        </Radio.Group>
                                        }
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <Form.Item
                                        name="justification"
                                        label={<Trans>Justification</Trans>}
                                        children={<TextArea rows={4} disabled={disabledJustification}/>}
                                        rules={[{
                                            type:"string",
                                            required: justificationRequired,
                                            message: <Trans>Please enter the justification</Trans>
                                        }]}
                                    />
                                </Col>
                            </Row>
                        </Card>
                        <Card style={{ width: 740, margin: 10 }} title={<Trans>Info</Trans>}>
                            <Row gutter={24}>
                                <Col span={24}>
                                    <ReactJson
                                        name={false}
                                        collapsed={1}
                                        indentWidth={8}
                                        displayDataTypes={false}
                                        displayObjectSize={false}
                                        src={infoData}
                                        enableClipboard={false}
                                        quotesOnKeys={false}
                                        style={{
                                            padding: 2.5,
                                            width: 700,
                                            height: 500,
                                            overflowY: "scroll",
                                            color: "black",
                                            fontSize: "larger",
                                            fontFamily: "system-ui"
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Card>

                    </Row>

                <Form.Item
                    children={
                        <>
                            <Button htmlType="button" type="primary" onClick={() => setBack(true)} style={{marginRight: 5}}>
                                <Trans>Back</Trans>
                            </Button>
                            <Button htmlType="button" type="primary" onClick={() => form.submit()} disabled={isView}>
                                <Trans>Save</Trans>
                            </Button>
                        </>
                    }
                />
        </Form>
               )
               ) : (
            <ClaimListScreen/>
                )
    );

}
export default ApprovalToErrorReasonForm;
