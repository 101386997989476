
import {FC} from "react";

import React from 'react';

import PPAPUploadForm from "../../forms/ppap/ppap-upload-form";

interface Interface{
    requestId: any;
    mode?: "View" | "AddNew";
}
const PPAPUploadScreen: FC<Interface> = (props:any) => {

    return (
            
                <PPAPUploadForm
                    requestId={props.requestId}
                    mode={props.mode}
                />
            
    );
}
export default PPAPUploadScreen;
