import {ColumnMain} from "#/models/column-main";
import {Column} from "#/models/column";
import {Trans} from "@lingui/macro";
import React from "react";

const IncomingModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();

    columns.push({key:'id', width: 100, sorter:true,title:<Trans>#</Trans>, fixed: "left", direction:"desc", render: text => {return (<b>{text}</b>);} });
    columns.push({key:'deliveryDate', width: 150, sorter:true,title:<Trans>Delivery date</Trans>, fixed: "left"});
    columns.push({key:'status',tags:[
            {text:'OPENED',color:'blue'},
            {text:'CLOSED',color:'red'},
        ],
        uniqueSelectFilterData:[
            {
                label:<Trans>-</Trans>,
                value:null
            },{
                label:<Trans>OPENED</Trans>,
                value:"OPENED"
            },{
                label:<Trans>CLOSED</Trans>,
                value:"CLOSED"
            }
        ],
        width: 110, sorter:true,title:<Trans>Status</Trans>
    });


    columns.push({key:'poNumber', width: 110, sorter:true,title:<Trans>PoNumber</Trans>});
    columns.push({key:'poItemNumber', width: 130, sorter:true,title:<Trans>PoItemNumber</Trans>});
    columns.push({key:'orderNo', width: 150, sorter:true,title:<Trans>OrderNo</Trans> });
    columns.push({key:'itemNo', width: 140, sorter:true,title:<Trans>ItemNo</Trans> });
    columns.push({key:'supplierCode', width: 110, sorter:false,title:<Trans>Supplier code</Trans> });


    const qtyColumns: Array<Column> = new Array();
    qtyColumns.push({key:'orderQty', width: 110, sorter:true, title:<Trans>Ordered</Trans>});
    qtyColumns.push({key:'scannedQty', width: 110, sorter:true, title:<Trans>Scanned</Trans>});

    columns.push({
        key: 'qty',
        width: 157,
        sorter: false,
        filter: false,
        title: <Trans>qty</Trans>,
        children: qtyColumns,
    });


    columns.push({key:'sapDbId', width: 170, sorter:true,title:<Trans>SAP Db Id</Trans>});
    columns.push({key:'incomingStartedAt', width: 140, dateTimeFilter: false, sorter:true,title:<Trans>Created Date</Trans>});
    columns.push({key:'incomingStartedBy', width: 170, sorter:true,title:<Trans>Created By</Trans>});
    columns.push({key:'scannedAt', width: 140, dateTimeFilter: false, sorter:true,title:<Trans>Scanned At</Trans>});
    columns.push({key:'scannedBy', width: 170, sorter:true,title:<Trans>Scanned By</Trans>});


    return{
        columns:columns,
        url:"/resource/incoming-view"
    };

}
export default IncomingModel();
