import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import ClaimPPAPListModel from "../../models/dto/claim-ppap-list-model";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb, message, Popconfirm, Tooltip, Button} from "antd";
import {Trans} from "@lingui/macro";

import {
    EyeOutlined,
    ForwardOutlined,
    FileOutlined
} from "@ant-design/icons/lib";

import isAuthorized from "../../utils/is-authorized";
import {useKeycloak} from "@react-keycloak/web";
import {PlusOutlined} from "@ant-design/icons/lib";
import Drawer from "../../components/drawer/drawer";
import ClaimDrawer from "../../forms/claim-handling-forms/claim-drawer";
import MLAUploadScreen from "../ppap/mla-upload";
import MLAAcceptScreen from "../ppap/mla-accept";
import MLACheckScreen from "../ppap/mla-check";
import MLAApprovalScreen from "../ppap/mla-approval";
import MLAMSCApprovalScreen from "../ppap/mla-msc-approval";
import PPAPDemandScreen from "../ppap/ppap-demand";
import MSCDecisionScreen from "../ppap/msc-decision";
import PPAPUploadScreen from "../ppap/ppap-upload";
import PPAPAcceptScreen from "../ppap/ppap-accept";
import PPAPCheckScreen from "../ppap/ppap-check";
import PPAPApprovalScreen from "../ppap/ppap-approval";
import PPAPMSCApprovalScreen from "../ppap/ppap-msc-approval";
import PPAPSendMSCScreen from "../ppap/ppap-send-msc";
import SendApprovalScreen from "../ppap/send-approval";


const ClaimListScreen : FC = () => {

    const {initialized, keycloak} = useKeycloak();
    const [trigger, setTrigger] = useSafeState(false);

    
    const [currentScreen, setCurrentScreen] = useSafeState<any>('main');
    const [requestId, setRequestId] = useSafeState<any>();
    const [screenMode, setScreenMode] = useSafeState<any>();
    

    const text = <Trans>Claim handling uploaded files list</Trans>

    const drawer = Drawer(
        {
            title: text,
            children:
                <ClaimDrawer/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    let idTokenParsed = JSON.parse(JSON.stringify(keycloak.idTokenParsed));
    let organization = idTokenParsed.organization;
    let isPU = true;
    let isQA = true;
    let isSales = true;

    if (organization != undefined) {
        switch (organization) {
            case 'PU':
                isQA = false;
                isSales = false;
            break;
            case 'QA':
                isPU = false;
                isSales = false;
            break;
            case 'SALES':
                isQA = false;
                isPU = false;
            break;    
        }
    }

    const swScreen = (record:any) => {

                let currStr = 'main';
                setRequestId(record.id)


                switch (record.actionId) {
                    case 33:
                        currStr = 'mlaaccept';
                    break;
                    case 34:
                        currStr = 'mlacheck';
                    break;
                    case 35:
                        currStr = 'mlaapproval';
                    break;
                    case 36:
                        currStr = 'mlamscapproval';
                    break;
                    case 37:
                        currStr = 'ppapdemand';
                    break;
                    case 38:
                        currStr = 'mscdecision';
                    break;
                    case 39:
                        currStr = 'ppapupload';
                    break;
                    case 40:
                        currStr = 'ppapupload';
                    break;
                    case 41:
                        currStr = 'ppapaccept';
                    break;
                    case 42:
                        currStr = 'ppapaccept';
                    break;
                    case 43:
                        currStr = 'ppapcheck';
                    break;
                    case 45:
                        currStr = 'ppapapproval';
                    break;
                    case 48:
                        currStr = 'ppapmscapproval';
                    break;
                    default:
                    break;
                }

                setCurrentScreen(currStr);
                setScreenMode('AddNew');
    }

    const getViewScreen = (record:any) => {

        let currStr = 'main';
        setRequestId(record.id);

        switch (record.status) {
            case '14':
                currStr = 'mlaupload';
            break;
        }

        switch (record.actionId) {
            case 39:
                currStr = 'ppapdemand';
            break;
            case 40:
                currStr = 'mscdecision';
            break;
        }

        setCurrentScreen(currStr);
        setScreenMode('View');
    }

    const swButton = (record: any) => {
        return (
            <>
            <Tooltip title={<Trans>Continue</Trans>}>
                <Button onClick={()=>swScreen(record)}>
                    <ForwardOutlined  className={"ActionButtons"} style={{color: "#40a9ff"}} />
                </Button>
            </Tooltip>
            </>
        )
    }

    const action =  (isMTSK: Boolean, record: any) => {
       
            return (            

            <>
           
                <Tooltip  title={<Trans>Download files</Trans>}>
                      
                            <FileOutlined  className={"ActionButtons"} style={{color: "#40a9ff"}} onClick={()=>drawer.open("", {
                            requestId: record
                            })
                        }/>
                        
                </Tooltip>
            
                      
                { record.actionId == 33 || record.status == 19 || record.actionId == 17 ? (
                    <>
                    <Tooltip  title={<Trans>View</Trans>}>
                       
                            <EyeOutlined  className={"ActionButtons"} style={{color: "#40a9ff"}} onClick={()=>getViewScreen(record)}/>
                        
                    </Tooltip>
                    </>
                ) : (<></>)
                }
{
//console.log("actionid: " + record.actionId + ", status: " + record.status + ", ismtsk: " + isMTSK)
}
                { record.status != 26  ? (
                    isMTSK ? 
                    (record.actionId == 33 && (isPU) ||
                     record.actionId == 42 && (isQA)||
                     record.actionId == 45 && (isQA)|| 
                     record.actionId == 48 && (isQA)||
                     record.actionId == 41 && (isQA)?
                    (
                        swButton(record)):(<></>)) : 
                    (record.status == 13 || record.status == 19 
                      ? (
                        swButton(record)):(<></>))
                
                
                ): (<></>)
            }
                </>
    )};

    const actions = {
        width: isAuthorized(keycloak,["MTSK_SUPPL_USER", "MTSK_SUPPL_ADMIN"]) ? 110 : 25,
        render: (text: string, record: any) => action(isMTSK(), record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    const isMTSK = () => {
        return !isAuthorized(keycloak,["MTSK_SUPPL_USER", "MTSK_SUPPL_ADMIN"]);
    }

    return (
        <>
            {currentScreen == 'main' ? (
            <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
                <Breadcrumb.Item><Trans id={'Claim list PPAP'}></Trans></Breadcrumb.Item>
            </Breadcrumb>
            <DtoTable
                tableHeader={isMTSK() ? (<></>):(<Button type={"primary"} onClick={ () => setCurrentScreen('mlaupload')} style={{float: "left", marginRight:10}}><PlusOutlined /><span><Trans>Add new PPAP</Trans></span></Button>)}
                model={ClaimPPAPListModel}
                action={actions}
                trigger={trigger}
                pageSize={100}
                scroll={{ x: 1300, y: "65vh" }}
                />
                {drawer.component}
                </>
        ) :
        (

        currentScreen == 'mlaaccept' ? (
        <MLAAcceptScreen requestId = {requestId} mode = {screenMode}/>) : (
        currentScreen == 'mlacheck' ? (
        <MLACheckScreen requestId = {requestId} mode = {screenMode}/>) : (
        currentScreen == 'mlaaproval' ? (
        <MLAApprovalScreen requestId = {requestId} mode = {screenMode}/>) : (
        currentScreen == 'mlamscapproval' ? (
        <MLAMSCApprovalScreen requestId = {requestId} mode = {screenMode}/>) : (
        currentScreen == 'ppapdemand' ? (
        <PPAPDemandScreen requestId = {requestId} mode = {screenMode}/>) : (
        currentScreen == 'mscdecision' ? (
        <MSCDecisionScreen requestId = {requestId} mode = {screenMode} />) : (
        currentScreen == 'ppapupload' ? (
        <PPAPUploadScreen requestId = {requestId} mode = {screenMode}/>) : (
        currentScreen == 'ppapaccept' ? (
        <PPAPAcceptScreen requestId = {requestId} mode = {screenMode}/>) : (
        currentScreen == 'ppapcheck' ? (
        <PPAPCheckScreen requestId = {requestId} mode = {screenMode}/>) : (
        currentScreen == 'mlaupload' ? (
        <MLAUploadScreen requestId = {requestId} mode = {screenMode}/>) : (
        currentScreen == 'ppapapproval' ? (
        <PPAPApprovalScreen requestId = {requestId}/>) : (
        currentScreen == 'ppapmscapproval' ? (
        <PPAPMSCApprovalScreen requestId = {requestId} mode = {screenMode}/>) : (
        currentScreen == 'ppapsendmsc' ? (
        <PPAPSendMSCScreen requestId = {requestId} mode = {screenMode}/>) : (
        currentScreen == 'sendapproval' ? (
        <SendApprovalScreen requestId = {requestId} mode = {screenMode}/>) :  (<></>)
        )
        )
        )
        )
        )
        )
        )
        )
        )
        )
        )
        )
        )
        )
    }
    </>)
}
export default ClaimListScreen;
