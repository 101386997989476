import { Trans } from '@lingui/macro';
import moment from 'moment';
import * as React from 'react';

export interface CalendarInfoProps {
    data?: Partial<any>;
}

export enum DateTimeFormat {
    DATE = 'YYYY-MM-DD',
    LONG_TIME = 'HH:mm:ss',
    SHORT_TIME = 'HH:mm',
    DATE_LONG_TIME = 'YYYY-MM-DD HH:mm:ss',
    DATE_SHORT_TIME = 'YYYY-MM-DD HH:mm',
}


function CalendarInfoModal(props: CalendarInfoProps) {
    const data = props.data || {};

    const TransportationComp = (
        <div>
            <p>
                <b>
                    <Trans id={'Event name'}>Event name</Trans>:
                </b>{' '}
                {data.title}
            </p>
            <p>
                <b>
                    <Trans id={'Start'}>Start</Trans>:
                </b>{' '}
                {moment(data.timeStart).format(DateTimeFormat.DATE_LONG_TIME)}
            </p>
        </div>
    );

    return <div>{TransportationComp}</div>;
}
export default CalendarInfoModal;
