
import {FC} from "react";

import React from 'react';

import PPAPSendMSCForm from "../../forms/ppap/ppap-sendmsc-form";

interface Interface{
    requestId: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

interface Interface{
    requestId: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

const PPAPSendMSCScreen: FC<Interface> = (props:any) => {

    

    return (
            
                <PPAPSendMSCForm
                    requestId={props.requestId}
                />
            
    );
}
export default PPAPSendMSCScreen;
