import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const ForecastModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'id', width: 110, sorter:true,title:<Trans>Id</Trans>, fixed: "left", direction:"asc"});
    columns.push({key:'supplierCode.code', dataIndex:['supplierCode','code'], width: 110, sorter:true,title:<Trans>Supplier code</Trans>, fixed: "left", direction:"asc"});
    columns.push({key:'itemNo.id.itemNo', dataIndex:['itemNo','id','itemNo'], width: 110, sorter:true,title:<Trans>ItemNo</Trans>, fixed: "left", direction:"asc"});
    columns.push({key:'itemNo.id.suppplier.code', dataIndex:['itemNo','id','supplier','code'], width: 110, sorter:true,title:<Trans>Supplier code</Trans>, fixed: "left", direction:"asc"});
    columns.push({key:'deliveryDate', width: 110, sorter:true,title:<Trans>Delivery date</Trans>, fixed: "left", direction:"asc"});
   // columns.push({key:'orderDate', width: 110, sorter:true,title:<Trans>Order date</Trans>, fixed: "left", direction:"asc"});
    columns.push({key:'orderStatus', width: 110, sorter:true,title:<Trans>Status</Trans>, fixed: "left", direction:"asc"});

    return{
        columns:columns,
        url:"/resource/forecasts"
    };

}
export default ForecastModel();
