
import {FC} from "react";

import React from 'react';

import CheckForm from "../../forms/claim-handling-forms/check-form";

interface Interface{
    partSource: any;
    requestId: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

interface Interface{
    requestId: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

const CheckScreen: FC<Interface> = (props:any) => {

    

    return (
            
                <CheckForm
                    requestId={props.requestId}
                    partSource={props.partSource}
                />
            
    );
}
export default CheckScreen;
