import useSafeState from '../../utils/safe-state';
import React, {useEffect} from 'react';
import {Form, Row, Col, Input, Button, message, Switch, Select, InputNumber} from 'antd';
import {useAxios} from '../../utils/hooks';
import {AxiosResponse} from "axios";
import {Trans} from "@lingui/macro";
import {i18n} from "@lingui/core";
import {I18nProvider} from "@lingui/react";
import {SelectType} from "#/interfaces/SelectType";
import ItemModel from "../../models/dto/item-model";
import {useKeycloak} from "@react-keycloak/web";


interface Interface {
    data?: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}


const SettingsForm = (props: Interface) => {

    const [loading, setLoading] = useSafeState(false);
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");
    const axiosInstanceBackend = useAxios(process.env.REACT_APP_API_BASE_URL + "")
    const [categoryOptions, setCategoryOptions] = useSafeState<any>();
    const [keyOptions, setKeyOptions] = useSafeState<any>();
    const config = {headers: {'Content-Type': 'application/json'}};
    const [active, setActive] = useSafeState(true);
    const [autoCompleteOptions, setAutoCompleteOptions] = useSafeState<any>();

    const data = props.data || {};
    const [form] = Form.useForm();
    const {initialized, keycloak} = useKeycloak();
    const [supplierCode, setSupplierCode] = useSafeState();
    const [isSupplier, setIsSupplier] = useSafeState(false);

    let isEditMode = false;

    useEffect(() => getFormData(), []);
    

    useEffect(() => {

        form.resetFields();

        if (props.mode === "Edit") {

            let fieldsValues = [];
           
            for (const [key, value] of Object.entries(data)) {
               
                
                fieldsValues.push({name: key, value: value});
            }
            form.setFields(fieldsValues);

            isEditMode = true;
            
        } else if (props.mode === "Add new") {
            isEditMode = false;
        }


    }, [])

    useEffect(() => detectSupplier(), []);

    const onReset = () => {
        form.resetFields();
    };

    const antdContext: any = (text: string) => {
        return <I18nProvider i18n={i18n}>
            <Trans>{text}</Trans>
        </I18nProvider>;
    }

    const openNotification = (status: any) => {
        if (status === "error") {
            message.error(antdContext('Hiba mentés közben')).then(r => {
                console.log(r)
            });
        } else if (status === "success") {
            message.success(antdContext('Sikeres mentés'));
        } else {
            message.info(antdContext('Ismeretlen hiba')).then(r => {
                console.log(r)
            })
        }
        if (props.onClose) {
            props.onClose();
        }
    };

    const handleEdit = () => {

        if (axiosInstance.current != null) {
            const obj = {
                id: form.getFieldValue(["id"]),
                category: form.getFieldValue(["category"]),
                key: form.getFieldValue(["key"]),
                value: form.getFieldValue(["value"]),
                supplier: {
                    code: form.getFieldValue(["supplier"]),
                },
                version: form.getFieldValue(["version"])
            };
            setLoading(true);
            axiosInstance.current.put("/resource/settings/id?id=" + form.getFieldValue(["id"]), obj,
                config).then(() => {
                openNotification("success");
                setLoading(false);

            }).catch(function (error: any) {
                openNotification("error");
                console.log(error)
                setLoading(false);
            });
        }
    }

    const handleAddNew = () => {

        if (axiosInstance.current != null) {
            setLoading(true);
            axiosInstance.current.post("/resource/settings", {
                    category: form.getFieldValue(["category"]),
                    key: form.getFieldValue(["key"]),
                    value: form.getFieldValue(["value"]),
                    supplier: {
                        code: form.getFieldValue(["supplier"]),
                    }
                },
                config)
                .then(function (response: AxiosResponse<any>) {
                    openNotification("success");
                    setLoading(false);

                }).catch(function (error: any) {
                openNotification("error");
                console.log(error)
                setLoading(false);
            });
        }
    }

    const getFormData = () => {

        if (!axiosInstanceBackend.current) {
            return;
        }

        axiosInstanceBackend.current.get('/resource/settings/categories')
            .then(function (response: AxiosResponse<any>) {
                let res: Array<any> = [];

                Object.entries(response.data).forEach(([key, value]) => {
                    res.push(
                        {
                            label: key,
                            value: value,
                        }
                    )
                });
                setCategoryOptions(res);
            })
            .catch(function (error: any) {
                console.log(error);//TODO::EndIt
            });

        axiosInstanceBackend.current.get('/resource/settings/keys')
            .then(function (response: AxiosResponse<any>) {
                let res: Array<any> = [];

                Object.entries(response.data).forEach(([key, value]) => {
                    res.push(
                        {
                            label: key,
                            value: value,
                        }
                    )
                });
                setKeyOptions(res);
            })
            .catch(function (error: any) {
                console.log(error);//TODO::EndIt
            });

            axiosInstanceBackend.current.get('/resource/suppliers')
                .then(function (response: AxiosResponse<any>) {
                    if (Array.isArray(response.data)) {
                        let supplierArray: Array<SelectType> = [];
                        response.data.forEach(value => {

                            const label = value.code;

                            supplierArray.push(
                                {
                                    label: label,
                                    value: label,
                                }
                            )
                        });
                        setAutoCompleteOptions(supplierArray);

                    }

                })
                .catch(function (error: any) {
                    console.log(error);//TODO::EndIt
                });
    };

    const onChange = () => {
        setActive(!active);
    }

    const detectSupplier = () => {
        let idTokenParsed = JSON.parse(JSON.stringify(keycloak.tokenParsed));
        let suppCode = idTokenParsed.supplierCode;
        let isSupp = idTokenParsed.supplierCode == undefined ? false : true;
        setSupplierCode(suppCode);
        setIsSupplier(isSupp);
        if (form.getFieldValue("supplier") == undefined){
            form.setFieldsValue({"supplier":suppCode});
        } 
    }

    return (
        <Form id={props.mode} form={form} layout="vertical"
              onFinish={() => props.mode === "Edit" ? handleEdit() : handleAddNew()}>
            <>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="category"
                            label={<Trans>Category</Trans>}
                            children={
                                <Select
                                    notFoundContent={<Trans id={'No data'}/>}
                                    showSearch={true}
                                    style={{width: '100%'}}
                                    placeholder={<Trans id={'Select category'}></Trans>}
                                    options={categoryOptions}
                                />
                            }
                            rules={[{
                                type: "string",
                                required: true,
                                message: <Trans>Please choose a category</Trans>
                            }]}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="key"
                            label={<Trans>Key</Trans>}
                            children={
                                <Select
                                    notFoundContent={<Trans id={'No data'}/>}
                                    showSearch={true}
                                    style={{width: '100%'}}
                                    placeholder={<Trans id={'Select key'}></Trans>}
                                    options={keyOptions}
                                />
                            }
                            rules={[{
                                type: "string",
                                required: true,
                                message: <Trans>Please choose a key</Trans>
                            }]}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="value"
                            label={<Trans>Value</Trans>}
                            children={<Input disabled={false}/>}
                            rules={[{
                                type: "string",
                                required: true,
                                message: <Trans>Please type a value</Trans>
                            }]}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="supplier"
                            label={<Trans>Supplier</Trans>}
                            children={
                                <Select
                                    disabled = {isSupplier}
                                    notFoundContent={<Trans id={'No data'}/>}
                                    showSearch={true}
                                    style={{width: '100%'}}
                                    placeholder={<Trans id={'Select supplier'}></Trans>}
                                    options={autoCompleteOptions}
                                />
                            }
                            rules={[{
                                type: "string",
                                required: isSupplier,
                                message: <Trans>Please type an supplier code</Trans>
                            }]}
                        />
                    </Col>
                </Row>
            </>
            {props.mode === "Edit" ?
                <Button loading={loading} htmlType="button" type="primary" onClick={() => form.submit()}>
                    <Trans>Mentés</Trans>
                </Button>
                :
                <>
                    <Button htmlType="button" style={{marginRight: 8}} onClick={() => onReset()}>
                        <Trans>Vissza</Trans>
                    </Button>
                    <Button loading={loading} htmlType="button" type="primary" style={{marginRight: 8}}
                            onClick={() => form.submit()}>
                        <Trans>Save</Trans>
                    </Button>
                </>

            }
        </Form>
    );

}
export default SettingsForm;
