import useSafeState from '../../utils/safe-state';
import React, {useState, useEffect} from "react";
import {Form, Row, Col, Button, message, Input} from 'antd';
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import {AxiosResponse } from 'axios';
import {antdContext} from "../../utils/antdContext";
import ClaimListScreen from '../../screens/ppap/claim-list';


interface Interface{
    requestId: any;
    mode?: "View" | "Add new";
    
}

const PPAPDemandForm = (props : Interface) => {
   
    const { TextArea } = Input;
    const [loading, setLoading] = useSafeState(false);
    const [isView, setIsView] = useSafeState<boolean>();

    const [form] = Form.useForm();
    
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");
    const [result, setResult] = useSafeState<boolean>();
    const [back, setBack] = useSafeState<boolean>(false);

    const viewMode = () => {
        if(axiosInstance.current != null) {
            
            const resp = axiosInstance.current.get("/resource/request-data/get-request-data?requestId=" + props.requestId, {
                    
                }
                ).then(function (response : AxiosResponse<any>)
                {
                   
                    let dataArray:any[] = JSON.parse(JSON.stringify(response.data));
                    
                    dataArray.forEach(value => {
                        switch (value.name) {
                            case 'demand':
                                form.setFieldsValue({"demand":value.value});
                            break;
                            default:
                            break;    
                        }
                        
                    });

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }
    }

    const handleFinish = () => {
        
        const obj = {    
            demand:form.getFieldValue(["demand"])
        };

        if(axiosInstance.current != null) {
            setLoading(true);

            axiosInstance.current.post("/resource/requests-to-actions/newRequestToAction?requestId=" + props.requestId, {
                reqData: obj,
                }
                ).then(() => {
                    setResult(true);

            }).catch(function (error :any) {
                message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });
        }

    }

    useEffect(() => {

        setIsView(props.mode == 'View' ? true : false);
        
    }, []);

    useEffect(() => {
        if (isView) {
            viewMode();
        }
    },[isView]

    );
    
    return(
        result == undefined ? (
            back == true ? (
                <ClaimListScreen/>
            ) : (
                <Form id="ppapdemand"  layout='vertical' form={form}  onFinish={() => handleFinish()}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <h2><Trans>PPAP Demand</Trans></h2>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={6}>
                            <Form.Item
                                name="demand"
                                label={<Trans>PPAP Demand detect</Trans>}
                                children={<TextArea disabled = {isView} rows={4}/>}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the demand</Trans>
                                }]}
                            />
                        </Col>    
                        
                    </Row>
                        
                    {
                    isView ? (
                        <Form.Item
                            children={
                                <Button htmlType="button" type="primary" onClick={() => setBack(true)}>
                                    <Trans>Back</Trans>
                                </Button>
                            }
                    />
                    ):(
                    <Form.Item
                        children={
                            <Button htmlType="button" type="primary" onClick={() => form.submit()}>
                                <Trans>Save</Trans>
                            </Button>
                        }
                    />)
                }

                </Form> 
                )
              ) : (
                <ClaimListScreen/>
                )
                
    );

}
export default PPAPDemandForm;
