import React, {useState} from "react";
import {SelectType} from "../interfaces/SelectType";
import {Trans} from "@lingui/macro";
import useSafeState from "./safe-state";

type optionalType = {
    key?: number;
    label: any;
    value: string;
};

export enum  responsibleOrganisations {'org 1' , 'org 2' , 'org 3'}
export enum  spareIdentifier {'spare 1' , 'spare 2' , 'spare 3'}
export enum  inventoryControl {'control 1' , 'control 2' , 'control 3'}
export enum  handlingFaultyParts {'faulty 1' , 'faulty 2' , 'faulty 3'}


function getEnumArray(name: string) {
    let enumArray: Array<SelectType> = [];
    let enumKeys: any[];

    switch (name) {
        case 'responsibleOrganisations':
            enumKeys = Object.keys(responsibleOrganisations).filter((v) => isNaN(Number(v)));
            break;
        case 'claimType':
            return [
                { label: <Trans>QUALITY</Trans>, value: 'QUALITY' },
                { label: <Trans>LOGISTIC</Trans>, value: 'LOGISTIC' },
                { label: <Trans>INTERNAL</Trans>, value: 'INTERNAL' },
            ];
        case 'importanceRating':
            return [
                { label: <Trans>GENERAL</Trans>, value: 'GENERAL' },
                { label: <Trans>FUNCTIONALITY</Trans>, value: 'FUNCTIONALITY' },
                { label: <Trans>SECURITY</Trans>, value: 'SECURITY' },
            ]
        case 'spareIdentifier':
                enumKeys = Object.keys(spareIdentifier).filter((v) => isNaN(Number(v)));
            break;
        case 'demandedImmediateAction':
            return [
                { label: <Trans>FULL_STOCK_CHECK</Trans>, value: 'FULL_STOCK_CHECK' },
                { label: <Trans>STOCK_REPLACE_WITHOUT_CHECK</Trans>, value: 'STOCK_REPLACE_WITHOUT_CHECK' },
                { label: <Trans>WRONG_STOCK_REPAIR</Trans>, value: 'WRONG_STOCK_REPAIR' },
                { label: <Trans>OTHER</Trans>, value: 'OTHER' },
            ];
        case 'inventorycontrol':
                enumKeys = Object.keys(inventoryControl).filter((v) => isNaN(Number(v)));
            break;  
        case 'handlingfaultyparts':
            return [
                { label: <Trans>PLEASE_DISPOSE_OF_DEFECTIVE_PARTS</Trans>, value: 'PLEASE_DISPOSE_OF_DEFECTIVE_PARTS' },
                { label: <Trans>PLEASE_RETURN_THE_DEFECTIVE_PARTS</Trans>, value: 'PLEASE_RETURN_THE_DEFECTIVE_PARTS' },
                { label: <Trans>PLEASE_SEND_THE_FAULTY_SAMPLE</Trans>, value: 'PLEASE_SEND_THE_FAULTY_SAMPLE' },
                { label: <Trans>PLEASE_REPAIR_THE_FAULTY_PARTS</Trans>, value: 'PLEASE_REPAIR_THE_FAULTY_PARTS' },
            ];
            break;  
        default:
            enumKeys = [];
        break;


    }
    enumKeys.forEach((key) => {
        enumArray.push(
        {
            label: key,
            value: key
        })
    })

    return enumArray;
}



export default getEnumArray;


