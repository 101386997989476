import useSafeState from '../../utils/safe-state';
import React, {useEffect, useRef} from "react";
import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Switch,
    message,
    Select,
    AutoComplete,
    Radio,
    Space,
    DatePickerProps,
    DatePicker,
    Card
} from 'antd';
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import { AxiosResponse } from 'axios';
import getEnumArray from "../../utils/enums";
import {antdContext} from "../../utils/antdContext";
import ControlAcceptorToErrorReasonScreen from '../../screens/claim-handling/control-acceptor-to-error-reason';
import ClaimListScreen from '../../screens/claim-handling/claim-list';
import type { InputRef } from 'antd';
import moment from 'moment';

interface Interface{
    partSource: any;
    requestId: any;
    mode?: "View" | "AddNew";
}

const ErrorReasonForm = (props : Interface) => {

    const [inventoryControlOptions,setinventoryControlOptions] = useSafeState<any>();
    const [handlingFaultyPartsOptions,sethandlingFaultyPartsOptions] = useSafeState<any>();
    const [loading, setLoading] = useSafeState(false);
    const [result, setResult] = useSafeState<boolean>();
    const [isView, setIsView] = useSafeState<boolean>();
    const [back, setBack] = useSafeState<boolean>(false);

    const [isEmptyGoingOutAction2, setIsEmptyGoingOutAction2] = useSafeState<boolean>(true);
    const [isEmptyGoingOutAction3, setIsEmptyGoingOutAction3] = useSafeState<boolean>(true);
    const [isEmptyGoingOutAction4, setIsEmptyGoingOutAction4] = useSafeState<boolean>(true);
    const [isEmptyGoingOutAction5, setIsEmptyGoingOutAction5] = useSafeState<boolean>(true);
    const [isEmptyGoingOutAction6, setIsEmptyGoingOutAction6] = useSafeState<boolean>(true);
    const [isEmptyGoingOutAction7, setIsEmptyGoingOutAction7] = useSafeState<boolean>(true);

    const [isEmptyOccurenceErrorAction2, setIsEmptyOccurenceErrorAction2] = useSafeState<boolean>(true);
    const [isEmptyOccurenceErrorAction3, setIsEmptyOccurenceErrorAction3] = useSafeState<boolean>(true);
    const [isEmptyOccurenceErrorAction4, setIsEmptyOccurenceErrorAction4] = useSafeState<boolean>(true);
    const [isEmptyOccurenceErrorAction5, setIsEmptyOccurenceErrorAction5] = useSafeState<boolean>(true);
    const [isEmptyOccurenceErrorAction6, setIsEmptyOccurenceErrorAction6] = useSafeState<boolean>(true);
    const [isEmptyOccurenceErrorAction7, setIsEmptyOccurenceErrorAction7] = useSafeState<boolean>(true);

    const goingOutAction2Change = (value: any) => { setIsEmptyGoingOutAction2(!(value.target.value && value.target.value.length > 0)); }
    const goingOutAction3Change = (value: any) => { setIsEmptyGoingOutAction3(!(value.target.value && value.target.value.length > 0)); }
    const goingOutAction4Change = (value: any) => { setIsEmptyGoingOutAction4(!(value.target.value && value.target.value.length > 0)); }
    const goingOutAction5Change = (value: any) => { setIsEmptyGoingOutAction5(!(value.target.value && value.target.value.length > 0)); }
    const goingOutAction6Change = (value: any) => { setIsEmptyGoingOutAction6(!(value.target.value && value.target.value.length > 0)); }
    const goingOutAction7Change = (value: any) => { setIsEmptyGoingOutAction7(!(value.target.value && value.target.value.length > 0)); }

    const occurenceErrorAction2Change = (value: any) => { setIsEmptyOccurenceErrorAction2(!(value.target.value && value.target.value.length > 0)); }
    const occurenceErrorAction3Change = (value: any) => { setIsEmptyOccurenceErrorAction3(!(value.target.value && value.target.value.length > 0)); }
    const occurenceErrorAction4Change = (value: any) => { setIsEmptyOccurenceErrorAction4(!(value.target.value && value.target.value.length > 0)); }
    const occurenceErrorAction5Change = (value: any) => { setIsEmptyOccurenceErrorAction5(!(value.target.value && value.target.value.length > 0)); }
    const occurenceErrorAction6Change = (value: any) => { setIsEmptyOccurenceErrorAction6(!(value.target.value && value.target.value.length > 0)); }
    const occurenceErrorAction7Change = (value: any) => { setIsEmptyOccurenceErrorAction7(!(value.target.value && value.target.value.length > 0)); }

    const [form] = Form.useForm();
    
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");


    const errorGenerateMainReasonDeadlineDatepickerOnChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };

    const errorOutputMainReasonDeadlineDatepickerOnChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };


    useEffect(() => {
        
        setinventoryControlOptions(getEnumArray('inventorycontrol'));
        sethandlingFaultyPartsOptions(getEnumArray('handlingfaultyparts'));
        setIsView(props.mode == 'View' ? true : false);

    }, []);

    useEffect(() => {
        if (isView) {
            viewMode();
        }
    },[isView]

    );    

    const viewMode = () => {
        if(axiosInstance.current != null) {
            
            const resp = axiosInstance.current.get("/resource/request-data/get-request-data?requestId=" + props.requestId, {
                    
                }
                ).then(function (response : AxiosResponse<any>)
                {
                   
                    let dataArray:any[] = JSON.parse(JSON.stringify(response.data));
                    
                    dataArray.forEach(value => {
                        switch (value.name) {


                            case 'fiveWhyAboutCreation1':
                                form.setFieldsValue({"fiveWhyAboutCreation1":value.value});
                                break;
                            case 'fiveWhyAboutCreation2':
                                form.setFieldsValue({"fiveWhyAboutCreation2":value.value});
                                break;
                            case 'fiveWhyAboutCreation3':
                                form.setFieldsValue({"fiveWhyAboutCreation3":value.value});
                                break;
                            case 'fiveWhyAboutCreation4':
                                form.setFieldsValue({"fiveWhyAboutCreation4":value.value});
                                break;
                            case 'fiveWhyAboutCreation5':
                                form.setFieldsValue({"fiveWhyAboutCreation5":value.value});
                                break;
                            case 'rootCauseOfTheError':
                                form.setFieldsValue({"rootCauseOfTheError":value.value});
                                break;


                            case 'fiveWhyAboutGotOut1':
                                form.setFieldsValue({"fiveWhyAboutGotOut1":value.value});
                                break;
                            case 'fiveWhyAboutGotOut2':
                                form.setFieldsValue({"fiveWhyAboutGotOut2":value.value});
                                break;
                            case 'fiveWhyAboutGotOut3':
                                form.setFieldsValue({"fiveWhyAboutGotOut3":value.value});
                                break;
                            case 'fiveWhyAboutGotOut4':
                                form.setFieldsValue({"fiveWhyAboutGotOut4":value.value});
                                break;
                            case 'fiveWhyAboutGotOut5':
                                form.setFieldsValue({"fiveWhyAboutGotOut5":value.value});
                                break;
                            case 'rootCauseOfTheExit':
                                form.setFieldsValue({"rootCauseOfTheExit":value.value});
                                break;


                            case 'occurenceErrorAction1':
                                form.setFieldsValue({"occurenceErrorAction1":value.value});
                                break;
                            case 'occurenceErrorResponsible1':
                                form.setFieldsValue({"occurenceErrorResponsible1":value.value});
                                break;
                            case 'occurenceErrorDeadline1':
                                form.setFieldsValue({"occurenceErrorDeadline1":value.value});
                                break;
                            case 'occurenceErrorAction2':
                                form.setFieldsValue({"occurenceErrorAction2":value.value});
                                break;
                            case 'occurenceErrorResponsible2':
                                form.setFieldsValue({"occurenceErrorResponsible2":value.value});
                                break;
                            case 'occurenceErrorDeadline2':
                                form.setFieldsValue({"occurenceErrorDeadline2":value.value});
                                break;
                            case 'occurenceErrorAction3':
                                form.setFieldsValue({"occurenceErrorAction3":value.value});
                                break;
                            case 'occurenceErrorResponsible3':
                                form.setFieldsValue({"occurenceErrorResponsible3":value.value});
                                break;
                            case 'occurenceErrorDeadline3':
                                form.setFieldsValue({"occurenceErrorDeadline3":value.value});
                                break;
                            case 'occurenceErrorAction4':
                                form.setFieldsValue({"occurenceErrorAction4":value.value});
                                break;
                            case 'occurenceErrorResponsible4':
                                form.setFieldsValue({"occurenceErrorResponsible4":value.value});
                                break;
                            case 'occurenceErrorDeadline4':
                                form.setFieldsValue({"occurenceErrorDeadline4":value.value});
                                break;
                            case 'occurenceErrorAction5':
                                form.setFieldsValue({"occurenceErrorAction5":value.value});
                                break;
                            case 'occurenceErrorResponsible5':
                                form.setFieldsValue({"occurenceErrorResponsible5":value.value});
                                break;
                            case 'occurenceErrorDeadline5':
                                form.setFieldsValue({"occurenceErrorDeadline5":value.value});
                                break;
                            case 'occurenceErrorAction6':
                                form.setFieldsValue({"occurenceErrorAction6":value.value});
                                break;
                            case 'occurenceErrorResponsible6':
                                form.setFieldsValue({"occurenceErrorResponsible6":value.value});
                                break;
                            case 'occurenceErrorDeadline6':
                                form.setFieldsValue({"occurenceErrorDeadline6":value.value});
                                break;
                            case 'occurenceErrorAction7':
                                form.setFieldsValue({"occurenceErrorAction7":value.value});
                                break;
                            case 'occurenceErrorResponsible7':
                                form.setFieldsValue({"occurenceErrorResponsible7":value.value});
                                break;
                            case 'occurenceErrorDeadline7':
                                form.setFieldsValue({"occurenceErrorDeadline7":value.value});
                                break;


                            case 'goingOutAction1':
                                form.setFieldsValue({"goingOutAction1":value.value});
                                break;
                            case 'goingOutResponsible1':
                                form.setFieldsValue({"goingOutResponsible1":value.value});
                                break;
                            case 'goingOutDeadline1':
                                form.setFieldsValue({"goingOutDeadline1":value.value});
                                break;
                            case 'goingOutAction2':
                                form.setFieldsValue({"goingOutAction2":value.value});
                                break;
                            case 'goingOutResponsible2':
                                form.setFieldsValue({"goingOutResponsible2":value.value});
                                break;
                            case 'goingOutDeadline2':
                                form.setFieldsValue({"goingOutDeadline2":value.value});
                                break;
                            case 'goingOutAction3':
                                form.setFieldsValue({"goingOutAction3":value.value});
                                break;
                            case 'goingOutResponsible3':
                                form.setFieldsValue({"goingOutResponsible3":value.value});
                                break;
                            case 'goingOutDeadline3':
                                form.setFieldsValue({"goingOutDeadline3":value.value});
                                break;
                            case 'goingOutAction4':
                                form.setFieldsValue({"goingOutAction4":value.value});
                                break;
                            case 'goingOutResponsible4':
                                form.setFieldsValue({"goingOutResponsible4":value.value});
                                break;
                            case 'goingOutDeadline4':
                                form.setFieldsValue({"goingOutDeadline4":value.value});
                                break;
                            case 'goingOutAction5':
                                form.setFieldsValue({"goingOutAction5":value.value});
                                break;
                            case 'goingOutResponsible5':
                                form.setFieldsValue({"goingOutResponsible5":value.value});
                                break;
                            case 'goingOutDeadline5':
                                form.setFieldsValue({"goingOutDeadline5":value.value});
                                break;
                            case 'goingOutAction6':
                                form.setFieldsValue({"goingOutAction6":value.value});
                                break;
                            case 'goingOutResponsible6':
                                form.setFieldsValue({"goingOutResponsible6":value.value});
                                break;
                            case 'goingOutDeadline6':
                                form.setFieldsValue({"goingOutDeadline6":value.value});
                                break;
                            case 'goingOutAction7':
                                form.setFieldsValue({"goingOutAction7":value.value});
                                break;
                            case 'goingOutResponsible7':
                                form.setFieldsValue({"goingOutResponsible7":value.value});
                                break;
                            case 'goingOutDeadline7':
                                form.setFieldsValue({"goingOutDeadline7":value.value});
                                break;
                                
                            default:
                            break;    
                        }
                        
                    });

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }
    }


    const handleFinish = () => {
        const obj = {
            fiveWhyAnalisysOfErrorCreation: {
                why1: form.getFieldValue(["fiveWhyAboutCreation1"]),
                why2: form.getFieldValue(["fiveWhyAboutCreation2"]),
                why3: form.getFieldValue(["fiveWhyAboutCreation3"]),
                why4: form.getFieldValue(["fiveWhyAboutCreation4"]),
                why5: form.getFieldValue(["fiveWhyAboutCreation5"]),
                rootCauseOfErrorCreation: form.getFieldValue(["rootCauseOfTheError"])
            },
            fiveWhyAnalisysOfGoingOut: {
                why1: form.getFieldValue(["fiveWhyAboutGotOut1"]),
                why2: form.getFieldValue(["fiveWhyAboutGotOut2"]),
                why3: form.getFieldValue(["fiveWhyAboutGotOut3"]),
                why4: form.getFieldValue(["fiveWhyAboutGotOut4"]),
                why5: form.getFieldValue(["fiveWhyAboutGotOut5"]),
                rootCauseOfGoingOut: form.getFieldValue(["rootCauseOfTheExit"])
            },
            actionsToTerminateCreationOfError: {
                action1: form.getFieldValue(["occurenceErrorAction1"]),
                responsible1: form.getFieldValue(["occurenceErrorResponsible1"]),
                deadline1: moment(form.getFieldValue(["occurenceErrorDeadline1"])).format('YYYY-MM-DD'),

                action2: form.getFieldValue(["occurenceErrorAction2"]),
                responsible2: form.getFieldValue(["occurenceErrorResponsible2"]),
                deadline2: moment(form.getFieldValue(["occurenceErrorDeadline2"])).format('YYYY-MM-DD'),

                action3: form.getFieldValue(["occurenceErrorAction3"]),
                responsible3: form.getFieldValue(["occurenceErrorResponsible3"]),
                deadline3: moment(form.getFieldValue(["occurenceErrorDeadline3"])).format('YYYY-MM-DD'),

                action4: form.getFieldValue(["occurenceErrorAction4"]),
                responsible4: form.getFieldValue(["occurenceErrorResponsible4"]),
                deadline4: moment(form.getFieldValue(["occurenceErrorDeadline4"])).format('YYYY-MM-DD'),

                action5: form.getFieldValue(["occurenceErrorAction5"]),
                responsible5: form.getFieldValue(["occurenceErrorResponsible5"]),
                deadline5: moment(form.getFieldValue(["occurenceErrorDeadline5"])).format('YYYY-MM-DD'),

                action6: form.getFieldValue(["occurenceErrorAction6"]),
                responsible6: form.getFieldValue(["occurenceErrorResponsible6"]),
                deadline6: moment(form.getFieldValue(["occurenceErrorDeadline6"])).format('YYYY-MM-DD'),

                action7: form.getFieldValue(["occurenceErrorAction7"]),
                responsible7: form.getFieldValue(["occurenceErrorResponsible7"]),
                deadline7: moment(form.getFieldValue(["occurenceErrorDeadline7"])).format('YYYY-MM-DD'),
            },
            actionsToTerminateGoingOutOfError: {
                action1: form.getFieldValue(["goingOutAction1"]),
                responsible1: form.getFieldValue(["goingOutResponsible1"]),
                deadline1: moment(form.getFieldValue(["goingOutDeadline1"])).format('YYYY-MM-DD'),

                action2: form.getFieldValue(["goingOutAction2"]),
                responsible2: form.getFieldValue(["goingOutResponsible2"]),
                deadline2: moment(form.getFieldValue(["goingOutDeadline2"])).format('YYYY-MM-DD'),

                action3: form.getFieldValue(["goingOutAction3"]),
                responsible3: form.getFieldValue(["goingOutResponsible3"]),
                deadline3: moment(form.getFieldValue(["goingOutDeadline3"])).format('YYYY-MM-DD'),

                action4: form.getFieldValue(["goingOutAction4"]),
                responsible4: form.getFieldValue(["goingOutResponsible4"]),
                deadline4: moment(form.getFieldValue(["goingOutDeadline4"])).format('YYYY-MM-DD'),

                action5: form.getFieldValue(["goingOutAction5"]),
                responsible5: form.getFieldValue(["goingOutResponsible5"]),
                deadline5: moment(form.getFieldValue(["goingOutDeadline5"])).format('YYYY-MM-DD'),

                action6: form.getFieldValue(["goingOutAction6"]),
                responsible6: form.getFieldValue(["goingOutResponsible6"]),
                deadline6: moment(form.getFieldValue(["goingOutDeadline6"])).format('YYYY-MM-DD'),

                action7: form.getFieldValue(["goingOutAction7"]),
                responsible7: form.getFieldValue(["goingOutResponsible7"]),
                deadline7: moment(form.getFieldValue(["goingOutDeadline7"])).format('YYYY-MM-DD'),
            }
        };


        if(axiosInstance.current != null) {
            setLoading(true);
            axiosInstance.current.post("/resource/requests/complete-by-name-and-action-type?requestId=" + props.requestId + "&stateName=" + "Error reason data" + "&actionType=APPROVE",
                    obj
            ).then(() => {

                setResult(false);

            }).catch(function (error :any) {
                message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });
        }

    }


    const inputRef = useRef<InputRef>(null);

    const sharedProps = {
        ref: inputRef,
    };

    return(
        result == undefined ? (
            back == true ? (
                <ClaimListScreen/>
            ) : (
        <Form id="errorReason"  layout='vertical' form={form}  onFinish={() => handleFinish()}>
            <Row gutter={24}>
                <Card style={{ width: 400, margin: 10 }} title={<Trans>5 why analysis about creation</Trans>}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="fiveWhyAboutCreation1"
                                label={<Trans>Why?</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="fiveWhyAboutCreation2"
                                label={<Trans>Why?</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="fiveWhyAboutCreation3"
                                label={<Trans>Why?</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="fiveWhyAboutCreation4"
                                label={<Trans>Why?</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="fiveWhyAboutCreation5"
                                label={<Trans>Why?</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="rootCauseOfTheError"
                                label={<Trans>Root cause of the error</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                    </Row>
                </Card>
                <Card style={{ width: 400, margin: 10 }} title={<Trans>5 why analysis about got out</Trans>}>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="fiveWhyAboutGotOut1"
                                label={<Trans>Why?</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="fiveWhyAboutGotOut2"
                                label={<Trans>Why?</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="fiveWhyAboutGotOut3"
                                label={<Trans>Why?</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="fiveWhyAboutGotOut4"
                                label={<Trans>Why?</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="fiveWhyAboutGotOut5"
                                label={<Trans>Why?</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={24}>
                            <Form.Item
                                name="rootCauseOfTheExit"
                                label={<Trans>Root cause of the exit</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                    </Row>
                </Card>
                <Card style={{ width: 700, margin: 10 }} title={<Trans>Actions to avoid the occurrence of the error</Trans>}>
                    <Row gutter={24}>
                        <Col style={{paddingTop: 35}}><span>1.</span></Col>
                        <Col span={8}>
                            <Form.Item
                                name="occurenceErrorAction1"
                                label={<Trans>Action</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="occurenceErrorResponsible1"
                                label={<Trans>Responsible</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="occurenceErrorDeadline1"
                                label={<Trans>Deadline</Trans>}
                                children={<DatePicker placeholder='Dátum' disabled={isView} onChange={errorGenerateMainReasonDeadlineDatepickerOnChange}
                                                      format={'YYYY.MM.DD'}/>}
                                rules={[{
                                    type:"date",
                                    required: true,
                                    message: <Trans>Please enter a main reason action deadline</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col style={{paddingTop: 35}}><span>2.</span></Col>
                        <Col span={8}>
                            <Form.Item
                                name="occurenceErrorAction2"
                                label={<Trans>Action</Trans>}
                                children={<Input {...sharedProps} disabled={isView} onChange={value => occurenceErrorAction2Change(value)} />}
                                rules={[{
                                    type:"string",
                                    required: false,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="occurenceErrorResponsible2"
                                label={<Trans>Responsible</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: !isEmptyOccurenceErrorAction2,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="occurenceErrorDeadline2"
                                label={<Trans>Deadline</Trans>}
                                children={<DatePicker placeholder='Dátum' disabled={isView} onChange={errorGenerateMainReasonDeadlineDatepickerOnChange}
                                                      format={'YYYY.MM.DD'}/>}
                                rules={[{
                                    type:"date",
                                    required: !isEmptyOccurenceErrorAction2,
                                    message: <Trans>Please enter a main reason action deadline</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col style={{paddingTop: 35}}><span>3.</span></Col>
                        <Col span={8}>
                            <Form.Item
                                name="occurenceErrorAction3"
                                label={<Trans>Action</Trans>}
                                children={<Input {...sharedProps} disabled={isView} onChange={value => occurenceErrorAction3Change(value)} />}
                                rules={[{
                                    type:"string",
                                    required: false,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="occurenceErrorResponsible3"
                                label={<Trans>Responsible</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: !isEmptyOccurenceErrorAction3,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="occurenceErrorDeadline3"
                                label={<Trans>Deadline</Trans>}
                                children={<DatePicker placeholder='Dátum' disabled={isView} onChange={errorGenerateMainReasonDeadlineDatepickerOnChange}
                                                      format={'YYYY.MM.DD'}/>}
                                rules={[{
                                    type:"date",
                                    required: !isEmptyOccurenceErrorAction3,
                                    message: <Trans>Please enter a main reason action deadline</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col style={{paddingTop: 35}}><span>4.</span></Col>
                        <Col span={8}>
                            <Form.Item
                                name="occurenceErrorAction4"
                                label={<Trans>Action</Trans>}
                                children={<Input {...sharedProps} disabled={isView} onChange={value => occurenceErrorAction4Change(value)} />}
                                rules={[{
                                    type:"string",
                                    required: false,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="occurenceErrorResponsible4"
                                label={<Trans>Responsible</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: !isEmptyOccurenceErrorAction4,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="occurenceErrorDeadline4"
                                label={<Trans>Deadline</Trans>}
                                children={<DatePicker placeholder='Dátum' disabled={isView} onChange={errorGenerateMainReasonDeadlineDatepickerOnChange}
                                                      format={'YYYY.MM.DD'}/>}
                                rules={[{
                                    type:"date",
                                    required: !isEmptyOccurenceErrorAction4,
                                    message: <Trans>Please enter a main reason action deadline</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col style={{paddingTop: 35}}><span>5.</span></Col>
                        <Col span={8}>
                            <Form.Item
                                name="occurenceErrorAction5"
                                label={<Trans>Action</Trans>}
                                children={<Input {...sharedProps} disabled={isView} onChange={value => occurenceErrorAction5Change(value)} />}
                                rules={[{
                                    type:"string",
                                    required: false,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="occurenceErrorResponsible5"
                                label={<Trans>Responsible</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: !isEmptyOccurenceErrorAction5,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="occurenceErrorDeadline5"
                                label={<Trans>Deadline</Trans>}
                                children={<DatePicker placeholder='Dátum' disabled={isView} onChange={errorGenerateMainReasonDeadlineDatepickerOnChange}
                                                      format={'YYYY.MM.DD'}/>}
                                rules={[{
                                    type:"date",
                                    required: !isEmptyOccurenceErrorAction5,
                                    message: <Trans>Please enter a main reason action deadline</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col style={{paddingTop: 35}}><span>6.</span></Col>
                        <Col span={8}>
                            <Form.Item
                                name="occurenceErrorAction6"
                                label={<Trans>Action</Trans>}
                                children={<Input {...sharedProps} disabled={isView} onChange={value => occurenceErrorAction6Change(value)} />}
                                rules={[{
                                    type:"string",
                                    required: false,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="occurenceErrorResponsible6"
                                label={<Trans>Responsible</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: !isEmptyOccurenceErrorAction6,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="occurenceErrorDeadline6"
                                label={<Trans>Deadline</Trans>}
                                children={<DatePicker placeholder='Dátum' disabled={isView} onChange={errorGenerateMainReasonDeadlineDatepickerOnChange}
                                                      format={'YYYY.MM.DD'}/>}
                                rules={[{
                                    type:"date",
                                    required: !isEmptyOccurenceErrorAction6,
                                    message: <Trans>Please enter a main reason action deadline</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col style={{paddingTop: 35}}><span>7.</span></Col>
                        <Col span={8}>
                            <Form.Item
                                name="occurenceErrorAction7"
                                label={<Trans>Action</Trans>}
                                children={<Input {...sharedProps} disabled={isView} onChange={value => occurenceErrorAction7Change(value)} />}
                                rules={[{
                                    type:"string",
                                    required: false,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="occurenceErrorResponsible7"
                                label={<Trans>Responsible</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: !isEmptyOccurenceErrorAction7,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="occurenceErrorDeadline7"
                                label={<Trans>Deadline</Trans>}
                                children={<DatePicker placeholder='Dátum' disabled={isView} onChange={errorGenerateMainReasonDeadlineDatepickerOnChange}
                                                      format={'YYYY.MM.DD'}/>}
                                rules={[{
                                    type:"date",
                                    required: !isEmptyOccurenceErrorAction7,
                                    message: <Trans>Please enter a main reason action deadline</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                </Card>
                <Card style={{ width: 700, margin: 10 }} title={<Trans>Actions to prevent the error going out</Trans>}>
                    <Row gutter={24}>
                        <Col style={{paddingTop: 35}}><span>1.</span></Col>
                        <Col span={8}>
                            <Form.Item
                                name="goingOutAction1"
                                label={<Trans>Action</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="goingOutResponsible1"
                                label={<Trans>Responsible</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: true,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="goingOutDeadline1"
                                label={<Trans>Deadline</Trans>}
                                children={<DatePicker placeholder='Dátum' disabled={isView} onChange={errorGenerateMainReasonDeadlineDatepickerOnChange}
                                                      format={'YYYY.MM.DD'}/>}
                                rules={[{
                                    type:"date",
                                    required: true,
                                    message: <Trans>Please enter a main reason action deadline</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col style={{paddingTop: 35}}><span>2.</span></Col>
                        <Col span={8}>
                            <Form.Item
                                name="goingOutAction2"
                                label={<Trans>Action</Trans>}
                                children={<Input {...sharedProps} disabled={isView} onChange={value => goingOutAction2Change(value)} />}
                                rules={[{
                                    type:"string",
                                    required: false,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="goingOutResponsible2"
                                label={<Trans>Responsible</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: !isEmptyGoingOutAction2,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="goingOutDeadline2"
                                label={<Trans>Deadline</Trans>}
                                children={<DatePicker placeholder='Dátum' disabled={isView} onChange={errorGenerateMainReasonDeadlineDatepickerOnChange}
                                                      format={'YYYY.MM.DD'}/>}
                                rules={[{
                                    type:"date",
                                    required: !isEmptyGoingOutAction2,
                                    message: <Trans>Please enter a main reason action deadline</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col style={{paddingTop: 35}}><span>3.</span></Col>
                        <Col span={8}>
                            <Form.Item
                                name="goingOutAction3"
                                label={<Trans>Action</Trans>}
                                children={<Input {...sharedProps} disabled={isView} onChange={value => goingOutAction3Change(value)} />}
                                rules={[{
                                    type:"string",
                                    required: false,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="goingOutResponsible3"
                                label={<Trans>Responsible</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: !isEmptyGoingOutAction3,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="goingOutDeadline3"
                                label={<Trans>Deadline</Trans>}
                                children={<DatePicker placeholder='Dátum' disabled={isView} onChange={errorGenerateMainReasonDeadlineDatepickerOnChange}
                                                      format={'YYYY.MM.DD'}/>}
                                rules={[{
                                    type:"date",
                                    required: !isEmptyGoingOutAction3,
                                    message: <Trans>Please enter a main reason action deadline</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col style={{paddingTop: 35}}><span>4.</span></Col>
                        <Col span={8}>
                            <Form.Item
                                name="goingOutAction4"
                                label={<Trans>Action</Trans>}
                                children={<Input {...sharedProps} disabled={isView} onChange={value => goingOutAction4Change(value)} />}
                                rules={[{
                                    type:"string",
                                    required: false,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="goingOutResponsible4"
                                label={<Trans>Responsible</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: !isEmptyGoingOutAction4,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="goingOutDeadline4"
                                label={<Trans>Deadline</Trans>}
                                children={<DatePicker placeholder='Dátum' disabled={isView} onChange={errorGenerateMainReasonDeadlineDatepickerOnChange}
                                                      format={'YYYY.MM.DD'}/>}
                                rules={[{
                                    type:"date",
                                    required: !isEmptyGoingOutAction4,
                                    message: <Trans>Please enter a main reason action deadline</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col style={{paddingTop: 35}}><span>5.</span></Col>
                        <Col span={8}>
                            <Form.Item
                                name="goingOutAction5"
                                label={<Trans>Action</Trans>}
                                children={<Input {...sharedProps} disabled={isView} onChange={value => goingOutAction5Change(value)} />}
                                rules={[{
                                    type:"string",
                                    required: false,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="goingOutResponsible5"
                                label={<Trans>Responsible</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: !isEmptyGoingOutAction5,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="goingOutDeadline5"
                                label={<Trans>Deadline</Trans>}
                                children={<DatePicker placeholder='Dátum' disabled={isView} onChange={errorGenerateMainReasonDeadlineDatepickerOnChange}
                                                      format={'YYYY.MM.DD'}/>}
                                rules={[{
                                    type:"date",
                                    required: !isEmptyGoingOutAction5,
                                    message: <Trans>Please enter a main reason action deadline</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col style={{paddingTop: 35}}><span>6.</span></Col>
                        <Col span={8}>
                            <Form.Item
                                name="goingOutAction6"
                                label={<Trans>Action</Trans>}
                                children={<Input {...sharedProps} disabled={isView} onChange={value => goingOutAction6Change(value)} />}
                                rules={[{
                                    type:"string",
                                    required: false,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="goingOutResponsible6"
                                label={<Trans>Responsible</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: !isEmptyGoingOutAction6,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="goingOutDeadline6"
                                label={<Trans>Deadline</Trans>}
                                children={<DatePicker placeholder='Dátum' disabled={isView} onChange={errorGenerateMainReasonDeadlineDatepickerOnChange}
                                                      format={'YYYY.MM.DD'}/>}
                                rules={[{
                                    type:"date",
                                    required: !isEmptyGoingOutAction6,
                                    message: <Trans>Please enter a main reason action deadline</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col style={{paddingTop: 35}}><span>7.</span></Col>
                        <Col span={8}>
                            <Form.Item
                                name="goingOutAction7"
                                label={<Trans>Action</Trans>}
                                children={<Input {...sharedProps} disabled={isView} onChange={value => goingOutAction7Change(value)} />}
                                rules={[{
                                    type:"string",
                                    required: false,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="goingOutResponsible7"
                                label={<Trans>Responsible</Trans>}
                                children={<Input {...sharedProps} disabled={isView} />}
                                rules={[{
                                    type:"string",
                                    required: !isEmptyGoingOutAction7,
                                    message: <Trans>Please enter the 5 why analysis generate</Trans>,
                                }]}
                            />
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="goingOutDeadline7"
                                label={<Trans>Deadline</Trans>}
                                children={<DatePicker placeholder='Dátum' disabled={isView} onChange={errorGenerateMainReasonDeadlineDatepickerOnChange}
                                                      format={'YYYY.MM.DD'}/>}
                                rules={[{
                                    type:"date",
                                    required: !isEmptyGoingOutAction7,
                                    message: <Trans>Please enter a main reason action deadline</Trans>
                                }]}
                            />
                        </Col>
                    </Row>
                </Card>
            </Row>


            <Form.Item
                children={
                    <>
                        <Button htmlType="button" type="primary" onClick={() => setBack(true)} style={{marginRight: 5}}>
                            <Trans>Back</Trans>
                        </Button>
                        <Button htmlType="button" type="primary" onClick={() => form.submit()} disabled={isView}>
                            <Trans>Save</Trans>
                        </Button>
                    </>
                }
            />

                </Form>

            )
                ) : <ClaimListScreen/>
            );    

}
export default ErrorReasonForm;
