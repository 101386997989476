
import {FC} from "react";

import React from 'react';

import PPAPMSCApprovalForm from "../../forms/ppap/ppap-msc-approval-form";

interface Interface{
    requestId: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

interface Interface{
    requestId: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

const PPAPMSCApprovalScreen: FC<Interface> = (props:any) => {

    

    return (
            
                <PPAPMSCApprovalForm
                    requestId={props.requestId}
                />
            
    );
}
export default PPAPMSCApprovalScreen;
