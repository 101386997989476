import React from 'react';
import { message } from 'antd';
import { antdContext } from '../utils/antdContext';

export const openNotification = (status: any, props?: any) => {
    if (status === 'error') {
        message.error(antdContext('Hiba mentés közben')).then(r => {
            console.log(r);
        });
    } else if (status === 'success') {
        message.success(antdContext('Sikeres mentés'));
    } else {
        message.info(antdContext('Ismeretlen hiba')).then(r => {
            console.log(r);
        });
    }
    if (props != null && props.onClose) {
        props.onClose();
    }
};
