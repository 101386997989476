import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const OrderModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'orderNo', width: 150, sorter:true,title:<Trans>OrderNo</Trans>, fixed: "left", direction:"desc", render: text => {return (<b>{text}</b>);} })
    columns.push({key:'supplier', dataIndex:['supplier','code'], width: 110, sorter:false,title:<Trans>Supplier code</Trans>});
    columns.push({key:'poNumber', width: 110, sorter:true,title:<Trans>PoNumber</Trans>});
    columns.push({key:'poItemNumber', width: 130, sorter:true,title:<Trans>PoItemNumber</Trans>});
    columns.push({key:'item',dataIndex:['item','id','itemNo'], width: 140, sorter:true,title:<Trans>ItemNo</Trans>, render: text => {return (<b>{text}</b>);} })

    const qtyColumns: Array<Column> = new Array();
    qtyColumns.push({key:'orderQty', width: 110, sorter:true, title:<Trans>Ordered</Trans>});
    qtyColumns.push({key:'receivedQty', width: 110, sorter:true, title:<Trans>Received</Trans>});

    columns.push({
        key: 'qty',
        width: 157,
        sorter: false,
        filter: false,
        title: <Trans>qty</Trans>,
        children: qtyColumns,
    });

    columns.push({key:'deliveryDate', width: 150, dateFilter:true, sorter:true,title:<Trans>Delivery date</Trans>});
    columns.push({key:'orderStatus',tags:[
        {text:'UPLOADED',color:'blue'},
        {text:'DOWNLOADED',color:'green'},
        {text:'RECEIVED',color:'orange'},
        {text:'RECEIVING_IN_PROGRESS',color:'purple'},
        {text:'CLOSED',color:'red'},
    ],
        uniqueSelectFilterData:[
            {
                label:<Trans>NONE</Trans>,
                value:null
            },{
                label:<Trans>UPLOADED</Trans>,
                value:"UPLOADED"
            },{
                label:<Trans>DOWNLOADED</Trans>,
                value:"DOWNLOADED"
            },{
                label:<Trans>RECEIVED</Trans>,
                value:"RECEIVED"
            },{
                label:<Trans>RECEIVING_IN_PROGRESS</Trans>,
                value:"RECEIVING_IN_PROGRESS"
            },{
                label:<Trans>CLOSED</Trans>,
                value:"CLOSED"
            }
        ],
        width: 110, sorter:true,title:<Trans>Status</Trans>
    });
    columns.push({key:'createdDate', width: 140, dateTimeFilter: true, sorter:true,title:<Trans>Created Date</Trans>})
    columns.push({key:'updatedDate', width: 140, dateTimeFilter: true, sorter:true,title:<Trans>Updated Date</Trans>})
    columns.push({key:'updatedBy', width: 160, sorter:true,title:<Trans>Updated By</Trans>})
    columns.push({key:'sapDbId', width: 150, sorter:true,title:<Trans>SAP Db Id</Trans>,
    render: (text, row) => {
        return { props: { className: 'sapDbId' }, children: <>{text}</> } }
    });

    return{
        columns:columns,
        url:"/resource/orders"
    };

}
export default OrderModel();
