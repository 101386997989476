import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";
import { Tag } from 'antd';

const ClaimPPAPListModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'supplierName', width: 70, filter:true, sorter:true,direction:'asc',title:<Trans>Supplier Name</Trans>});
    columns.push({key:'supplierCode', width: 100, filter:true, sorter:true,title:<Trans>Supplier Code</Trans>});
    columns.push({key:'uploader', width: 100, filter:true, sorter:true,title:<Trans>Uploader</Trans>});
    columns.push({
        key: 'status',
        width: 100,
        uniqueSelectFilterData: [
            {
                label: <Trans>All</Trans>,
                value: null,
            },
            {
                label: <Trans>Status 10</Trans>,
                value: '13',
            },
            {
                label: <Trans>Status 20</Trans>,
                value: '14',
            },
            {
                label: <Trans>Status 30</Trans>,
                value: '15',
            },
            {
                label: <Trans>Status 40</Trans>,
                value: '16',
            },
            {
                label: <Trans>Status 50</Trans>,
                value: '17',
            },
            {
                label: <Trans>Status 60</Trans>,
                value: '18',
            },
            {
                label: <Trans>Status 70</Trans>,
                value: '19',
            },
            {
                label: <Trans>Status 80</Trans>,
                value: '20',
            },
            {
                label: <Trans>Status 90</Trans>,
                value: '21',
            },
            {
                label: <Trans>Status 100</Trans>,
                value: '22',
            },
            {
                label: <Trans>Status 110</Trans>,
                value: '23',
            },
            {
                label: <Trans>Status 120</Trans>,
                value: '24',
            },
            {
                label: <Trans>Status 130</Trans>,
                value: '25',
            },
            {
                label: <Trans>Close</Trans>,
                value: '26',
            },
        ],
        sorter: true,
        title: <Trans>Status</Trans>,
        render: text => {
            let color = 'gray';
            let transText;
            if (text === '13') {
                color = 'gold';
                transText = <Trans>Status 10</Trans>;
            } else if (text === '14') {
                color = 'green';
                transText = <Trans>Status 20</Trans>;
            } else if (text === '15') {
                color = 'red';
                transText = <Trans>Status 30</Trans>;
            } else if (text === '16') {
                color = 'blue';
                transText = <Trans>Status 40</Trans>;
            } else if (text === '17') {
                color = 'yellow';
                transText = <Trans>Status 50</Trans>;
            } else if (text === '18') {
                color = 'lightblue';
                transText = <Trans>Status 60</Trans>;
            } else if (text === '19') {
                color = 'purple';
                transText = <Trans>Status 70</Trans>;
            } else if (text === '20') {
                color = 'coral';
                transText = <Trans>Status 80</Trans>;
            } else if (text === '21') {
                color = 'brown';
                transText = <Trans>Status 90</Trans>;
            } else if (text === '22') {
                color = 'darkgrey';
                transText = <Trans>Status 100</Trans>;
            } else if (text === '23') {
                color = 'black';
                transText = <Trans>Status 110</Trans>;
            } else if (text === '24') {
                color = 'chocolate';
                transText = <Trans>Status 120</Trans>;
            } else if (text === '25') {
                color = 'chartreuse';
                transText = <Trans>Status 130</Trans>;
            } else if (text === '26') {
                color = 'bisque';
                transText = <Trans>Closed</Trans>;
            }
            return (
                <Tag color={color} key={text}>
                    {transText}
                </Tag>
            );
        },
    });
    columns.push({key:'createDate', width: 100, dateTimeFilter:true, sorter:true,title:<Trans>Create date</Trans>});


    return{
        columns:columns,
        url:"/resource/claimppap/get-all-ppap-claims"
    };

}
export default ClaimPPAPListModel();
