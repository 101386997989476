import {Trans} from '@lingui/macro';
import {useKeycloak} from '@react-keycloak/web';
import useSafeState from '../../utils/safe-state';
import {AxiosResponse} from 'axios';
import React, {ReactNode, useCallback, useState} from 'react';
import {Alert, Avatar, Button, Card, Col, Divider, List, Row, Skeleton, Space, Statistic, Layout} from 'antd';
import {useAxios} from '../../utils/hooks';
import InfiniteScroll from "react-infinite-scroll-component";
import {Link} from "react-router-dom";
import {Message} from "../../interfaces/Message";
import {DownloadOutlined} from "@ant-design/icons";
import isAuthorized from "../../utils/is-authorized";
import {MessageDocument} from "#/interfaces/MessageDocument";
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {PrivateRoute} from '../../routes/utils'
import ClaimListScreen from '../../screens/claim-handling/claim-list';


const ClaimHandlingScreen = () => {
    
    const {keycloak} = useKeycloak();
    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "")


    const [loading, setLoading] = useSafeState(false);

    let keycloakToken: any = JSON.parse(JSON.stringify(keycloak.tokenParsed));

    if (isAuthorized(keycloak, ["MTSK_GLOBAL_ADMIN", "MTSK_USER"])) {
        React.useEffect(() => {

        }, []);
    } else {
        React.useEffect(() => {

        }, []);
    }


    return (
        <>
            <Router>
                <Switch>
                    <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]}
                                  exact path="/claim-handling" component={ClaimListScreen}/>
                </Switch>
            </Router>
        </>
    );

}
export default ClaimHandlingScreen;
