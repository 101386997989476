
import {FC} from "react";

import React from 'react';

import AcceptToImplementForm from "../../forms/claim-handling-forms/accept-to-implement-form";

interface Interface{
    partSource: any;
    requestId: any;
    mode?: "View" | "Add new";
    onClose?: () => void;
}

const AcceptToImplementScreen: FC<Interface> = (props:any) => {

    

    return (
            
                <AcceptToImplementForm
                    requestId={props.requestId}
                    partSource={props.partSource}
                    mode={props.mode}
                />
            
    );
}
export default AcceptToImplementScreen;
