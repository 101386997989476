import useSafeState from '../../utils/safe-state';
import React from 'react';
import { Breadcrumb } from 'antd';
import Drawer from '../../components/drawer/drawer';
import { Trans } from '@lingui/macro';
import { EyeOutlined } from '@ant-design/icons/lib';
import DtoTable from '../../components/dto-table/dto-table';
import SapConnectionModel from '../../models/dto/sap-connection';
import SapConnectionForm from '../../forms/sap-connection/sap-connection';

const SapConnectionScreen = () => {
    const text = <Trans>Request Data</Trans>;
    const drawer = Drawer({
        title: text,
        children: <SapConnectionForm />,
        customClose: () => setTrigger(!trigger),
    });

    const [trigger, setTrigger] = useSafeState(false);

    const action = (text: string, record: any) => (
        <div style={{ clear: 'both', whiteSpace: 'nowrap' }}>
            <EyeOutlined
                className={'ActionButtons'}
                style={{ color: '#40a9ff' }}
                onClick={() =>
                    drawer.open('', {
                        requestBody: record.requestBody,
                        requestUrl: record.requestUrl,
                    })
                }
            />
        </div>
    );

    const actions = {
        width: 130,
        fixed: 'right',
        align: 'center',
        render: (text: string, record: any) => action(text, record),
        title: <Trans>Request Data</Trans>,
    };

    return (
        <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item>
                    <Trans>Homepage</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>External data connection</Trans>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Trans>Sap connection</Trans>
                </Breadcrumb.Item>
            </Breadcrumb>
            {drawer.component}
            <DtoTable
                model={SapConnectionModel}
                trigger={trigger}
                action={actions}
                pageSize={100}
                scroll={{ x: 1300, y: '65vh' }}
            />
        </>
    );
};
export default SapConnectionScreen;
