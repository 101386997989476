import useSafeState from '../../utils/safe-state';
import React from "react";
import {Breadcrumb, Button, Col, Input, message, Popconfirm, Row, Space, Table, Tooltip} from "antd";
import {AxiosResponse} from "axios";
import Drawer from "../../components/drawer/drawer";
import {
    CloseOutlined,
    DeleteOutlined,
    EditOutlined, FilterFilled, FilterOutlined,
    KeyOutlined, LockOutlined, PlusOutlined, RetweetOutlined, SearchOutlined,
} from '@ant-design/icons/lib';
import {useAxios} from "../../utils/hooks";
import UserManagementForm from "../../forms/user-management-form/user-management-form";
import PasswordReset from "../../components/password-reset/password-reset";
import RoleSettingForm from "../../forms/role-setting-form/role-setting-form";
import {I18nProvider} from "@lingui/react";
import {i18n} from "@lingui/core";
import {useKeycloak} from "@react-keycloak/web";
import {t, Trans} from "@lingui/macro";

interface SupplierDto
{
    code: number,
    name: string
}

const UserManagementScreen = () => {

    const axiosInstance = useAxios(process.env.REACT_APP_API_KEYCLOAK_URL + "")
    const axiosBackend = useAxios(process.env.REACT_APP_API_BASE_URL + "")
    const [users, setUsers] = useSafeState<any>([]);
    const [roles, setRoles] = useSafeState<any[]>([]);
    const [trigger, setTrigger] = useSafeState(false);
    const [page, setPage] = useSafeState(1);
    const [size, setSize] = useSafeState(10);

    const [searchedColumn, setSearchedColumn] = useSafeState<any>();
    const [searchText, setSearchText] = useSafeState<any>();

    const [filteredInfo, setFilteredInfo] = useSafeState<any>({});
    let suppliers : SupplierDto[];
    let speedRoles : any[] = [];
    let speedUsers : any[] = [];

    const [isAnyFilterOnTable, setIsAnyFilterOnTable] = useSafeState(false);

    const {initialized, keycloak} = useKeycloak();
    let obj: any = JSON.parse(JSON.stringify(keycloak.tokenParsed));

    const antdContext: any = (text: string) => {
        return <I18nProvider i18n={i18n}>
            <Trans id={text}></Trans>
        </I18nProvider>;
    }

    const text = <Trans id={'User'}/>;
    const drawer = Drawer(
        {
            title: text,
            children:
                <UserManagementForm/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    const pwdTitle = antdContext('Kérlek adj meg egy ideiglenes jelszót!');
    const passwordReset = PasswordReset(
        {
            title: pwdTitle,
            children:
                <></>,
            customClose: () => setTrigger(!trigger)
        }
    );

    const roleTitle = antdContext('Kérlek válassz jogosultságot!');
    const roleSetting = RoleSettingForm(
        {
            title: roleTitle,
            children:
                <span/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    React.useEffect(() => {
        getRealmRoles();
        getSuppliers();

    }, [trigger]);

    React.useEffect(() => {


        let isAny = false;
        for (var key of Object.keys(filteredInfo)) {
            if(filteredInfo[key] != null){
                isAny = true;
            }
        }
        setIsAnyFilterOnTable(isAny);
    }, [filteredInfo]);


    const getUsersByRoleList  = async (roleList:any) =>
    {

        if (axiosInstance.current != null) {

            let usersArray : any[] = [];

            for (let i = 0; i <roleList.length; i++)
            {
                let response = await axiosInstance.current.get("/auth/admin/realms/mtsk/roles/" + roleList[i] + "/users");

                var userList = response.data;
                for(var j = 0; j < userList.length; j++){

                    userList[j].rolename = roleList[i];

                    if(userList[j].attributes && userList[j].attributes["supplierCode"] && userList[j].attributes.supplierCode.length > 0) {
                        userList[j].supplierName = findSupplierNameByCode(
                            userList[j].attributes["supplierCode"][0]
                        );
                    }

                    var usr = getUser(userList[j], usersArray);
                    if(usr == null){
                        if(obj.supplierCode){
                            if(
                                userList[j].attributes &&
                                userList[j].attributes.supplierCode &&
                                userList[j].attributes.supplierCode.length > 0 &&
                                userList[j].attributes.supplierCode == obj.supplierCode

                            ){
                                usersArray.push(userList[j]);
                            }
                        }else{
                            usersArray.push(userList[j]);
                        }
                    }else{
                        if(roleList[i] != 'default-roles-mtsk' && roleList[i] != 'MTSK_MANAGE_USER'){
                            usr.rolename = roleList[i];
                        }
                    }
                }
            }

            usersArray = sortArray(usersArray);
            setUsers(usersArray);
            speedUsers=usersArray;
        }
    }

    const sortArray = (list: any) => {

        for(let i = 0; i < list.length - 1; i++){

            for(let j = i + 1; j < list.length; j++){

                if(list[i].username.localeCompare(list[j].username) == 1 ){
                    let tmp = list[i];
                    list[i] = list[j];
                    list[j] = tmp;
                }
            }
        }

        return list;
    }

    const getUser = (user: any, usersArray: any) => {

        for(let i = 0; i < usersArray.length; i++){
            if(user.username == usersArray[i].username){
                return usersArray[i];
            }
        }

        return null;
    }

    const getRealmRoles = () =>
    {

        if (axiosInstance.current != null) {
            axiosInstance.current.get("/auth/admin/realms/mtsk/roles")
                .then(function (response: AxiosResponse<any>) {

                    setRoles(response.data);

                    let roleArray : any[] = [];

                    response.data.forEach(function (item :any) {
                        roleArray.push(item.name);
                    });

                    speedRoles = roleArray;

                    getUsersByRoleList(speedRoles);

                }).catch(function (error: any) {
                console.log(error);
            });
        }

    }

    const findSupplierNameByCode = (supplierCode: number): string => {
        let name = "";
        if (suppliers) {
            for (let i = 0; i < suppliers.length; i++) {
                let supplier = suppliers[i];
                if (Number(supplier.code) == Number(supplierCode)) {
                    name = supplier.name;
                    break;
                }
            }
        }

        return name;
    }

    const getSuppliers = () =>
    {
        if (axiosBackend.current != null) {
            axiosBackend.current.get("/resource/suppliers")
                .then(function (response: AxiosResponse<any>)
                {
                    let myArray: SupplierDto[] = [];
                     response.data.forEach((value: SupplierDto) =>
                     {
                         myArray.push({code:value.code,name:value.name});
                     });

                     suppliers=myArray;
                }).catch(function (error: any) {
                console.log(error);
            });
        }
    }

    const handleDelete = (record: any) => {

        if (!axiosInstance.current) {
            return;
        }

        axiosInstance.current.delete("/auth/admin/realms/mtsk/users/" + record.id)
            .then(() => {


                if (record.attributes && record.attributes.supplierCode && axiosBackend.current != null && record.email) {
                    axiosBackend.current.delete("/resource/user-mails/id?supplier="+record.attributes.supplierCode+"&mail="+record.email);
                }

                message.success(antdContext('Az adott sor sikeresen törölve lett.'));
                setTrigger(false);
                setTrigger(!trigger);
            }).catch(function (error: any) {
            message.error(antdContext('Hiba a mentés közben'));
            setTrigger(false);
            setTrigger(!trigger);
            console.log(error);
        });
    }

    const action = (text: string, record: any) => (

        <>
            <EditOutlined className={"ActionButtons"} style={{color: "#40a9ff"}} onClick={() => {
                drawer.open("Edit", {
                    id: record.id,
                    username: record.username,
                    firstName: record.firstName,
                    lastName: record.lastName,
                    enabled: record.enabled,
                    email: record.email,
                    supplierCode: record.attributes== undefined ? '' : record.attributes.supplierCode
                })
            }}/>
            <RetweetOutlined className={"ActionButtons"} style={{color: "#73d13d"}}
                         onClick={() => passwordReset.showPromiseConfirm({
                             id: record.id,
                             username: record.username
                         })}/>
            <LockOutlined className={"ActionButtons"} style={{color: "#ff7a45"}}
                          onClick={() => {

                              if (axiosInstance.current != null) {
                                  axiosInstance.current.get("/auth/admin/realms/mtsk/users/" + record.id + "/role-mappings/realm")
                                      .then(function (response: AxiosResponse<any>) {

                                          let role: any = '';
                                          for (let i = 0; i < response.data.length; i++) {
                                              if (response.data[i]["name"].startsWith('MTSK_')) {
                                                  role = response.data[i]["name"];
                                                  break;
                                              }
                                          }

                                          roleSetting.showPromiseConfirm({
                                                  role: role,
                                                  roles: roles,
                                                  userId: record.id,
                                                  username: record.username,
                                                  supplierCode: record.attributes== undefined ? '' : record.attributes.supplierCode
                                              }
                                          );

                                      }).catch(function (error: any) {
                                      console.log(error);
                                  });
                              }
                          }}/>
            <Popconfirm title={<Trans id={'Are you sure to delete this user?'}></Trans>}
                        onConfirm={() => handleDelete(record)}
                        okText={<Trans id={'Yes'}></Trans>}
                        cancelText={<Trans id={'No'}></Trans>}
            >
                <DeleteOutlined className={"ActionButtons"} style={{color: "#ff4d4f"}}/>
            </Popconfirm>
        </>
    );

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (

            <div style={{ padding: 8 }}>
                <Row>
                    <Col>
                        <Input
                            value={selectedKeys[0]}
                            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                            onPressEnter={(event:any) => {
                                handleSearch(selectedKeys, confirm, dataIndex);
                                confirm({ closeDropdown: false });
                            }}
                            style={{ marginRight: 1, width: 150}}
                        />
                        <>
                            <Button
                                style={{ marginRight: 1}}
                                type="primary"
                                onClick={(event : any) => {
                                    handleSearch(selectedKeys, confirm, dataIndex);
                                    confirm({ closeDropdown: false });
                                }}
                                icon={<SearchOutlined />}
                            >
                            </Button>

                            <Button onClick={() => {
                                handleReset(clearFilters);

                                confirm({ closeDropdown: false });
                                setSearchText(selectedKeys[0]);
                                setSearchedColumn(dataIndex);
                                }
                            }
                                    icon={<CloseOutlined />}>
                            </Button>
                        </>

                    </Col>
                </Row>
            </div>

        ),
        onFilter: (value: any, record: any) => {

            return getEmbeddedProperty(record, dataIndex)
                ? getEmbeddedProperty(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase())
                : '';
            }
    });

    const getProperty = <T, K extends keyof T>(obj: T, key: K) => {
        if(obj != undefined){
            return obj[key];
        }

        return null;
    }

    const getEmbeddedProperty = (o: any, s: any) => {

        s = s.replace(/\[(\w+)\]/g, '.$1');
        s = s.replace(/^\./, '');
        var a = s.split('.');
        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k != null && o != null && k in o) {
                o = o[k];
            } else {
                return null;
            }
        }

        return Array.isArray(o) ? o[0] : o;
    }

    const columns = [
        {
            title: <Trans>username</Trans>,
            dataIndex: 'username',
            key: 'username',
            filteredValue: filteredInfo.username || null,
            ...getColumnSearchProps('username'),
            sortDirection: 'ascend'
        },
        {
            title: <Trans>firstName</Trans>,
            dataIndex: 'firstName',
            key: 'firstName',
            filteredValue: filteredInfo.firstName || null,
            ...getColumnSearchProps('firstName')
        },
        {
            title: <Trans>lastName</Trans>,
            dataIndex: 'lastName',
            key: 'lastName',
            filteredValue: filteredInfo.lastName || null,
            ...getColumnSearchProps('lastName')
        },
        {
            title: <Trans>Email</Trans>,
            dataIndex: 'email',
            key: 'email',
            filteredValue: filteredInfo.email || null,
            ...getColumnSearchProps('email')
        },
        {
            title: <Trans>supplierCode</Trans>,
            dataIndex: ['attributes', 'supplierCode'],
            key: 'attributes.supplierCode',
            //filteredValue: filteredInfo.attributes != null ? filteredInfo.attributes.supplierCode : null,
            //...getColumnSearchProps('attributes.supplierCode')
        },
        {
            title: <Trans>supplierName</Trans>,
            dataIndex: ['supplierName'],
            key: 'supplierName',
            render(text: string, record: any)
            {
                return record.supplierName;
            },
        },
        {
            title: <Trans>Organization</Trans>,
            dataIndex: ['attributes', 'organization'],
            key: 'attributes.organization',
            //filteredValue: filteredInfo.attributes != null ? filteredInfo.attributes.supplierCode : null,
            //...getColumnSearchProps('attributes.supplierCode')
        },
        {
            title: <Trans>Role</Trans>,
            dataIndex: 'rolename',
            key: 'xy',
            render: (text: string, record: any) => {
                if(text == 'default-roles-mtsk' || text == 'MTSK_MANAGE_USER'){
                    return '';
                }
                return text;
            }
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
            width: 160,
            render: (text: string, record: any) => action(text, record),
        }
    ];

    const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: any) => {
        clearFilters();
        setSearchText('');
    };

    const clearFilters = () => {
        setSearchedColumn(null);
        setSearchText('');
        setFilteredInfo({});
    };

    const handleChange = (pagination: any, filters: any, sorter: any) => {
        setFilteredInfo(filters);
    };

    return (
        <>
            <Breadcrumb style={{marginBottom: 10}}>
                <Breadcrumb.Item><Trans id={'Kezdőoldal'}/></Breadcrumb.Item>
                <Breadcrumb.Item><Trans id={'Felhasználó kezelés'}></Trans></Breadcrumb.Item>
            </Breadcrumb>
            <Button type={"primary"} onClick={() => drawer.open("Add new")} style={{float: "left", marginRight: 10}}>
                <PlusOutlined/>
                <span><Trans id={'Új hozzáadása'}></Trans></span>
            </Button>
            <Tooltip placement="topLeft" title={<Trans>Clear all filters</Trans>}>
                <Button
                    onClick={() => clearFilters()}>
                    {isAnyFilterOnTable
                        ? <FilterFilled style={{color: '#1890ff'}} />
                        : <FilterOutlined style={{color: '#1890ff'}} />
                    }

                </Button>
            </Tooltip>
            <div style={{marginBottom: 10}}></div>
            {drawer.component}
            <Row gutter={24}>
                <Col span={24}>
                    <Table
                        style={{height: "100%"}}
                        dataSource={users}
                        columns={columns}
                        bordered
                        onChange={handleChange}
                        scroll={{x: 1300, y: "65vh"}}
                        pagination={
                            {
                                total: users.size,
                                pageSize: 10,
                                onChange: (page: any) => setPage(page),
                                showSizeChanger: false,
                                pageSizeOptions: ["5", "10", "15", "20", "50", "100"],
                                current: page,
                                onShowSizeChange: (current, pageSize) => setSize(pageSize),
                            }
                        }
                    />
                </Col>
            </Row>
        </>
    );
}
export default UserManagementScreen;
