import useSafeState from '../../utils/safe-state';
import React, {useEffect, useState, useRef} from "react";
import {Form, Row, Col, Input, Button, message, Select, Radio, Space, DatePickerProps, DatePicker} from 'antd';
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import {useKeycloak} from "@react-keycloak/web";
import { AxiosInstance, AxiosResponse } from 'axios';
import type { RadioChangeEvent } from 'antd';
import moment from 'moment';

import getEnumArray from "../../utils/enums";

import {InboxOutlined} from "@ant-design/icons";

import {Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import {antdContext} from "../../utils/antdContext";
import { InputNumber } from 'antd';
import type { InputRef } from 'antd';
import ClaimListScreen from '../../screens/ppap/claim-list';



interface Interface{
    requestId: any;
    mode?: "View" | "AddNew";
}

const MLAUploadForm = (props : Interface) => {

    
    const { TextArea } = Input;
    const [responsibleOrganisationOptions,setResponsibleOrganisationOptions] = useSafeState<any>();
    const [claimTypeOptions,setClaimTypeOptions] = useSafeState<any>();
    const [importanceRatingOptions,setImportanceRatingOptions] = useSafeState<any>();
    const [spareIdentifierOptions,setSpareIdentifierOptions] = useSafeState<any>();
    const [demandedImmediateActionOptions,setDemandedImmediateActionOptions] = useSafeState<any>();
    const [itemNoSearch, setItemNoSearch] = useSafeState<any>();
    const [items, setItems] = useSafeState<any>([]);
    const [selectedItem, setSelectedItem] = useSafeState<any>();
    const [itemNo, setItemNo] = useSafeState<any>([]);

    const [form] = Form.useForm();
    
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");

    const [value, setValue] = useState(1);
    const [loading, setLoading] = useSafeState(false);
    

    const [result, setResult] = useSafeState<boolean>();
    const [isView, setIsView] = useSafeState<boolean>();
    const {initialized, keycloak} = useKeycloak();
    const [userId, setUserId] = useSafeState<number>();
    const [back, setBack] = useSafeState<boolean>(false);


    let idTokenParsed:any = JSON.parse(JSON.stringify(keycloak.idTokenParsed));
    let supplierCode:string = idTokenParsed.supplierCode;
    let uploader:string = idTokenParsed.name;

    const getSupplierName = () => {
        if(axiosInstance.current != null) {

            const resp = axiosInstance.current.get("/resource/suppliers/" + supplierCode, {
                    
                }
                ).then(function (response : AxiosResponse<any>)
                {
                    let data: any = JSON.parse(JSON.stringify(response.data));
                    
                    form.setFieldsValue({"suppliername":data.name});
                    form.setFieldsValue({"suppliercode":supplierCode});
                    form.setFieldsValue({"uploader":uploader});
                    

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                
            });

            
        }
    }

    const getUserId = () => {
        if(axiosInstance.current != null) {
            
            let obj: any = JSON.parse(JSON.stringify(keycloak.tokenParsed));

            const resp = axiosInstance.current.get("/resource/user/get-user?userName=" + obj.preferred_username, {
                    
                }
                ).then(function (response : AxiosResponse<any>)
                {
                    let data: any = JSON.parse(JSON.stringify(response.data));

                    setUserId(data.id);
                    

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                
            });

            
        }
    }

    const viewMode = () => {
        if(axiosInstance.current != null) {
            
            const resp = axiosInstance.current.get("/resource/request-data/get-request-data?requestId=" + props.requestId, {
                    
                }
                ).then(function (response : AxiosResponse<any>)
                {
                   
                    let dataArray:any[] = JSON.parse(JSON.stringify(response.data));
                    
                    dataArray.forEach(value => {
                        switch (value.name) {
                            case 'suppliername':
                                form.setFieldsValue({"suppliername":value.value});
                            break;
                            case 'suppliercode':
                                form.setFieldsValue({"suppliercode":value.value});
                            break;
                            case 'uploader':
                                form.setFieldsValue({"responsibleorganisation":value.value});
                            break;
                            default:
                            break;    
                        }
                        
                    });

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }
    }

    useEffect(() => {
        
        getSupplierName();
        setIsView(props.mode == 'View' ? true : false);
        if (!isView) {
            
            getUserId();
        }
    }, []);

    useEffect(() => {
        if (isView) {
            viewMode();
        }
    },[isView]

    );

    const handleFinish = () => {
        
        const obj = {
            suppliername: form.getFieldValue(["suppliername"]),
            suppliercode: form.getFieldValue(["suppliercode"]),
            uploader: form.getFieldValue(["uploader"])
        };

        if(axiosInstance.current != null) {
            setLoading(true);
            
            const resp = axiosInstance.current.post("/resource/requests/newRequest?processId=2&title="+form.getFieldValue(["suppliercode"])+"&userId="+userId, {
                    reqData: obj
                }
                ).then(function (response : AxiosResponse<any>)
                {
                    if (fileList.length > 0) {
                        handleUpload(response.data.id);
                    }
                    
                    setResult(false);

                }).catch(function (error :any) {
                message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }

    }

    const { Dragger } = Upload;

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [uploading, setUploading] = useState(false);

    const uploadProps: UploadProps = {
        name: 'file',
        multiple: true,
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (status === 'done') {
            message.success(`${info.file.name} file uploaded successfully.`);
          } else if (status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);

            return false;
        },
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
          },
        fileList,
    };

    const handleUpload = (requestId:any) => {
        
        fileList.forEach((file) => {
          const uploadedFile = new FormData();
          uploadedFile.append('uploadedFile', file as RcFile);
            if (axiosInstance.current != null) {

                axiosInstance.current.post("/resource/request-files/upload-file?requestId=" + requestId + "&actionId=33", uploadedFile,
                    { headers: { 'Content-Type': 'multipart/form-data' } }).then(() => {
                        setUploading(false);
                    }).catch(function (error :any) {
                        console.log(error)
                        setUploading(false);
                });
            }
        });
        setUploading(true);
        
        
    };

    type optionalType = {
        key?: number;
        label: any;
        value: string;
    };

    return(
        result == undefined ? (
            back == true ? (
                <ClaimListScreen/>
            ) : (
                <Form id="mlaupload"  layout='vertical' form={form}  onFinish={() => handleFinish()}>
                <Row gutter={24}>
                    <Col span={12}>
                        <h2><Trans>MLA Upload</Trans></h2>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name="suppliercode"
                            label={<Trans>Supplier Code</Trans>}
                            children={<Input disabled={true}/>}
                        />
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="suppliername"
                            label={<Trans>Supplier Name</Trans>}
                            children={<Input disabled={true}/>}
                        />
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="uploader"
                            label={<Trans>Uploader</Trans>}
                            children={<Input disabled={true}/>}
                        />
                    </Col>
                    
                </Row>
               
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name="datafiles"
                            children={<Dragger disabled={isView} {...uploadProps}>
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text"><Trans>Click or drag file to this area to upload</Trans></p>
                          </Dragger>
                          }
                        />
                    </Col>
                </Row>
                
                {
                    isView ? (
                        <Form.Item
                            children={
                                <Button htmlType="button" type="primary" onClick={() => setBack(true)}>
                                    <Trans>Back</Trans>
                                </Button>
                            }
                    />
                    ):(
                    <Form.Item
                        children={
                            <Button htmlType="button" type="primary" onClick={() => form.submit()}>
                                <Trans>Save</Trans>
                            </Button>
                        }
                    />)
                }

            </Form>
            )
            ) : (
                <ClaimListScreen/>
            )
            );

}
export default MLAUploadForm;
