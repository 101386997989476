import { Trans } from '@lingui/macro';
import { useKeycloak } from '@react-keycloak/web';
import useSafeState from '../../utils/safe-state';
import { AxiosResponse } from 'axios';
import React, {ReactNode, useCallback} from 'react';
import {Alert, Avatar, Button, Card, Col, Divider, List, Row, Skeleton, Space, Statistic} from 'antd';
import { useAxios } from '../../utils/hooks';
import InfiniteScroll from "react-infinite-scroll-component";
import {Link} from "react-router-dom";
import {Message} from "../../interfaces/Message";
import {DownloadOutlined} from "@ant-design/icons";
import isAuthorized from "../../utils/is-authorized";
import {MessageDocument} from "#/interfaces/MessageDocument";


const LandingScreen = () => {

    const { keycloak } = useKeycloak();
    const fcrAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL  + "")

    const [downloadedOrders, setDownloadedOrders] = useSafeState<number>(0);
    const [notDownloadedOrders, setNotDownloadedOrders] = useSafeState<number>(0);
    const [allOrders, setAllOrders] = useSafeState<number>(0);
    const [messageReactNode, setMessageReactNode] = useSafeState<Array<ReactNode>>([]);
    const [suppliers, setSuppliers] = useSafeState<any>([]);
    const [loading, setLoading] = useSafeState(false);

    let keycloakToken: any = JSON.parse(JSON.stringify(keycloak.tokenParsed));

    if(isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN","MTSK_USER"]))
    {
        React.useEffect(() => {
            getSuppliers();
            getAllOrders("");
            getNotDownloadedOrders("");
            getDownloadedOrders("");
            getAllMessage();
        }, []);
    }
    else
    {
        React.useEffect(() => {
            getNotDownloadedOrders("/"+keycloakToken.supplierCode);
            getDownloadedOrders("/"+keycloakToken.supplierCode);
            getAllOrders("/"+keycloakToken.supplierCode);
            getAllMessage();
        }, []);
    }




    const getDownloadedOrders = (extraPath : String) => {
        if(fcrAxiosInstance.current != null){
            fcrAxiosInstance.current.get("/resource/orders/get-count-of-downloaded-orders")
                .then( function (response : AxiosResponse<any>) {
                    setDownloadedOrders(response.data);
                }).catch( function (error: any){
                console.log(error);
            });
        }
    }

    const getNotDownloadedOrders = (extraPath : String) => {
        if(fcrAxiosInstance.current != null){
            fcrAxiosInstance.current.get("/resource/orders/get-count-of-not-downloaded-orders"+extraPath)
                .then( function (response : AxiosResponse<any>) {
                    setNotDownloadedOrders(response.data);
                }).catch( function (error: any){
                console.log(error);
            });
        }
    }

    const getAllOrders = (extraPath: String) => {
        if(fcrAxiosInstance.current != null){
            fcrAxiosInstance.current.get("/resource/orders/get-count-of-all-orders"+extraPath)
                .then( function (response : AxiosResponse<any>) {
                    setAllOrders(response.data);
                }).catch( function (error: any){
                console.log(error);
            });
        }
    }


    const getAllMessage = () => {
        if(fcrAxiosInstance.current != null){
            fcrAxiosInstance.current.get("/resource/messages/get-messages-by-supplier")
                .then( function (response : AxiosResponse<any>)
                {
                    let messageArray : Array<ReactNode> = [];
                    response.data.forEach((message : Message)  =>
                    {
                        let messageDocuments : ReactNode[] = [];

                        if (message.messageDocuments!=null)
                        {
                            message.messageDocuments.forEach(function (messageDocument : MessageDocument) {

                                messageDocuments.push(
                                    <Button
                                         href={process.env.REACT_APP_API_BASE_URL+"/public/file/get?folder="+messageDocument.folder+"&filename="+message.id+"/"+messageDocument.fileName}
                                         type="primary" icon={<DownloadOutlined />} size={"small"}>
                                        {messageDocument.fileName}
                                    </Button>
                                );

                            });
                        }

                        messageArray.push(
                            <Col span={24}>
                                <Alert
                                    style={{marginBottom: 5}}
                                    message={<b>{message.title}</b>}
                                    description={
                                        <div>
                                            {message.message}
                                        </div>
                                    }
                                    action={
                                        <>
                                            <Space direction="vertical">
                                                {messageDocuments}
                                            </Space>
                                        </>
                                    }
                                    type="info"
                                    showIcon
                                />
                            </Col>
                        );
                    });
                    setMessageReactNode(messageArray);
                }).catch( function (error: any){
                console.log(error);
            });
        }
    }

    const getSuppliers = () => {

        if (loading) {
            return;
        }

        if(fcrAxiosInstance.current != null){
            fcrAxiosInstance.current.get("/resource/suppliers/get-suppliers-who-have-not-downloaded-orders")
                .then( function (response : AxiosResponse<any>) {
                        setSuppliers(response.data);
                        console.log(suppliers);
                        setLoading(false);
                }).catch( function (error: any){
                console.log(error);
                setLoading(false);
            });
        }
    }

    const setDownloadedPerAll = () => {
        return allOrders > 0 ? downloadedOrders / allOrders * 100 : 0;
    }

    const setNotDownloadedPerAll = () => {
        return allOrders > 0 ? notDownloadedOrders / allOrders * 100 : 0;
    }

    return (
            <>
                {isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN","MTSK_USER"])
                    ? <>
                <Row gutter={24}>
                    <Col span={12}>
                        <h2><Trans>Orders</Trans></h2>
                    </Col>
                    <Col span={12}>
                        <h2><Trans>Suppliers who have not downloaded orders</Trans></h2>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Card style={{backgroundColor: "#d9f7be"}}>
                            <Statistic
                                title={<Trans>Letöltött / Összes</Trans>}
                                value={downloadedOrders}
                                suffix={"/ " + allOrders}
                            />
                            <Statistic
                                title={<Trans>Százalék</Trans>}
                                value={setDownloadedPerAll()}
                                precision={2}
                                valueStyle={{ color: '#3f8600' }}
                                suffix="%"
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card style={{backgroundColor: "#fff1b8"}}>
                            <Statistic
                                title={<Trans>Nem letöltött / Összes</Trans>}
                                value={notDownloadedOrders}
                                suffix={"/ " + allOrders}
                            />
                            <Statistic
                                title={<Trans>Százalék</Trans>}
                                value={setNotDownloadedPerAll()}
                                precision={2}
                                valueStyle={{ color: '#d48806' }}
                                suffix="%"
                            />
                        </Card>
                    </Col>
                    <Col span={12}>
                        <div
                            id="scrollableDiv"
                            style={{
                                height: 175,
                                overflow: 'auto',
                                padding: '0 16px',
                                border: '1px solid rgba(140, 140, 140, 0.35)',
                            }}
                        >
                            <InfiniteScroll
                                dataLength={suppliers.length}
                                next={getSuppliers}
                                hasMore={suppliers.length < 1}
                                loader={<Skeleton avatar paragraph={{ rows: 0 }} active />}
                                // endMessage={<Divider plain><Trans>It is all, nothing more</Trans></Divider>}
                                scrollableTarget="scrollableDiv"
                            >
                                <List
                                    dataSource={suppliers}
                                    renderItem={(supplier: any) => (
                                        <List.Item key={supplier.code}>
                                            <List.Item.Meta
                                                title={supplier.name}
                                                description={
                                                    <a href={"mailto:" + supplier.email}>{supplier.email}</a>
                                                }
                                            />
                                            <div style={{fontSize: "large", fontWeight: "bold"}}>{supplier.code}</div>
                                        </List.Item>
                                    )}
                                />
                            </InfiniteScroll>
                        </div>
                    </Col>
                </Row>
                <br />
                </>
                :
                    <>
                        {/*
                         FOR NORMAL USER #
                         MTSK_SUPPL_ADMIN
                         MTSK_SUPPL_USER
                         */}
                        <Row gutter={24}>
                            <Col span={12}>
                                <h2><Trans>Orders</Trans></h2>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={6}>
                                <Card style={{backgroundColor: "#d9f7be"}}>
                                    <Statistic
                                        title={<Trans>Letöltött / Összes</Trans>}
                                        value={downloadedOrders}
                                        suffix={"/ " + allOrders}
                                    />
                                    <Statistic
                                        title={<Trans>Százalék</Trans>}
                                        value={setDownloadedPerAll()}
                                        precision={2}
                                        valueStyle={{ color: '#3f8600' }}
                                        suffix="%"
                                    />
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card style={{backgroundColor: "#fff1b8"}}>
                                    <Statistic
                                        title={<Trans>Nem letöltött / Összes</Trans>}
                                        value={notDownloadedOrders}
                                        suffix={"/ " + allOrders}
                                    />
                                    <Statistic
                                        title={<Trans>Százalék</Trans>}
                                        value={setNotDownloadedPerAll()}
                                        precision={2}
                                        valueStyle={{ color: '#d48806' }}
                                        suffix="%"
                                    />
                                </Card>
                            </Col>
                        </Row>

                    </>}
                <Row gutter={24}>
                    <Col span={8}>
                        <h2><Trans>Notifications</Trans></h2>
                    </Col>
                </Row>
                <Row gutter={24}>
                    {
                        messageReactNode
                    }
                </Row>
            </>
    );
}
export default LandingScreen;
