import useSafeState from '../../utils/safe-state';
import {Form, Modal, Select} from 'antd';
import React, {ReactElement} from "react";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useAxios} from "../../utils/hooks";
import {Trans} from "@lingui/macro";
import {I18nProvider} from "@lingui/react";
import {i18n} from "@lingui/core";
import {notif} from "../../utils/notif";
import isAuthorized from "../../utils/is-authorized";
import {useKeycloak} from "@react-keycloak/web";

const { confirm } = Modal;

interface Interface {
    title?: string;
    children:  ReactElement;
    onCustomClose?: () => void;
    customClose?: () => void;
    name?: string;
}

const RoleSettingForm = (props : Interface) => {

    const axiosInstance = useAxios(process.env.REACT_APP_API_KEYCLOAK_URL + "")
    const {initialized, keycloak} = useKeycloak();
    const [visible, setVisible] = useSafeState(false);
    const [data,setData] = useSafeState([]);
    const [mode,setMode]= useSafeState("Edit");

    const [form] = Form.useForm();
    form.resetFields();

    let selectedRole: roleType[];
    let manageUserRole: roleType;

    type roleType = {
        id: string,
        name: string
    }

    type optionalType = {
        label: string,
        value: string
    }

    const onClose = () => {
        if(props.customClose)
        {
            props.customClose();
        }
        setVisible(false);
    };

    const generateRoleOptions = (data : any, supplierCode: any) : optionalType[] =>  {
        let arrayToOptions = new Array<optionalType>();
        for (var i = 0; i < data.length; i++) {

            if(!data[i]['name'].startsWith('MTSK_')){
                continue;
            }

            if(data[i]['name'] == 'MTSK_MANAGE_USER'){
                manageUserRole = data[i];
                continue;
            }


            if(
                data[i]['name'] == 'MTSK_GLOBAL_ADMIN' &&
                isAuthorized(keycloak, ["MTSK_GLOBAL_ADMIN"])
                //&& supplierCode != undefined && supplierCode.length == 0
            ){

                let currentItem : optionalType = {value:
                        "{\"id\": \"" + data[i]['id'] + "\", \"name\": \"" + data[i]['name'] + "\"}"
                    , label: data[i]['name']};

                arrayToOptions.push(currentItem);
            }

            if(
                data[i]['name'] == 'MTSK_USER' &&
                isAuthorized(keycloak, ["MTSK_GLOBAL_ADMIN"])
                //&& supplierCode != undefined && supplierCode.length == 0
            ){
                let currentItem : optionalType = {value:
                        "{\"id\": \"" + data[i]['id'] + "\", \"name\": \"" + data[i]['name'] + "\"}"
                    , label: data[i]['name']};

                arrayToOptions.push(currentItem);
            }

            if(
                data[i]['name'] == 'MTSK_SUPPL_ADMIN' &&
                (isAuthorized(keycloak, ["MTSK_GLOBAL_ADMIN"]) || isAuthorized(keycloak, ["MTSK_SUPPL_ADMIN"]))
                //&& supplierCode != undefined && supplierCode.length == 0
            ){
                let currentItem : optionalType = {value:
                        "{\"id\": \"" + data[i]['id'] + "\", \"name\": \"" + data[i]['name'] + "\"}"
                    , label: data[i]['name']};

                arrayToOptions.push(currentItem);
            }

            console.log(data[i]['name']);

            if(
                data[i]['name'] == 'MTSK_SUPPL_USER' &&
                (isAuthorized(keycloak, ["MTSK_GLOBAL_ADMIN"]) || isAuthorized(keycloak, ["MTSK_SUPPL_ADMIN"]))
                //&& supplierCode != undefined && supplierCode.length == 0
            ){
                let currentItem : optionalType = {value:
                        "{\"id\": \"" + data[i]['id'] + "\", \"name\": \"" + data[i]['name'] + "\"}"
                    , label: data[i]['name']};

                arrayToOptions.push(currentItem);
            }
        }
        return arrayToOptions;
    }

    const handleOnChange = () => {
        selectedRole = [];
        console.log(JSON.parse(form.getFieldValue(["role"])));
        selectedRole.push(JSON.parse(form.getFieldValue(["role"])));
    };

    const open  = ( mode? : "Add new" | "Edit" | "" , recordData?: any) => {
        setMode(mode ?  mode : '');
        setData(recordData);
        setVisible(true);
    };

    const addRoleCall = (id:any, roles: any) => {

        if(!axiosInstance.current){
            return;
        }

        if(selectedRole[0].name == "MTSK_SUPPL_ADMIN" || selectedRole[0].name == "MTSK_GLOBAL_ADMIN"){
            //selectedRole.push(manageUserRole)
        }

        if(axiosInstance.current != null && selectedRole.length > 0) {
            axiosInstance.current({
                url: process.env.REACT_APP_API_KEYCLOAK_URL + "/auth/admin/realms/mtsk/users/"+id+"/role-mappings/realm",
                method: 'DELETE',
                data: roles
            }).then(() => {
                if(axiosInstance.current != null){
                    axiosInstance.current.post("/auth/admin/realms/mtsk/users/"+id+"/role-mappings/realm", selectedRole).then(() => {
                        notif("success", "Sikeres mentés!");
                        onClose();
                    }).catch(function (error :any) {
                        console.log(error);
                        notif("error", "Sikertelen mentés!");
                    });
                }
            }).catch(function (error: any) {
                console.log(error);
                notif("error", "Sikertelen mentés!");
            })
        }
    }


    const showPromiseConfirm = (recordData?: any) => {
        setData(recordData);
        confirm({
            title: props.title,
            icon: <ExclamationCircleOutlined />,
            content:
                <I18nProvider i18n={i18n}>
                <Form form={form}>
                    <Form.Item
                        name="role"
                        label={<Trans id={'Jogosultság'}/>}
                        children={
                            <Select
                                allowClear
                                showSearch={true}
                                style={{width: '100%'}}
                                placeholder={'...'}
                                options={generateRoleOptions(recordData.roles, recordData.supplierCode)}
                                onChange={() => handleOnChange()}
                            />
                        }
                        rules={[{
                            required: true,
                            message: 'Please select roles'
                        }]}
                    />
                </Form>
                </I18nProvider>,
            visible: visible,
            onOk() {
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    addRoleCall(recordData.userId, recordData.roles);
                }).catch(() => console.log('Oops error!'));
            },
            onCancel() {

            },
        });
    }

    return {open,showPromiseConfirm};

};
export default RoleSettingForm;
