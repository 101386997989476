import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import MessageModel from "../../models/dto/message-model";
import useSafeState from "../../utils/safe-state";
import Drawer from "../../components/drawer/drawer";
import MessageForm from "../../forms/message-form/message-drawer";

import {Trans} from "@lingui/macro";
import {Breadcrumb, Button, message, Popconfirm,Image} from "antd";
import {DeleteOutlined, EditTwoTone} from "@ant-design/icons";
import {antdContext} from "../../utils/antdContext";
import {useAxios} from "../../utils/hooks";
import {PlusOutlined} from "@ant-design/icons/lib";
import moment from "moment";


const MessageScreen : FC = () => {

    const [trigger, setTrigger] = useSafeState(false);
    const mtskAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL  + "");


    const text = <Trans>Message</Trans>
    const drawer = Drawer(
        {
            title: text,
            children:
                <MessageForm/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    const handleDelete = (id: any) => {
        if(mtskAxiosInstance.current != null){
            mtskAxiosInstance.current.delete("/resource/messages/id?id="+id)
                .then(() => {
                    message.success(antdContext('Az adott sor sikeresen törölve lett.'));
                    setTrigger(false);
                    setTrigger(!trigger);
                }).catch(function (error :any) {
                message.error(antdContext('Hiba a mentés közben'));
                setTrigger(false);
                setTrigger(!trigger);
                console.log(error);
            });
        }
    }

    const action = (text: string, record: any) => {

        return <>
            <Popconfirm title={<Trans>Are you sure to delete this message?</Trans>}
                        onConfirm={() => handleDelete(record.id)}
                        okText={<Trans>Yes</Trans>}
                        cancelText={<Trans>No</Trans>}
            >
                <DeleteOutlined className={"ActionButtons"} style={{color: "#ff4d4f"}}/>
            </Popconfirm>
            <EditTwoTone
                twoToneColor={"#40a9ff"}
                className={"ActionButtons"} onClick={
                () => drawer.open("Edit", {
                    id: record.id,
                    message: record.message,
                    messageDocuments : record.messageDocuments,
                    title: record.title,
                    startDate: record.startDate != null ? moment(record.startDate) : null,
                    endDate: record.endDate != null ? moment(record.endDate) : null,
                    supplierCode : record.supplierCode.code,
                    version: record.version
                })}/>
        </>
    }

    const actions = {
        width: 100,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    return <>
            <Breadcrumb style={{ marginBottom: 10 }}>
                <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
                <Breadcrumb.Item><Trans id={'Messages'}></Trans></Breadcrumb.Item>
            </Breadcrumb>
            <DtoTable model={MessageModel}
                      tableHeader={
                          <Button type={"primary"} onClick={ () => drawer.open("Add new")} style={{float: "left", marginRight:10}}><PlusOutlined /><span><Trans>Add new</Trans></span></Button>
                      }
                      action={actions}
                      trigger={trigger}
                      pageSize={100}
                      scroll={{ x: 1300, y: "65vh" }}
            />
        {drawer.component}
    </>
}
export default MessageScreen;
