
import {FC} from "react";

import React from 'react';

import MSCDecisionForm from "../../forms/ppap/msc-decision-form";

interface Interface{
    requestId: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

const MSCDecisionScreen: FC<Interface> = (props:any) => {

    

    return (
            
                <MSCDecisionForm
                    requestId={props.requestId}
                    mode={props.mode}
                />
            
    );
}
export default MSCDecisionScreen;
