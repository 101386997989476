import useSafeState from '../../utils/safe-state';
import React, {useState} from "react";
import {Form, Row, Col, Button, message, Radio, Space, RadioChangeEvent} from 'antd';
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import {antdContext} from "../../utils/antdContext";
import ClaimListScreen from '../../screens/ppap/claim-list';



interface Interface{
    requestId: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

const SendApprovalForm = (props : Interface) => {
   

    const [loading, setLoading] = useSafeState(false);

    const [form] = Form.useForm();
    
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");
    const [value, setValue] = useState(1);
    const [result, setResult] = useSafeState<boolean>();


    const handleFinish = () => {

        if(axiosInstance.current != null) {
            setLoading(true);

            axiosInstance.current.post("/resource/requests-to-actions/newRequestToAction?requestId=" + props.requestId, {
                    
                }
                ).then(() => {
                    setResult(false);

            }).catch(function (error :any) {
                message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });
        }

    }

    return(
        result == undefined ? (
        <Form id="check"  layout='vertical' form={form}  onFinish={() => handleFinish()}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <h2><Trans>Send Approval</Trans></h2>
                        </Col>
                    </Row>
                        
                    <Form.Item
                        children={
                            <Button htmlType="button" type="primary" onClick={() => form.submit()}>
                                <Trans>Send</Trans>
                            </Button>
                        }
                    />

        </Form> ) : (   
            <ClaimListScreen/>
        )
    );

}
export default SendApprovalForm;
