import useSafeState from '../utils/safe-state';
import * as React from 'react'
import en from '../locales/en/messages.json';
import hu from '../locales/hu/messages.json';
import {BrowserRouter as Router, Link, Switch} from 'react-router-dom';
import {useKeycloak} from '@react-keycloak/web'
import {PrivateRoute} from './utils'
import {Button, Col, Dropdown, Form, Input, Layout, Menu, message, Modal, Row, Tooltip} from 'antd';
import SubMenu from "antd/es/menu/SubMenu";

import LanguageChange from "../components/language-change/language-change";
import {Language} from "../models/language/language";

import {Trans} from "@lingui/macro";

import {i18n} from '@lingui/core'

import "../routes/App.css";
import './App.css';
import 'antd/dist/antd.css';
import "../routes/index.css";
import logo from "../images/head_logo.png"
import huFlag from "../icons/hu.png";
import enFlag from "../icons/en.png";
import {
    DatabaseOutlined, DownloadOutlined,
    HomeOutlined,
    MenuFoldOutlined, MenuUnfoldOutlined, QrcodeOutlined,
    CopyOutlined, SnippetsOutlined
} from "@ant-design/icons";
import {
    MessageOutlined,
    CalendarOutlined,
    AlertOutlined,
    CopyrightOutlined,
    ControlOutlined,
    ReconciliationOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    SwapOutlined,
    ArrowUpOutlined,
    FileOutlined, SettingOutlined, ArrowRightOutlined
} from '@ant-design/icons/lib';

import {Footer} from "antd/lib/layout/layout";
import LandingScreen from "../screens/landing/landing";
import MessageScreen from "../screens/messages/messages";
import ForecastScreen from "../screens/forecasts/forecasts";
import EmailLogScreen from "../screens/email-logs/email-logs";
import ItemScreen from "../screens/items/items";
import IncomingScreen from "../screens/incomings/incomings";
import DeliveryCalendarScreen from "../screens/delivery-calendars/delivery-calendars";
import MessageDocumentScreen from "../screens/message-document/messages-documents";
import OrderScreen from "../screens/orders/orders";
import SupplierScreen from "../screens/suppliers/suppliers";
import UserManagementScreen from "../screens/user-management/user-management";
import isAuthorized from "../utils/is-authorized";
import SapFileOrderIncomingScreen from "../screens/sap-files-incoming/sap-files-order-incoming";
import SapFileForecastIncomingScreen from "../screens/sap-files-incoming/sap-files-forecast-incoming";
import SapConnectionScreen from '../screens/sap-connection/sap-connection';
import ClaimHandlingScreenMLA from '../screens/claim-handling/claim-handling';
import ClaimHandlingScreenPPAP from '../screens/ppap/claim-handling';
import SupportIcon from "../components/icons/support-icon";
import Icon from '@ant-design/icons';
import {useAxios} from "../utils/hooks";
import {useState} from "react";
import {AxiosResponse} from "axios";
import QRCode from "qrcode";
import {antdContext} from "../utils/antdContext";
import SettingsScreen from "../screens/settings/settings";
const {Header, Sider, Content} = Layout;

export const AppRouter = () => {

    const SupportIco = (props: any) => <Icon component={SupportIcon} {...props} />;

    const {initialized, keycloak} = useKeycloak();
    const myStorage = window.localStorage;
    const axiosInstance = useAxios("")

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [qrCode, setQrCode] = useState<any>();

    const languageModel: Language[] = [
        {
            language: "hu",
            icon: huFlag
        },
        {
            language: "en",
            icon: enFlag
        }
    ];

    const [loggedUser, setLoggedUser] = useSafeState<any>('')
    const [collapsed, setCollapsed] = useSafeState(false)
    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    }


    const showModal = () => {
        setIsModalVisible(true);
    };


    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const [form] = Form.useForm();
    form.resetFields();

    React.useEffect(() => {

        if (keycloak && keycloak.tokenParsed) {

            let obj: any = JSON.parse(JSON.stringify(keycloak.tokenParsed));

            if (keycloak.tokenParsed != null) {
                setLoggedUser(obj.preferred_username);
                localStorage.setItem('username', obj.preferred_username);
                localStorage.setItem('email', obj.email);
            }
        }

    }, [initialized, keycloak]);

    const openWikiJs = () => {
        console.log(window.location.pathname);
        let pathname =
            ((window.location.pathname == "/" || window.location.pathname == "/landing")
                ? "/home"
                : window.location.pathname).replace( new RegExp("[0-9]+","gm"),"");

        window.open(process.env.REACT_APP_WIKI_URL + pathname, '_blank');
    }

    if (!initialized) {
        return <div>
            <Trans>Loading...</Trans>
        </div>
    }

    const getQrModal = () => {
        showModal();
    };
    const generateQrIcon = async (password : String)   =>
    {
        const encodedString = Buffer.from('{username:"'+loggedUser+'",password:"'+password+'"}').toString('base64');
        let at = await QRCode.toDataURL(encodedString);
        setQrCode(at);
    }
    const onQrFormFinish = async (values: any) => {

        const config = { headers: {'Content-Type': 'application/x-www-form-urlencoded'}};

        const params = new URLSearchParams()
        params.append('username', values.user)
        params.append('password', values.pass)
        params.append('client_id', process.env.REACT_APP_KEYCLOAK_CLIENT_ID + '')
        params.append('grant_type', 'password')

        if (axiosInstance.current != null) {
            axiosInstance.current.post(
                process.env.REACT_APP_API_KEYCLOAK_URL + "/auth/realms/mtsk/protocol/openid-connect/token",params, config
            ).then(function (response: AxiosResponse<any>) {

                generateQrIcon(values.pass);

            }).catch(function (error) {
                message.error(antdContext('Hibás jelszó!'));
            })
        }
    };

    const menu = (
        <Menu>
            <Menu.Item
                onClick={() => {
                console.log(window.location.origin);
                keycloak.redirectUri = window.location.origin;
                keycloak.logout();
                localStorage.clear();
            }}>
                <span style={{
                    fontSize: "small",
                    cursor: "pointer",
                    marginRight: 20
                }}>
                    <span style={{marginRight: 5}}><LogoutOutlined/></span><span><Trans>Logout</Trans></span></span>
            </Menu.Item>
            <Menu.Item onClick={() => getQrModal()}>
                <span style={{
                    fontSize: "small",
                    cursor: "pointer",
                    marginRight: 20
                }}>
                    <span style={{marginRight: 5}}><QrcodeOutlined /></span><span><Trans>Print Qr</Trans></span></span>
            </Menu.Item>
        </Menu>
    );

    return (

        <Router>
            <Layout style={{minHeight: "100vh"}}>
                <Sider trigger={null} collapsible collapsed={collapsed}>
                    <Menu theme={"dark"} mode="inline" defaultSelectedKeys={['1']}>

                        {isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]) ?
                            <Menu.Item key="1" icon={<HomeOutlined/>}>
                                <Link to="/landing"><Trans>Kezdőoldal</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }

                        {isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]) ?
                            <Menu.Item key="sap-files-order-incoming" icon={<ReconciliationOutlined/>}>
                                <Link to="/sap-files-order-incoming"><Trans>Orders</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }

                        {isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]) ?
                            <Menu.Item key="sap-files-forecast-incoming" icon={<AlertOutlined/>}>
                                <Link to="/sap-files-forecast-incoming"><Trans>Forecasts</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }

                        {isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN"]) ?
                            <SubMenu key="MasterDataSubMenu" icon={<DatabaseOutlined/>} title={<Trans>Master data</Trans>}>
                                <Menu.Item key="suppliers">
                                    <Link to="/suppliers"><Trans>Suppliers</Trans></Link>
                                </Menu.Item>
                                <Menu.Item key="items">
                                    <Link to="/items"><Trans>Items</Trans></Link>
                                </Menu.Item>
                            </SubMenu>
                            : <></>
                        }

                        {isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN", "MTSK_USER"]) ?
                            <Menu.Item key="incomings" icon={<ArrowRightOutlined />}>
                                <Link to="/incomings"><Trans>Incomings</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }

                        {isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN"]) ?
                            <Menu.Item key="messages" icon={<MessageOutlined/>}>
                                <Link to="/messages"><Trans>Notifications</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }

                        {isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]) ?
                            <Menu.Item key="delivery-calendars" icon={<CalendarOutlined/>}>
                                <Link to="/delivery-calendars"><Trans>Delivery calendar</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }

                        {isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN"]) ?
                            <>
                                <Menu.Item key="users-management" icon={<ControlOutlined/>}>
                                    <Link to="/users-management"><Trans>Users management</Trans></Link>
                                </Menu.Item>
                            </> :
                            <></>
                        }

                        {isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN"]) ? (
                            <>
                                <SubMenu
                                    key="IntefaceSubMenu"
                                    icon={<SwapOutlined />}
                                    title={<Trans>External data connection</Trans>}
                                >
                                    <Menu.Item key="6" icon={<ArrowUpOutlined />}>
                                        <Link to="/sap-connection">
                                            <Trans>Sap connection</Trans>
                                        </Link>
                                    </Menu.Item>
                                </SubMenu>
                            </>
                        ) : (
                            <></>
                        )}

                        {isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]) ?
                            
                                <Menu.Item key="claim-handling-mla" icon={<SnippetsOutlined />}>
                                    <Link to="/claim-handling"><Trans>claim handling</Trans></Link>
                                </Menu.Item>
                               
                            : <></>
                        }
                        
                        {isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]) ?
                            
                                <Menu.Item key="claim-handling-ppap" icon={<FileOutlined />}>
                                    <Link to="/ppap"><Trans>claim PPAP</Trans></Link>
                                </Menu.Item>
                            
                            
                            : <></>
                        }

                        {isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN"]) ?
                            <Menu.Item key="settings" icon={<SettingOutlined />}>
                                <Link to="/settings"><Trans>Settings</Trans></Link>
                            </Menu.Item>
                            : <></>
                        }
                    </Menu>
                </Sider>
                <Layout className="site-layout ">
                    <Header className="site-layout-background" style={{textAlign: "center", padding: 0}}>
                        <Row>
                            <Col span={10}>
                                {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                                    className: 'trigger',
                                    onClick: toggleCollapse,
                                    style: {float: 'left'}
                                })}
                            </Col>
                            <Col span={4}>
                                    <span style={{textAlign: "center", width: 125}}>
                                        <img src={logo}/>
                                    </span>
                            </Col>
                            <Col span={10}>
                                    <span style={{float: 'right'}}>


                                        <Tooltip placement="bottom" title={<Trans>Help</Trans>}>
                                            <span style={{verticalAlign: 'middle'}}>
                                                <QuestionCircleOutlined style={{marginRight: 20, fontSize: 'x-large'}}
                                                                        onClick={() => openWikiJs()}/>
                                            </span>
                                        </Tooltip>

                                        <Tooltip placement="bottom" title={<Trans>Support</Trans>}>
                                            <span style={{verticalAlign: 'middle'}}>
                                                <a style={{textDecoration: "none", color: "black"}} href={"mailto:support@logicloud.hu"}><SupportIco style={{ marginRight: 20, fontSize: 'x-large' }} /></a>
                                            </span>
                                        </Tooltip>

                                        <span
                                            className={process.env.REACT_APP_ENVIRONMENT}>{process.env.REACT_APP_ENVIRONMENT}</span>

                                        <LanguageChange
                                            handleLanguageChange={(e) => {
                                                i18n.activate(e.key + '');
                                                myStorage.setItem('language', e.key);
                                            }}
                                            activeLanguage={localStorage.getItem('language') ? localStorage.getItem('language') + '' : 'hu'}
                                            languages={languageModel}/>

                                        <Dropdown overlay={menu} placement="bottomLeft">
                                            <Button style={{border: 0}}><span
                                                style={{fontSize: 'large'}}>{loggedUser} </span></Button>
                                        </Dropdown>
                                        <Modal
                                            title={<Trans>Print QR to application</Trans>}
                                            visible={isModalVisible}
                                            footer={[]}
                                            onCancel={handleCancel}>

                                                     <Form
                                                         name="basic"
                                                         labelCol={{ span: 8 }}
                                                         wrapperCol={{ span: 16 }}
                                                         initialValues={{ remember: true }}
                                                         onFinish={onQrFormFinish}

                                                     >
                                                      <Form.Item
                                                          label={<Trans>Username</Trans>}
                                                          initialValue={loggedUser}
                                                          name="user"
                                                          rules={[{ required: true,  message: 'Please input your username!' }]}
                                                      >
                                                        <Input disabled={true}/>
                                                      </Form.Item>

                                                      <Form.Item
                                                          label={<Trans>Password</Trans>}
                                                          name="pass"
                                                          rules={[{ required: true, message: <Trans>Please input your password!</Trans> }]}
                                                      >
                                                        <Input.Password />
                                                      </Form.Item>

                                                      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                                                        <Button type="primary" htmlType="submit">
                                                          <Trans>Get my QR</Trans>
                                                        </Button>
                                                      </Form.Item>
                                                    </Form>
                                                  <Row>
                                                    {
                                                        qrCode == null ? "" : <Col span={24} style={{justifyContent:'center',display:'flex'}}>
                                                            <img  src={qrCode}/>
                                                        </Col>
                                                    }
                                                      {
                                                          qrCode == null ? "" :
                                                              <Col span={24} style={{justifyContent:'center',display:'flex'}}>
                                                                  <a download={loggedUser} href={qrCode}>
                                                                      <Button type="primary" shape="round" icon={<DownloadOutlined />} size={'large'}>
                                                                          <Trans>Download</Trans>
                                                                      </Button>
                                                                  </a>
                                                              </Col>
                                                      }
                                                  </Row>

                                              </Modal>
                                    </span>
                            </Col>
                        </Row>
                    </Header>
                    <Content
                        className="site-layout-background"
                        style={{
                            margin: '12px 8px',
                            padding: 20
                        }}
                    >
                        <Switch>
                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]} exact path="/" component={LandingScreen}/>
                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]} exact path="/landing" component={LandingScreen}/>
                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]} exact path="/sap-files-order-incoming" component={SapFileOrderIncomingScreen}/>
                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]} exact path="/sap-files-forecast-incoming" component={SapFileForecastIncomingScreen}/>

                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]} exact path="/orders" component={OrderScreen}/>
                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]} path="/orders/:id" component={OrderScreen}/>
                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]} exact path="/forecasts" component={ForecastScreen}/>
                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]} exact path="/forecasts/:id" component={ForecastScreen}/>
                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN"]} exact path="/suppliers" component={SupplierScreen}/>
                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN"]} exact path="/items" component={ItemScreen}/>

                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_USER"]} exact path="/incomings" component={IncomingScreen}/>

                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]} exact path="/messages" component={MessageScreen}/>
                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]} exact path="/message-documents" component={MessageDocumentScreen}/>

                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]} exact path="/delivery-calendars" component={DeliveryCalendarScreen}/>

                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]} exact path="/email-logs" component={EmailLogScreen}/>

                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN"]} exact path="/users-management" component={UserManagementScreen}/>
                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN"]} exact path="/sap-connection" component={SapConnectionScreen}/>
                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]} exact path="/claim-handling" component={ClaimHandlingScreenMLA}/>
                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN", "MTSK_USER", "MTSK_SUPPL_USER"]} exact path="/ppap" component={ClaimHandlingScreenPPAP}/>
                            <PrivateRoute roles={["MTSK_GLOBAL_ADMIN", "MTSK_SUPPL_ADMIN"]} exact path="/settings" component={SettingsScreen}/>
                        </Switch>
                    </Content>
                    <Footer style={{ textAlign: "center", fontSize: 10, paddingTop: 0, paddingRight: 10, paddingLeft: 10, paddingBottom: 9}}>
                        <Row>
                            <Col span={8}></Col>
                            <Col span={8}>
                                <CopyrightOutlined />{' '}
                                <span>
                                    {' '}
                                    2021 Copyright  Magyar Toyo Seat Kft.. | Created by{' '}
                                    <a href="https://www.logicloud.hu" target="_blank" >LogiCloud Kft.</a>
                                </span>
                            </Col>
                            <Col span={8} style={{ textAlign: 'right' }}>
                                {process.env.REACT_APP_VERSION + '-SNAPSHOT'}
                            </Col>
                        </Row>
                    </Footer>
                </Layout>
            </Layout>
        </Router>
    )
}
