
import {FC} from "react";

import React from 'react';

import MLACheckForm from "../../forms/ppap/mla-check-form";

interface Interface{
    requestId: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

interface Interface{
    requestId: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

const MLACheckScreen: FC<Interface> = (props:any) => {

    

    return (
            
                <MLACheckForm
                    requestId={props.requestId}
                />
            
    );
}
export default MLACheckScreen;
