import useSafeState from '../../utils/safe-state';
import React, {ReactNode, useEffect, useState} from "react";
import {Form, Row, Col, Input, Button, Switch, message, Select, AutoComplete} from 'antd';
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import {I18nProvider} from "@lingui/react";
import {i18n} from "@lingui/core";
import axios, {AxiosResponse} from "axios";
import {SelectType} from "../../interfaces/SelectType";
import {useKeycloak} from "@react-keycloak/web";
import isAuthorized from "../../utils/is-authorized";

interface Interface{
    data?: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

const UserManagementForm = (props : Interface) => {

    const config = { headers: {'Content-Type': 'application/json'} };
    const data = props.data || {};
    const {keycloak} = useKeycloak()
    let obj: any = JSON.parse(JSON.stringify(keycloak.tokenParsed));
    const [autoCompleteOptions,setAutoCompleteOptions] = useSafeState<any>();
    const [organizationOptions,setOrganizationOptions] = useSafeState<any>();

    const [form] = Form.useForm();
    const [enabled, setEnabled] = useSafeState(true);
    const axiosInstance = useAxios(process.env.REACT_APP_API_KEYCLOAK_URL + "")
    const axiosInstanceBackend = useAxios(process.env.REACT_APP_API_BASE_URL + "")


    useEffect(() => {

        if (props.mode === "Edit") {

            let fieldsValues = [];
            for (const [key, value] of Object.entries(data)) {
                fieldsValues.push({name: key, value: value});
            }
            form.setFields(fieldsValues);
        }else{
            form.resetFields();
            let fieldsValues = [];
            fieldsValues.push({name: "enabled", value: true});
            form.setFields(fieldsValues);
        }

        if (form.getFieldValue(['enabled']) != null && form.getFieldValue(['enabled']) != undefined){
            setEnabled(form.getFieldValue(['enabled']));
        }

        getFormData();

    }, []);


    const onReset = () => {
        form.resetFields();
    };

    const antdContext : any = (text : string) => {
        return <I18nProvider i18n={i18n}>
            <Trans id={text}></Trans>
        </I18nProvider>;
    }

    const openNotification = (status:any) => {
        if (status === "error") {
            message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
        } else if (status === "success") {
            message.success(antdContext('Sikeres mentés'));
        } else {
            message.info(antdContext('Ismeretlen hiba')).then(r => {console.log(r)})
        }
        if (props.onClose){
            props.onClose();
        }
    };

    const handleEdit = () => {

        if (!axiosInstance.current) {
            return;
        }

        let storeSupplierCode = form.getFieldValue(["supplierCode"]);
        let storeUserMailAddress = form.getFieldValue(["email"]);

        axiosInstance.current.put("/auth/admin/realms/mtsk/users/"+form.getFieldValue(["id"]), {
                firstName: form.getFieldValue(["firstName"]),
                lastName: form.getFieldValue(["lastName"]),
                email: form.getFieldValue(["email"]),
                enabled: form.getFieldValue(["enabled"]),
                attributes : {
                    supplierCode: form.getFieldValue(["supplierCode"]),
                    organization: form.getFieldValue(["organization"])
                }
            }).then(() => {
            openNotification("success");

            if (props.data.supplierCode != null && props.data.supplierCode.length > 0 && axiosInstanceBackend.current != null) {
                axiosInstanceBackend.current.put("/resource/user-mails/id?supplier=" + props.data.supplierCode[0].toString() + "&mail=" + props.data.email, {
                        id: {
                            supplier: {
                                code: storeSupplierCode.toString()
                            },
                            email: storeUserMailAddress
                        }
                    },
                    config);
            }

        }).catch(function (error :any) {
            openNotification("error");
            console.log(error)
        });
    }

    const handleAddNew = () => {

        if(!axiosInstance.current){
            return;
        }
        let storeSupplierCode = form.getFieldValue(["supplierCode"]);
        let storeUserMailAddress = form.getFieldValue(["email"]);

        let supplierCode = isAuthorized(keycloak, ["MTSK_SUPPL_ADMIN"])
            ? obj.supplierCode
            : form.getFieldValue(["supplierCode"]);

        console.log(form.getFieldValue(["organization"]));
        axiosInstance.current.post(process.env.REACT_APP_API_KEYCLOAK_URL + "/auth/admin/realms/mtsk/users", {
                firstName: form.getFieldValue(["firstName"]),
                lastName: form.getFieldValue(["lastName"]),
                email: form.getFieldValue(["email"]),
                enabled: form.getFieldValue(["enabled"]),
                username: form.getFieldValue(["username"]),
                attributes : {
                    supplierCode: supplierCode,
                    organization: form.getFieldValue(["organization"])
                }

        }).then(function (response :any) {
            openNotification("success");

            if (storeSupplierCode != null && axiosInstanceBackend.current != null) {
                axiosInstanceBackend.current.post('/resource/user-mails', {
                    id: {
                        supplier: {
                            code: storeSupplierCode.toString()
                        },
                        email: storeUserMailAddress
                    }
                }, config)
                    .then(function (response: AxiosResponse<any>) {
                    }).catch(function (error: any) {
                    message.error(antdContext('Hiba a user mentése közben!')).then(r => {
                        console.log(r)
                    });
                });
            }

        }).catch(function (error :any) {
            openNotification("error");
            console.log(error)
        });
    }

    const getFormData = () => {

        if(!axiosInstanceBackend.current){
            return;
        }

        axiosInstanceBackend.current.get('/resource/suppliers')
            .then(function (response: AxiosResponse<any>) {
                if (Array.isArray(response.data)) {
                    let supplierArray: Array<SelectType> = [];
                    response.data.forEach(value => {

                        const label = value.code;

                        supplierArray.push(
                            {
                                label: label,
                                value: label,
                            }
                        )
                    });
                    setAutoCompleteOptions(supplierArray);
                }
            })
            .catch(function (error: any) {
                console.log(error);//TODO::EndIt
            });

        axiosInstanceBackend.current.get('/resource/settings/organizations')
            .then(function (response: AxiosResponse<any>) {
                let res: Array<any> = [];

                Object.entries(response.data).forEach(([key, value]) => {
                    res.push(
                        {
                            label: key,
                            value: value,
                        }
                    )
                });
                setOrganizationOptions(res);
            })
            .catch(function (error: any) {
                console.log(error);
            });

    };


    return(
        <Form id={props.mode}  form={form} layout="vertical" onFinish={() => props.mode === "Edit" ?  handleEdit() : handleAddNew()}>
            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="username"
                        label={'Felhasználónév'}
                        children={props.mode === "Edit" ? <Input disabled={true} /> : <Input />}
                        rules={[{
                            type:"string",
                            pattern: new RegExp(/^(?! +$).+$/),
                            required: true,
                            message: 'Please enter a username'
                        }]}
                    />
                </Col>
                <Col span={12}>
                    <div style={{marginBottom: 8}}>
                    <Form.Item
                        name="enabled"
                        label={'Aktív?'}
                        children={<Switch defaultChecked={enabled}/>}
                    />
                    </div>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <Form.Item
                        name="lastName"
                        label={<Trans>lastName</Trans>}
                        children={<Input />}
                        rules={[{
                            type:"string",
                            pattern: new RegExp(/^(?! +$).+$/),
                            required: true,
                            message: 'Please enter a last name'
                        }]}
                    />
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="firstName"
                        label={<Trans>firstName</Trans>}
                        children={<Input />}
                        rules={[{
                            type:"string",
                            pattern: new RegExp(/^(?! +$).+$/),
                            required: true,
                            message: 'Please enter a first name'
                        }]}
                    />
                </Col>
                <Col span={8}>
                <Form.Item
                    name="email"
                    label={<Trans>Email</Trans>}
                    children={<Input />}
                    rules={[{
                        type:"string",
                        pattern: new RegExp(/^(?! +$).+$/),
                        required: true,
                        message: 'Please enter a email address'
                    }]}
                />
            </Col>
            </Row>
            <Row gutter={18}>
                <Col span={8}>
                    <Form.Item
                        name="supplierCode"
                        label={<Trans id={'Supplier'}/>}
                        hidden={!isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN"])}
                        children={
                            <Select
                                notFoundContent={<Trans id={'No data'}/>}
                                style={{ width: '100%' }}
                                placeholder={<Trans id={'Select supplier'}></Trans>}
                                options={autoCompleteOptions}
                            />
                          }
                    />
                </Col>
                <Col span={8}>
                    <Form.Item
                        name="organization"
                        label={<Trans id={'Organization'}/>}
                        hidden={!isAuthorized(keycloak,["MTSK_GLOBAL_ADMIN"])}
                        children={
                            <Select
                                notFoundContent={<Trans id={'No data'}/>}
                                style={{ width: '100%' }}
                                placeholder={<Trans id={'Select organization'}></Trans>}
                                options={organizationOptions}
                            />
                        }
                    />
                </Col>
            </Row>
            {props.mode === "Edit" ?
                <Button htmlType="button" type="primary" onClick={() => form.submit()}>
                    Mentés
                </Button>
                :
                <>
                    <Button htmlType="button" style={{ marginRight: 8 }} onClick={() =>onReset()}>
                        Visszaállítás
                    </Button>
                    <Button htmlType="button" type="primary" style={{ marginRight: 8 }} onClick={() => form.submit()}>
                        Hozzáadás
                    </Button>
                </>
            }


        </Form>
    );

}
export default UserManagementForm;
