
import {FC} from "react";

import React from 'react';

import ApprovalForm from "../../forms/claim-handling-forms/approval-form";

interface Interface{
    partSource: any;
    requestId: any;
    mode?: "View" | "Add new";
    onClose?: () => void;
}

const ApprovalScreen: FC<Interface> = (props:any) => {

    

    return (
            
                <ApprovalForm
                    requestId={props.requestId}
                    partSource={props.partSource}
                    mode={props.mode}
                />
            
    );
}
export default ApprovalScreen;
