import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import OrderModel from "../../models/dto/order-model";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb, Button, Popconfirm, Space} from "antd";
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import {PrinterOutlined} from "@ant-design/icons";
import {useKeycloak} from "@react-keycloak/web";
import isAuthorized from "../../utils/is-authorized";

const OrderScreen : FC = () => {

    const {initialized, keycloak} = useKeycloak();

    const [trigger, setTrigger] = useSafeState(false);
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL  + "");

    const handleDownloadPdfLabel = (orderNo : number,isA5Label : boolean) => {

        if(axiosInstance.current != null){
            const FileDownload = require('js-file-download');
            axiosInstance.current({
                url: process.env.REACT_APP_API_BASE_URL + '/resource/orders/get-qr-by-order-number/'+orderNo+"/"+isA5Label,
                method: 'GET',
                responseType: 'blob'
            }).then((response) => {
                FileDownload(response.data, new Date().toISOString().split('.')[0] + '.'+'pdf');
            }).catch(function (error: any) {
                console.log(error);
            })
        }

    }

    const action = (record: any) => {

        return (
            <Space size="middle">
                <Popconfirm
                    title={<Trans>Which label do you want to print?</Trans>}
                    okText={<Trans>A5 label</Trans>}
                    cancelText={<Trans>80x100 label</Trans>}
                    onCancel={() => handleDownloadPdfLabel(record.orderNo,false)}
                    onConfirm={() => handleDownloadPdfLabel(record.orderNo,true)}
                >
                    <PrinterOutlined className={"ActionButtons"} style={{color: "#096dd9"}}  />
                </Popconfirm>
            </Space>
        );
    }

    const actions = {
        width: 55,
        render: (text: string, record: any) => action(record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    return <>
        <Breadcrumb style={{marginBottom: 10}}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Orders'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <DtoTable
            query={{
                sapFileIncomingId: window.location.pathname.replace("/orders/", "")
            }}
            model={OrderModel}
            allowRefreshButton={true}
            allowBackButton={true}
            backUrl={'/sap-files-order-incoming'}
            allowExport={isAuthorized(keycloak, ["MTSK_SUPPL_USER"]) ? true : false}
            apiUrl={'orders'}
            trigger={trigger}
            pageSize={100}
            action={actions}
            scroll={{x: 1300, y: "65vh"}}
        /></>
}
export default OrderScreen;
