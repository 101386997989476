import useSafeState from '../../utils/safe-state';
import {Form, Input, message, Modal} from 'antd';
import React, {ReactElement, useCallback} from "react";
import {Trans} from "@lingui/macro";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {useAxios} from "../../utils/hooks";
import {I18nProvider} from "@lingui/react";
import {i18n} from "@lingui/core";
import {antdContext} from "../../utils/antdContext";

const { confirm } = Modal;

interface Interface {
    title?: ReactElement;
    children:  ReactElement;
    onCustomClose?: () => void;
    customClose?: () => void;
    name?: string;
}

const PasswordReset = (props : Interface) => {

    const axiosInstance = useAxios(process.env.REACT_APP_API_KEYCLOAK_URL + "")

    const [visible, setVisible] = useSafeState(false);
    const [data,setData] = useSafeState([]);
    const [mode,setMode]= useSafeState("Edit");
    const [form] = Form.useForm();
    form.resetFields();

    const onClose = () => {
        if(props.customClose)
        {
            props.customClose();
        }
        setVisible(false);
    };

    const open  = ( mode? : "Add new" | "Edit" | "" , recordData?: any) => {
        setMode(mode ?  mode : '');
        setData(recordData);
        setVisible(true);
    };

    const openNotification = (status:any) => {
        if (status === "error") {
            message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
        } else if (status === "success") {
            message.success(antdContext('Sikeres mentés'));
        } else {
            message.info(antdContext('Ismeretlen hiba')).then(r => {console.log(r)})
        }
    };

    const resetCall = (id:any) => {
        if(!axiosInstance.current){
            return;
        }
        axiosInstance.current.put("/auth/admin/realms/mtsk/users/"+id+"/reset-password", {
                type: "password",
                temporary: true,
                value: form.getFieldValue(["password"])
            }).then(() => {
                openNotification("success")
        }).catch(function (error :any) {
            openNotification("error")
            console.log(error);
        });
    }

    const showPromiseConfirm = (recordData?: any) => {
        setData(recordData);
        confirm({
            title: props.title,
            icon: <ExclamationCircleOutlined />,
            content:
                    <I18nProvider i18n={i18n}>
                    <Form form={form}>
                        <Form.Item
                            name="password"
                            label={<Trans>Jelszó</Trans>}
                            children={<Input type="password"/>}
                            rules={[{
                                type:"string",
                                required: true,
                                message: props.title
                            }]}
                        />
                    </Form>
                    </I18nProvider>,
            visible: visible,
            onOk() {
                return new Promise((resolve, reject) => {
                    setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
                    resetCall(recordData.id);
                }).catch(() => console.log('Oops error!'));
            },
            onCancel() {

            },
        });
    }

    const createContent = (): React.ReactNode => {
        return React.cloneElement(props.children, {
            mode : mode,
            data : data,
            onClose : () => {
                onClose();
            }
        });
    };

    return {open,showPromiseConfirm};

};
export default PasswordReset;
