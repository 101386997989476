
import {FC} from "react";

import React from 'react';

import ErrorExistsSecondForm from "../../forms/claim-handling-forms/error-exists-second-form";

interface Interface{
    partSource: any;
    requestId: any;
    mode?: "View" | "Add new";
    onClose?: () => void;
}

const ErrorExistsSecondScreen: FC<Interface> = (props:any) => {

    

    return (
            
                <ErrorExistsSecondForm
                    requestId={props.requestId}
                    partSource={props.partSource}
                    mode={props.mode}
                />
            
    );
}
export default ErrorExistsSecondScreen;
