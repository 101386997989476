import useSafeState from '../../utils/safe-state';
import React, {useEffect, useRef} from "react";
import {
    Form,
    Row,
    Col,
    Input,
    Button,
    Switch,
    message,
    Select,
    AutoComplete,
    Radio,
    Space,
    DatePickerProps,
    DatePicker,
    Card, Divider, InputNumber, Modal
} from 'antd';
import {Trans} from "@lingui/macro";
import {useAxios} from "../../utils/hooks";
import { AxiosResponse } from 'axios';
import getEnumArray from "../../utils/enums";
import {antdContext} from "../../utils/antdContext";
import type { InputRef } from 'antd';
import RectificationScreen from '../../screens/claim-handling/rectification';
import ClaimListScreen from '../../screens/claim-handling/claim-list';
import moment from 'moment';
import TextArea from "antd/es/input/TextArea";
import ReactJson from "react-json-view";




interface Interface{
    partSource: any;
    requestId: any;
    mode?: "View" | "AddNew";
}

const CorrectionForm = (props : Interface) => {

    const [inventoryControlOptions,setinventoryControlOptions] = useSafeState<any>();
    const [handlingFaultyPartsOptions,sethandlingFaultyPartsOptions] = useSafeState<any>();
    const [loading, setLoading] = useSafeState(false);
    const [rectification, setRectification] = useSafeState(false);
    const [isView, setIsView] = useSafeState<boolean>();
    const [back, setBack] = useSafeState<boolean>(false);
    const [isEmptyGroupName2, setIsEmptyGroupName2] = useSafeState<boolean>(true);
    const [isEmptyGroupName3, setIsEmptyGroupName3] = useSafeState<boolean>(true);
    const [isEmptyGroupName4, setIsEmptyGroupName4] = useSafeState<boolean>(true);
    const [isEmptyGroupName5, setIsEmptyGroupName5] = useSafeState<boolean>(true);
    const [isEmptyGroupName6, setIsEmptyGroupName6] = useSafeState<boolean>(true);
    const [isEmptyGroupName7, setIsEmptyGroupName7] = useSafeState<boolean>(true);
    const [needToCheck, setNeedToCheck] = useSafeState<boolean>(false);

    const [form] = Form.useForm();
    
    const axiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL + "");


    const immediateActionDeadlineDatepickerOnChange: DatePickerProps['onChange'] = (date, dateString) => {
        console.log(date, dateString);
    };

    useEffect(() => {
        
        setinventoryControlOptions(getEnumArray('inventorycontrol'));
        sethandlingFaultyPartsOptions(getEnumArray('handlingfaultyparts'));
        setIsView(props.mode == 'View' ? true : false);
        needToCheckStock(props.requestId);

    }, []);

    useEffect(() => {
        if (isView) {
            viewMode();
        }
    },[isView]

    );

    const needToCheckStock = (id: number) => {

        if(axiosInstance.current != null){

            axiosInstance
                .current
                .get(process.env.REACT_APP_API_BASE_URL + '/resource/request-data/need-to-check-stock-by-request-id?requestId=' + id)
                .then(function (response: AxiosResponse<any>) {
                    setNeedToCheck(response.data);
                }).catch(function (error :any) {
                    message.error(antdContext('Hiba a mentés közben'));
                    console.log(error);
                }).finally(() => {

                });
        }
    };


    const viewMode = () => {
        if(axiosInstance.current != null) {
            
            const resp = axiosInstance.current.get("/resource/request-data/get-request-data?requestId=" + props.requestId, {
                    
                }
                ).then(function (response : AxiosResponse<any>)
                {
                   
                    let dataArray:any[] = JSON.parse(JSON.stringify(response.data));
                    
                    dataArray.forEach(value => {
                        switch (value.name) {

                            case 'groupname1':
                                form.setFieldsValue({"groupname1":value.value});
                            break;
                            case 'groupname2':
                                form.setFieldsValue({"groupname2":value.value});
                                break;
                            case 'groupname3':
                                form.setFieldsValue({"groupname3":value.value});
                                break;
                            case 'groupname4':
                                form.setFieldsValue({"groupname4":value.value});
                                break;
                            case 'groupname5':
                                form.setFieldsValue({"groupname5":value.value});
                                break;
                            case 'groupname6':
                                form.setFieldsValue({"groupname6":value.value});
                                break;
                            case 'groupname7':
                                form.setFieldsValue({"groupname7":value.value});
                                break;


                            case 'grouprank1':
                                form.setFieldsValue({"grouprank1":value.value});
                            break;
                            case 'grouprank2':
                                form.setFieldsValue({"grouprank2":value.value});
                                break;
                            case 'grouprank3':
                                form.setFieldsValue({"grouprank3":value.value});
                                break;
                            case 'grouprank4':
                                form.setFieldsValue({"grouprank4":value.value});
                                break;
                            case 'grouprank5':
                                form.setFieldsValue({"grouprank5":value.value});
                                break;
                            case 'grouprank6':
                                form.setFieldsValue({"grouprank6":value.value});
                                break;
                            case 'grouprank7':
                                form.setFieldsValue({"grouprank7":value.value});
                                break;


                            case 'groupemail1':
                                form.setFieldsValue({"groupemail1":value.value});
                            break;
                            case 'groupemail2':
                                form.setFieldsValue({"groupemail2":value.value});
                                break;
                            case 'groupemail3':
                                form.setFieldsValue({"groupemail3":value.value});
                                break;
                            case 'groupemail4':
                                form.setFieldsValue({"groupemail4":value.value});
                                break;
                            case 'groupemail5':
                                form.setFieldsValue({"groupemail5":value.value});
                                break;
                            case 'groupemail6':
                                form.setFieldsValue({"groupemail6":value.value});
                                break;
                            case 'groupemail7':
                                form.setFieldsValue({"groupemail7":value.value});
                                break;



                            case 'problemname':
                                form.setFieldsValue({"problemname":value.value});
                            break;
                            case 'problemdetails':
                                form.setFieldsValue({"problemdetails":value.value});
                            break;
                            case 'factorydatawho':
                                form.setFieldsValue({"factorydatawho":value.value});
                            break;
                            case 'factorydatawhat':
                                form.setFieldsValue({"factorydatawhat":value.value});
                            break;
                            case 'factorydatawhen':
                                form.setFieldsValue({"factorydatawhen":value.value});
                            break;
                            case 'factorydatawhere':
                                form.setFieldsValue({"factorydatawhere":value.value});
                            break;
                            case 'immediateactioncorrect':
                                form.setFieldsValue({"immediateactioncorrect":value.value});
                            break;
                            case 'immediateactionresponsible':
                                form.setFieldsValue({"immediateactionresponsible":value.value});
                            break;
                            case 'immediateactiondeadline':
                                const immediateactiondeadlineDate = new Date(value.value);
                                const immediateactiondeadlineMoment = moment(immediateactiondeadlineDate);
                                form.setFieldsValue({"immediateactiondeadline":immediateactiondeadlineMoment});
                            break;
                            case 'immediateactioninventorycontrol':
                                form.setFieldsValue({"immediateactioninventorycontrol":value.value});
                            break;
                            case 'handlingfaultyparts':
                                form.setFieldsValue({"handlingfaultyparts":value.value});
                            break;

                            case 'mtskStockOk':
                                form.setFieldsValue({"mtskStockOk":value.value});
                                break;
                            case 'mtskStockNg':
                                form.setFieldsValue({"mtskStockNg":value.value});
                                break;
                            case 'mtskStockRepaired':
                                form.setFieldsValue({"mtskStockRepaired":value.value});
                                break;
                            case 'mtskStockSum':
                                form.setFieldsValue({"mtskStockSum":value.value});
                                break;

                            default:
                            break;    
                        }
                        
                    });

                }).catch(function (error :any) {
                message.error(antdContext('Hiba a lekérdezés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });

            
        }
    }

    const handleFinish = () => {
        const obj = {
            repairData: {
                team: {
                    name1: form.getFieldValue(["groupname1"]),
                    name2: form.getFieldValue(["groupname2"]),
                    name3: form.getFieldValue(["groupname3"]),
                    name4: form.getFieldValue(["groupname4"]),
                    name5: form.getFieldValue(["groupname5"]),
                    name6: form.getFieldValue(["groupname6"]),
                    name7: form.getFieldValue(["groupname7"]),

                    rank1: form.getFieldValue(["grouprank1"]),
                    rank2: form.getFieldValue(["grouprank2"]),
                    rank3: form.getFieldValue(["grouprank3"]),
                    rank4: form.getFieldValue(["grouprank4"]),
                    rank5: form.getFieldValue(["grouprank5"]),
                    rank6: form.getFieldValue(["grouprank6"]),
                    rank7: form.getFieldValue(["grouprank7"]),

                    email1: form.getFieldValue(["groupemail1"]),
                    email2: form.getFieldValue(["groupemail2"]),
                    email3: form.getFieldValue(["groupemail3"]),
                    email4: form.getFieldValue(["groupemail4"]),
                    email5: form.getFieldValue(["groupemail5"]),
                    email6: form.getFieldValue(["groupemail6"]),
                    email7: form.getFieldValue(["groupemail7"])
                },
                problem: {
                    name: form.getFieldValue(["problemname"]),
                    description: form.getFieldValue(["problemdetails"])
                },
                productionData: {
                    who: form.getFieldValue(["factorydatawho"]),
                    what: form.getFieldValue(["factorydatawhat"]),
                    when: form.getFieldValue(["factorydatawhen"]),
                    where: form.getFieldValue(["factorydatawhere"])
                },
                immediateActionData: {
                    repairAction: form.getFieldValue(["immediateactioncorrect"]),
                    responsible: form.getFieldValue(["immediateactionresponsible"]),
                    deadline: moment(form.getFieldValue(["immediateactiondeadline"])).format('YYYY-MM-DD'),
                    handlingOfFoundedPartsAtMtsk: form.getFieldValue(["handlingfaultyparts"]),
                },
                stockCheckResult: {
                    qtyOfOk: form.getFieldValue(["mtskStockOk"]),
                    qtyOfNg: form.getFieldValue(["mtskStockNg"]),
                    qtyOfSum: form.getFieldValue(["mtskStockSum"]),
                    qtyOfRepaired: form.getFieldValue(["mtskStockRepaired"])
                }
            }
        };

        if(axiosInstance.current != null) {
            setLoading(true);
            axiosInstance.current.post("/resource/requests/complete-by-name-and-action-type?requestId=" + props.requestId + "&stateName=" + "Response" + "&actionType=" + "APPROVE",
                    obj
            ).then(() => {

                setBack(true);

            }).catch(function (error :any) {
                message.error(antdContext('Hiba mentés közben')).then(r => {console.log(r)});
                console.log(error)
                setLoading(false);
            });
        }

    }

    const inputRef = useRef<InputRef>(null);

    const sharedProps = {
        ref: inputRef,
    };


    const groupName2Change = (value: any) => { setIsEmptyGroupName2(!(value.target.value && value.target.value.length > 0)); }
    const groupName3Change = (value: any) => { setIsEmptyGroupName3(!(value.target.value && value.target.value.length > 0)); }
    const groupName4Change = (value: any) => { setIsEmptyGroupName4(!(value.target.value && value.target.value.length > 0)); }
    const groupName5Change = (value: any) => { setIsEmptyGroupName5(!(value.target.value && value.target.value.length > 0)); }
    const groupName6Change = (value: any) => { setIsEmptyGroupName6(!(value.target.value && value.target.value.length > 0)); }
    const groupName7Change = (value: any) => { setIsEmptyGroupName7(!(value.target.value && value.target.value.length > 0)); }


    return(
            back == true ? (
                <ClaimListScreen/>
            ) : (
                <Form id="correction"  layout='vertical' form={form}  onFinish={() => handleFinish()}>
                <Row gutter={24}>
                    <Col span={12}>
                        <h2><Trans>Correction</Trans></h2>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Card style={{ width: 750, margin: 10 }} title={<Trans>Team</Trans>}>
                        <Row gutter={24}>
                            <Col span={2} style={{paddingTop: 35}}><span>1.</span></Col>
                            <Col span={8}>
                                <Form.Item
                                    name="groupname1"
                                    label={<Trans>name</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter a group name</Trans>,
                                        validator: () => {

                                            let v:string = form.getFieldValue('groupname1');

                                            if (v == undefined || v.length <= 0) {
                                                inputRef.current!.focus({});
                                                return Promise.reject('');
                                            } else {
                                                return Promise.resolve('');
                                            }
                                        }

                                    }]}
                                />
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="grouprank1"
                                    label={<Trans>rank</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter a group rank</Trans>,
                                        validator: () => {

                                            let v:string = form.getFieldValue('grouprank1');

                                            if (v == undefined || v.length <= 0) {
                                                inputRef.current!.focus({});
                                                return Promise.reject('');
                                            } else {
                                                return Promise.resolve('');
                                            }
                                        }
                                    }]}
                                />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="groupemail1"
                                    label={<Trans>email</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"email",
                                        required: true,
                                        message: <Trans>Please enter a group email</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={2}  style={{paddingTop: 35}}><span>2.</span></Col>
                            <Col span={8}>
                                <Form.Item
                                    name="groupname2"
                                    label={<Trans>name</Trans>}
                                    children={<Input {...sharedProps} disabled={isView} type={"text"} onChange={value => groupName2Change(value)}/>}
                                    rules={[{
                                        type:"string",
                                        required: false,
                                        message: <Trans>Please enter a group name</Trans>,

                                    }]}
                                />
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="grouprank2"
                                    label={<Trans>rank</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: !isEmptyGroupName2,
                                        message: <Trans>Please enter a group rank</Trans>,
                                    }]}
                                />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="groupemail2"
                                    label={<Trans>email</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"email",
                                        required: !isEmptyGroupName2,
                                        message: <Trans>Please enter a group email</Trans>,
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={2}  style={{paddingTop: 35}}><span>3.</span></Col>
                            <Col span={8}>
                                <Form.Item
                                    name="groupname3"
                                    label={<Trans>name</Trans>}
                                    children={<Input {...sharedProps} disabled={isView} type={"text"} onChange={value => groupName3Change(value)}/>}
                                    rules={[{
                                        type:"string",
                                        required: false,
                                        message: <Trans>Please enter a group name</Trans>,
                                    }]}
                                />
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="grouprank3"
                                    label={<Trans>rank</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: !isEmptyGroupName3,
                                        message: <Trans>Please enter a group rank</Trans>,
                                    }]}
                                />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="groupemail3"
                                    label={<Trans>email</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"email",
                                        required: !isEmptyGroupName3,
                                        message: <Trans>Please enter a group email</Trans>,
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={2}  style={{paddingTop: 35}}><span>4.</span></Col>
                            <Col span={8}>
                                <Form.Item
                                    name="groupname4"
                                    label={<Trans>name</Trans>}
                                    children={<Input {...sharedProps} disabled={isView} type={"text"} onChange={value => groupName4Change(value)}/>}
                                    rules={[{
                                        type:"string",
                                        required: false,
                                        message: <Trans>Please enter a group name</Trans>,
                                    }]}
                                />
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="grouprank4"
                                    label={<Trans>rank</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: !isEmptyGroupName4,
                                        message: <Trans>Please enter a group rank</Trans>,
                                    }]}
                                />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="groupemail4"
                                    label={<Trans>email</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"email",
                                        required: !isEmptyGroupName4,
                                        message: <Trans>Please enter a group email</Trans>,
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={2}  style={{paddingTop: 35}}><span>5.</span></Col>
                            <Col span={8}>
                                <Form.Item
                                    name="groupname5"
                                    label={<Trans>name</Trans>}
                                    children={<Input {...sharedProps} disabled={isView} type={"text"} onChange={value => groupName5Change(value)}/>}
                                    rules={[{
                                        type:"string",
                                        required: false,
                                        message: <Trans>Please enter a group name</Trans>,
                                    }]}
                                />
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="grouprank5"
                                    label={<Trans>rank</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: !isEmptyGroupName5,
                                        message: <Trans>Please enter a group rank</Trans>,
                                    }]}
                                />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="groupemail5"
                                    label={<Trans>email</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"email",
                                        required: !isEmptyGroupName5,
                                        message: <Trans>Please enter a group email</Trans>,
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={2}  style={{paddingTop: 35}}><span>6.</span></Col>
                            <Col span={8}>
                                <Form.Item
                                    name="groupname6"
                                    label={<Trans>name</Trans>}
                                    children={<Input {...sharedProps} disabled={isView} type={"text"} onChange={value => groupName6Change(value)}/>}
                                    rules={[{
                                        type:"string",
                                        required: false,
                                        message: <Trans>Please enter a group name</Trans>,
                                    }]}
                                />
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="grouprank6"
                                    label={<Trans>rank</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: !isEmptyGroupName6,
                                        message: <Trans>Please enter a group rank</Trans>,
                                    }]}
                                />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="groupemail6"
                                    label={<Trans>email</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"email",
                                        required: !isEmptyGroupName6,
                                        message: <Trans>Please enter a group email</Trans>,
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={2}  style={{paddingTop: 35}}><span>7.</span></Col>
                            <Col span={8}>
                                <Form.Item
                                    name="groupname7"
                                    label={<Trans>name</Trans>}
                                    children={<Input {...sharedProps} disabled={isView} type={"text"} onChange={value => groupName7Change(value)}/>}
                                    rules={[{
                                        type:"string",
                                        required: false,
                                        message: <Trans>Please enter a group name</Trans>,
                                    }]}
                                />
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="grouprank7"
                                    label={<Trans>rank</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: !isEmptyGroupName7,
                                        message: <Trans>Please enter a group rank</Trans>,
                                    }]}
                                />
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="groupemail7"
                                    label={<Trans>email</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"email",
                                        required: !isEmptyGroupName7,
                                        message: <Trans>Please enter a group email</Trans>,
                                    }]}
                                />
                            </Col>
                        </Row>
                    </Card>
                    <Card style={{ width: 400, margin: 10 }} title={<Trans>Problem</Trans>}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="problemname"
                                    label={<Trans>Problem name</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter a problem name</Trans>,
                                        validator: () => {

                                            let v:string = form.getFieldValue('problemname');

                                            if (v == undefined || v.length <= 0) {
                                                inputRef.current!.focus({});
                                                return Promise.reject('');
                                            } else {
                                                return Promise.resolve('');
                                            }
                                        }
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="problemdetails"
                                    label={<Trans>Problem details</Trans>}
                                    children={<TextArea {...sharedProps} disabled={isView} rows={20}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter a problem details</Trans>,
                                        validator: () => {

                                            let v:string = form.getFieldValue('problemdetails');

                                            if (v == undefined || v.length <= 0) {
                                                inputRef.current!.focus({});
                                                return Promise.reject('');
                                            } else {
                                                return Promise.resolve('');
                                            }
                                        }
                                    }]}
                                />
                            </Col>
                        </Row>
                    </Card>
                    <Card style={{ width: 400, margin: 10 }} title={<Trans>Details of Production</Trans>}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="factorydatawho"
                                    label={<Trans>Who</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter a factory data who</Trans>,
                                        validator: () => {

                                            let v:string = form.getFieldValue('factorydatawho');

                                            if (v == undefined || v.length <= 0) {
                                                inputRef.current!.focus({});
                                                return Promise.reject('');
                                            } else {
                                                return Promise.resolve('');
                                            }
                                        }
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="factorydatawhat"
                                    label={<Trans>What</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter a factory data what</Trans>,
                                        validator: () => {

                                            let v:string = form.getFieldValue('factorydatawhat');

                                            if (v == undefined || v.length <= 0) {
                                                inputRef.current!.focus({});
                                                return Promise.reject('');
                                            } else {
                                                return Promise.resolve('');
                                            }
                                        }
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="factorydatawhen"
                                    label={<Trans>When</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter a factory data when</Trans>,
                                        validator: () => {

                                            let v:string = form.getFieldValue('factorydatawhen');

                                            if (v == undefined || v.length <= 0) {
                                                inputRef.current!.focus({});
                                                return Promise.reject('');
                                            } else {
                                                return Promise.resolve('');
                                            }
                                        }
                                    }]}
                                />

                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="factorydatawhere"
                                    label={<Trans>Where</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter a factory data where</Trans>,
                                        validator: () => {

                                            let v:string = form.getFieldValue('factorydatawhere');

                                            if (v == undefined || v.length <= 0) {
                                                inputRef.current!.focus({});
                                                return Promise.reject('');
                                            } else {
                                                return Promise.resolve('');
                                            }
                                        }
                                    }]}
                                />

                            </Col>
                        </Row>
                    </Card>
                    <Card style={{ width: 400, margin: 10 }} title={<Trans>Immediate Measures</Trans>}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="immediateactioncorrect"
                                    label={<Trans>Corrective action</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter a immediate action correct</Trans>,
                                        validator: () => {

                                            let v:string = form.getFieldValue('immediateactioncorrect');

                                            if (v == undefined || v.length <= 0) {
                                                inputRef.current!.focus({});
                                                return Promise.reject('');
                                            } else {
                                                return Promise.resolve('');
                                            }
                                        }
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="immediateactionresponsible"
                                    label={<Trans>Responsible</Trans>}
                                    children={<Input {...sharedProps} disabled={isView}/>}
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter a immediateaction responsible</Trans>,
                                        validator: () => {

                                            let v:string = form.getFieldValue('immediateactionresponsible');

                                            if (v == undefined || v.length <= 0) {
                                                inputRef.current!.focus({});
                                                return Promise.reject('');
                                            } else {
                                                return Promise.resolve('');
                                            }
                                        }
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="immediateactiondeadline"
                                    label={<Trans>Deadline</Trans>}
                                    children={<DatePicker placeholder='Dátum' disabled={isView} onChange={immediateActionDeadlineDatepickerOnChange}
                                                          format={'YYYY.MM.DD'}/>}
                                    rules={[{
                                        type:"date",
                                        required: true,
                                        message: <Trans>Please enter a immediate action deadline</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="handlingfaultyparts"
                                    label={<Trans>Handling faulty parts</Trans>}
                                    children={
                                        <Select disabled={isView}
                                                notFoundContent={<Trans id={'No data'}/>}
                                                style={{ width: '100%' }}
                                                placeholder={<Trans id={'Handling faulty parts'}></Trans>}
                                                options={handlingFaultyPartsOptions}
                                        />
                                    }
                                    rules={[{
                                        type:"string",
                                        required: true,
                                        message: <Trans>Please enter the handling faulty parts</Trans>
                                    }]}
                                />
                            </Col>
                        </Row>
                    </Card>
                    <Card style={{ width: 400, margin: 10 }} title={<Trans>Result of Stock Check</Trans>}>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="mtskStockOk"
                                    label={<Trans>Count of OK</Trans>}
                                    children={<InputNumber min={0} disabled={isView || !needToCheck}/>}
                                    rules={[{
                                        type:"number",
                                        required: needToCheck,
                                        message: <Trans>Please fill number of OK</Trans>,
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="mtskStockNg"
                                    label={<Trans>Count of NG</Trans>}
                                    children={<InputNumber min={0} disabled={isView || !needToCheck}/>}
                                    rules={[{
                                        type:"number",
                                        required: needToCheck,
                                        message: <Trans>Please fill number of NG</Trans>,
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="mtskStockSum"
                                    label={<Trans>Count of SUM</Trans>}
                                    children={<InputNumber min={0} disabled={isView || !needToCheck}/>}
                                    rules={[{
                                        type:"number",
                                        required: needToCheck,
                                        message: <Trans>Please fill number of SUM</Trans>,
                                    }]}
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={24}>
                                <Form.Item
                                    name="mtskStockRepaired"
                                    label={<Trans>Count of Repaired</Trans>}
                                    children={<InputNumber min={0} disabled={isView || !needToCheck}/>}
                                    rules={[{
                                        type:"number",
                                        required: needToCheck,
                                        message: <Trans>Please fill number of Repaired</Trans>,
                                    }]}
                                />
                            </Col>
                        </Row>
                    </Card>
                </Row>

                    <Form.Item
                        children={
                            <>
                                <Button htmlType="button" type="primary" onClick={() => setBack(true)} style={{marginRight: 5}}>
                                    <Trans>Back</Trans>
                                </Button>
                                <Button htmlType="button" type="primary" onClick={() => form.submit()} disabled={isView}>
                                    <Trans>Save</Trans>
                                </Button>
                            </>
                        }
                    />

            </Form>
            )
            );

}
export default CorrectionForm;
