import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const MessageModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'id', width: 110, sorter:true,title:<Trans>Id</Trans>, fixed: "left", direction:"desc"});
    columns.push({key:'supplierCode.code', dataIndex:['supplierCode','code'], width: 110, sorter:true,title:<Trans>Supplier code</Trans>, fixed: "left"});
    columns.push({key:'startDate',dateFilter:true, width: 110, sorter:true,title:<Trans>Start date</Trans>, fixed: "left"});
    columns.push({key:'endDate', dateFilter:true, width: 110, sorter:true,title:<Trans>End date</Trans>, fixed: "left"});
    columns.push({key:'message', width: 110, sorter:true,title:<Trans>Message</Trans>, fixed: "left",
    render: (text) => {
        return <div style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }}>{text}</div>
    }});
    columns.push({key:'title', width: 110, sorter:true,title:<Trans>Title</Trans>, fixed: "left" });

    return{
        columns:columns,
        url:"/resource/messages"
    };

}
export default MessageModel();
