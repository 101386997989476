import React, {FC} from "react";
import useSafeState from "../../utils/safe-state";
import {useAxios} from "../../utils/hooks";
import {Breadcrumb, Button, message, Popconfirm, Tooltip} from "antd";
import {antdContext} from "#/utils/antdContext";
import {Trans} from "@lingui/macro";
import Drawer from "../../components/drawer/drawer";
import ItemForm from "../../forms/item-form/item-drawer";
import DtoTable from "../../components/dto-table/dto-table";
import IncomingModel from "../../models/dto/incoming-model";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons/lib";

const IncomingScreen : FC = () => {

    const [trigger, setTrigger] = useSafeState(false);

    const mtskAxiosInstance = useAxios(process.env.REACT_APP_API_BASE_URL  + "");

    const action = (text: string, record: any) => {

        return <>
            <Tooltip placement="top" title={<Trans>Delete item</Trans>}>
                {/*<Popconfirm title={<Trans>Are you sure to delete this item?</Trans>}*/}
                {/*            onConfirm={() => handleDelete(record.id)}*/}
                {/*            okText={<Trans>Yes</Trans>}*/}
                {/*            cancelText={<Trans>No</Trans>}>*/}
                {/*    <DeleteOutlined className={"ActionButtons"} style={{color: "#ff4d4f"}}/>*/}
                {/*</Popconfirm>*/}
            </Tooltip>
            <Tooltip placement="top" title={<Trans>Edit item</Trans>}>

                {/*<EditTwoTone*/}
                {/*    twoToneColor={"#40a9ff"}*/}
                {/*    className={"ActionButtons"} onClick={*/}
                {/*    () => drawer.open("Edit", {*/}
                {/*        itemNo: record.id.itemNo,*/}
                {/*        piecePerBox: record.piecePerBox,*/}
                {/*        nameHun: record.nameHun,*/}
                {/*        nameEng: record.nameEng,*/}
                {/*        active: record.active,*/}
                {/*        supplier : record.id.supplier.code,*/}
                {/*        version:record.version*/}
                {/*    })}/>*/}
            </Tooltip>
        </>
    }

    const actions = {
        width: 100,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    const text = <Trans>Incoming</Trans>;
    const drawer = Drawer(
        {
            title: text,
            children:
                <ItemForm/>,
            customClose: () => setTrigger(!trigger)
        }
    );

    return  <>

        <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Incomings'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <DtoTable model={IncomingModel}
                  trigger={trigger}
                  allowRefreshButton={true}
                  pageSize={100}
                  scroll={{ x: 1300, y: "65vh" }}
        />
        {drawer.component}
    </>
}
export default IncomingScreen;
