import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import ForecastModel from "../../models/dto/forecast-model";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb} from "antd";
import {Trans} from "@lingui/macro";
import {useKeycloak} from "@react-keycloak/web";
import isAuthorized from "../../utils/is-authorized";

const ForecastScreen : FC = () => {

    const {initialized, keycloak} = useKeycloak();

    const [trigger, setTrigger] = useSafeState(false);

    return  <>
        <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Forecasts'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <DtoTable
            query={{
                sapFileIncomingId: window.location.pathname.replace("/forecast/", "")
            }}
            model={ForecastModel}
            allowBackButton={true}
            backUrl={'/sap-files-incoming/forecasts'}
            allowExport={isAuthorized(keycloak, ["MTSK_SUPPL_USER"]) ? true : false }
            apiUrl={'forecasts'}
            trigger={trigger}
            pageSize={100}
            scroll={{ x: 1300, y: "65vh" }}
        /></>
}
export default ForecastScreen;
