import React from "react";
import {FC} from "react";
import DtoTable from "../../components/dto-table/dto-table";
import MessageDocumentModel from "../../models/dto/message-document-model";
import useSafeState from "../../utils/safe-state";
import {Breadcrumb} from "antd";
import {Trans} from "@lingui/macro";

const MessageDocumentScreen : FC = () => {

    const [trigger, setTrigger] = useSafeState(false);

    const action = (text: string, record: any) => {

        return('');
    }

    const actions = {
        width: 50,
        render: (text: string, record: any) => action(text, record),
        title: '',
        fixed: 'right',
        align: 'center'
    };

    return  <>
        <Breadcrumb style={{ marginBottom: 10 }}>
            <Breadcrumb.Item><Trans id={'Homepage'}/></Breadcrumb.Item>
            <Breadcrumb.Item><Trans id={'Message documents'}></Trans></Breadcrumb.Item>
        </Breadcrumb>
        <DtoTable model={MessageDocumentModel}
                                 allowExport={true}
                                 apiUrl={'message-documents'}
                                 action={actions}
                                 trigger={trigger}
                                 pageSize={100}
                                 scroll={{ x: 1300, y: "65vh" }}
    /></>
}
export default MessageDocumentScreen;
