
import {FC} from "react";

import React from 'react';

import PPAPCheckForm from "../../forms/ppap/ppap-check-form";

interface Interface{
    requestId: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

interface Interface{
    requestId: any;
    mode?: "Edit" | "Add new";
    onClose?: () => void;
}

const PPAPCheckScreen: FC<Interface> = (props:any) => {

    

    return (
            
                <PPAPCheckForm
                    requestId={props.requestId}
                />
            
    );
}
export default PPAPCheckScreen;
