
import {FC} from "react";

import React from 'react';

import ErrorReasonForm from "../../forms/claim-handling-forms/error-reason-form";

interface Interface{
    partSource: any;
    requestId: any;
    mode?: "View" | "AddNew";
}

const ErrorReasonScreen: FC<Interface> = (props:any) => {

    

    return (
            
                <ErrorReasonForm
                    requestId={props.requestId}
                    mode={props.mode}
                    partSource={props.partSource}
                />
            
    );
}
export default ErrorReasonScreen;
