
import {FC} from "react";

import React from 'react';

import ReleaseForm from "../../forms/claim-handling-forms/release-form";

interface Interface{
    requestId: any;
    mode?: "View" | "AddNew";
}
const ReleaseScreen: FC<Interface> = (props:any) => {

    return (
            
                <ReleaseForm
                    requestId={props.requestId}
                    mode={props.mode}
                />
            
    );
}
export default ReleaseScreen;
