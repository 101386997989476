
import {FC} from "react";

import React from 'react';

import ControlAcceptorToErrorReasonForm from "../../forms/claim-handling-forms/control-acceptor-to-error-reason-form";

interface Interface{
    partSource: any;
    requestId: any;
    mode?: "View" | "Add new";
    onClose?: () => void;
}

const ControlAcceptorToErrorReasonScreen: FC<Interface> = (props:any) => {

    

    return (
            
                <ControlAcceptorToErrorReasonForm
                    requestId={props.requestId}
                    partSource={props.partSource}
                    mode={props.mode}
                />
            
    );
}
export default ControlAcceptorToErrorReasonScreen;
