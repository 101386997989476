import {ColumnMain} from "../column-main";
import {Column} from "../column";
import {Trans} from "@lingui/macro";
import React from "react";

const MessageDocumentModel = () : ColumnMain => {

    const columns : Array<Column> = new Array();
    columns.push({key:'id', width: 110, sorter:true,title:<Trans>Id</Trans>, fixed: "left", direction:"asc"});
    columns.push({key:'messageId.id', dataIndex:['messageId','id'], width: 110, sorter:true,title:<Trans>Message Id</Trans>, fixed: "left", direction:"asc"});
    columns.push({key:'folder', width: 110, sorter:true,title:<Trans>Folder</Trans>, fixed: "left", direction:"asc"});
    columns.push({key:'fileName', width: 110, sorter:true,title:<Trans>File name</Trans>, fixed: "left", direction:"asc"});

    return{
        columns:columns,
        url:"/resource/message-documents"
    };

}
export default MessageDocumentModel();
